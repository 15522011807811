<template>
  <div id="my-reservation">
    <BannerProfile :user="this.user"></BannerProfile>
    <div class="container-profil">
      <Menuprofil :user="this.user"></Menuprofil>
      <div v-if="this.reservationsList.length === 0" class="empty-resa">
        <div class="resa">
          <p class="raleway-reg-p15 mb-5 text-center">Vous n’avez aucune réservation pour le moment.</p>
          <a :href="this.origin +'/reservation-hotel'" class="hortense-btn-primary">Trouver un hébergement</a>
        </div>
      </div>
      <div v-else class="reservations">
        <div class="col-8 offset-lg-4 col-search">
          <div class="banner-search">
            <input class="search-bar" type="text" placeholder="Numéro de réservation" v-model="search">
            <a class="around-btn link-hortense" href="#">

            </a>
            <a :href="'/recherche?search=' + search +'&otherValue=test'" :data="search" class="no-deco">
              <div class="search-button">
                 <img src="../../images/loupe.svg" v-set-alt>
              </div>
            </a>
          </div>
        </div>
        <Cardresapending v-if="this.pendings != null && this.pendings.length >= 1"></Cardresapending>
        <Cardresapast v-if="this.pasts != null && this.pasts.length >= 1"></Cardresapast>
      </div>
    </div>
  </div>
</template>

<script>

  import BannerProfile from 'components/shared/BannerProfile.vue';
  import Menuprofil from 'components/shared/Menuprofil.vue';
  import Cardresapending from 'pages/dashboard/Cardresapending.vue';
  import Cardresapast from 'pages/dashboard/Cardresapast.vue';
  import fetcher from 'packs/shared/api.js';

  export default{
    name: 'my-reservation',
    components: { BannerProfile, Menuprofil, Cardresapending, Cardresapast },
    props: [ "user", "reservations"],
    data() {
      return {
        reservationsList: [],
        origin: window.location.origin,
        search: "",
        pendings: null,
        pasts: null,
      }
    },
    methods: {
      async fetchPendingResa() {
        try {
          this.reservationsList = this.reservations
          await this.dateNow()
          await this.pendingReservations()
          this.pendingReservations()
          await this.pastReservations()
        } catch(e) {
          console.error(e)
        }
      },
      dateNow(splinter){
        const set = new Date();
        let getDate = set.getDate().toString();
        if (getDate.length == 1){ //example if 1 change to 01
         getDate = "0"+getDate;
        }
        let getMonth = (set.getMonth()+1).toString();
        if (getMonth.length == 1){
         getMonth = "0"+getMonth;
        }
        let getYear = set.getFullYear().toString();
        const dateNow = getYear +splinter+ getMonth +splinter+ getDate ; //today
        return dateNow;
      },
      pendingReservations() {
        this.pendings = this.reservationsList.filter((reservation) => {
          return reservation.checkin === this.dateNow('-') && reservation.duration > 0 || reservation.check_out > this.dateNow('-')
        })
        return this.pendings
      },
      pastReservations() {
        this.pasts = this.reservationsList.filter((reservation) => {
          return reservation.checkin === this.dateNow('-') && reservation.duration < 0 || reservation.check_out < this.dateNow('-')
        })
        return this.pasts
      }
    },
    mounted() {
      this.fetchPendingResa();
      this.reservationsList = this.reservations;
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/my-resa'>

</style>
