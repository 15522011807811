<template>
  <div id="Hortense">
    <div class="container-index">
      <div class="text-header">
        <h1 class="merriweather-reg-30">Qui est Hortense ?</h1>
        <p class="merriweather-italic24">Hortense propose des hébergements de charme qui agissent pour développer le tourisme durable tout en offrant des expériences exceptionnelles.</p>
      </div>
      <div class="img-whois-header">
        <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/Hortense-1.jpeg" v-set-alt>
      </div>
      <div class="row row-newborn">
        <div class="col-lg-6 col-xs-9 offset-lg-3 offset-xs-2">
          <h2 class="raleway-reg-p24">La naissance d’Hortense</h2>
          <p class="raleway-reg-p15">Hortense est une aventure humaine, née du goût prononcé de sa fondatrice pour les voyages, les lieux d’exception et les enjeux de développement durable.
            <br>
            <br>
            Alors que notre conscience écologique a modifié notre quotidien (on trie, on réutilise, on gaspille moins, on mange bio et/ou local), un autre enjeu est de minimiser notre empreinte écologique lors de nos voyages. <br>
            Hortense a pour ambition d’aider les voyageurs à minimiser leur impact écologique grâce à des actions simples, comme :
          </p>
          <div class="act-1 col-lg-7 col-xs-12">
            <h1 class="merriweather-italic67">1</h1>
            <p class="raleway-reg-p15">Privilégier les hébergements éco responsables alliant luxe et écologie</p>
          </div>
          <div class="act-2 col-lg-9 offset-lg-4 col-xs-12">
            <h1 class="merriweather-italic67">2</h1>
            <p class="raleway-reg-p15">Offrir une clé de lecture aux voyageurs pour comprendre l’engagement environnemental de ces hébergements</p>
          </div>
        </div>
      </div>
      <div class="row row-founder">
        <div class="col-lg-6 col-xs-9">
          <h2 class="raleway-reg-p24">Le mot de la fondatrice</h2>
          <p class="raleway-reg-p15">“Le voyage est l’une des expériences les plus enrichissantes qui soit. Les voyages forment l’esprit, le corps et l’âme. Ils permettent également de graver des souvenirs irremplaçables et de découvrir de nouvelles cultures. Le tourisme évolue, mais sa raison première demeure : celle de quitter son quotidien, pour mieux y revenir.” </p>
          <h3 class="merriweather-italic30">“N’arrêtons pas de voyager mais voyageons mieux !” </h3>
          <span class="raleway-reg-p15">Aurore Sterling</span>
        </div>
        <div class="col-lg-6 col-xs-11 fouder-img">
          <img src="../images/fondatrice.jpg" v-set-alt>
        </div>
      </div>

      <!-- Chiffres -->
      <div class="tourism-data">
        <div class="title d-flex">
          <h3 class="raleway-reg-p24">Comprendre les données clés du tourisme durable, c’est déjà mieux voyager.</h3>
          <p class="raleway-bold-p">Quelques chiffres</p>
        </div>
        <div class="row row-tourism-data">
          <div class="col-lg-3 col-xs-9 col-CO2">
              <h1 class="raleway-reg-p65 mb-4">43,1</h1>
            <div class="col-content-1">
              <h2 class="raleway-semi-p15">Milliards de tonnes de CO²</h2>
              <p class="raleway-reg-p15">émises dans le monde, toutes <br>activités confondues</p>
            </div>
          </div>
            <span class="raleway-semi-p15 dont-1">Dont</span>
          <div class="col-lg-2 col-xs-7 col-industry">
            <div class="number-co2 mb-4">
              <h1 class="raleway-reg-p65">3,5</h1>
            </div>
            <div class="content">
              <h2 class="raleway-semi-p15">Milliards de tonnes de CO² soit 8% des émissions mondiales</h2>
              <p class="raleway-reg-p15">juste pour l’industrie touristique</p>
            </div>
          </div>
            <span class="raleway-semi-p15 dont-2">Dont</span>
          <div class="col-lg-2 col-xs-6 col-transport">
            <div class="number-co2 mb-4">
              <h1 class="raleway-reg-p65">2,6</h1>
            </div>
            <div class="content">
              <h2 class="raleway-semi-p15">Milliards de tonnes de CO² soit 6% des émissions mondiales</h2>
              <p class="raleway-reg-p15">pour le transport</p>
            </div>
          </div>
          <span class="raleway-semi-p15 dont-2">Dont</span>
          <div class="col-lg-2 col-xs-6 col-hebergement">
            <div class="number-co2 mb-4">
              <h1 class="raleway-reg-p65">0,7</h1>
            </div>
            <div class="content">
              <h2 class="raleway-semi-p15">Milliards de tonnes de CO² soit moins de 2% des émissions mondiales</h2>
              <p class="raleway-reg-p15">pour l’hébergement touristique</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Section constat -->


      </div>
      <section class="constat">
        <div class="row row-constat">
          <div class="col-lg-3 col-xs-7 offset-lg-1 col-title">
            <h2 class="raleway-reg-p24">Constat</h2>
            <p class="raleway-reg-p15">L’industrie touristique, c’est</p>
          </div>
          <div class="col-lg-3 col-xs-3 col-pourcent">
            <p class="raleway-bold-p">les transports</p>
            <h1 class="raleway-reg-p65 percent">75%</h1>
            <p class="raleway-reg-p15">des émissions de CO²</p>
          </div>
          <div class="col-lg-3 col-xs-3 col-pourcent">
            <p class="raleway-bold-p">Les hébergements</p>
            <h1 class="raleway-reg-p65 percent">21%</h1>
            <p class="raleway-reg-p15">des émissions de CO²</p>
          </div>
        </div>
     </section>
     <div class="container-index">

       <div class="row row-carbon-offset">
        <div class="col-lg-6 col-xs-10 tree-img">
          <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/Hortense-4.jpeg" v-set-alt>
        </div>
        <div class="col-lg-5 col-xs-9 carbon-content">
          <h2 class="raleway-reg-p24 mb-4">Empreinte carbone</h2>
          <p class="raleway-reg-p15">L’empreinte carbone est une mesure qui détermine les émissions de gaz à effet de serre d’une activité. Dans le cadre de la transition écologique, c’est grâce à cette mesure que nous pouvons quantifier l’impact environnemental des activités touristiques. <br><br> <strong>Emission de CO² par nuit et par chambre pour une nuit d’hôtel :</strong></p>
          <div class="row row-number-offset">
            <div class="col-4 col-num-offset">
              <h1 class="merriweather-italic67">6,9</h1>
              <p class="raleway-it-l15">Kg de CO2</p>
              <p class="raleway-bold-p">En moyenne</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row row-ambition">
        <div class="col-lg-6 col-xs-12 offset-lg-3">
          <h2 class="raleway-reg-p24">Notre ambition</h2>
          <p class="raleway-reg-p15">Hortense agit concrètement pour la transition écologique de l’industrie <br>touristique en tentant de réduire l’empreinte environnementale des hébergements sélectionnés, grâce à la synergie de ces 2 démarches :
          </p>
          <div class="act-1 col-lg-9 col-xs-11">
            <h1 class="merriweather-italic67">1</h1>
            <p class="raleway-reg-p15">Accompagner les hébergements sélectionnés dans la mise en place de bonnes pratiques environnementales.</p>
          </div>
          <div class="act-2 col-lg-9 col-xs-11 offset-lg-4">
            <h1 class="merriweather-italic67">2</h1>
            <p class="raleway-reg-p15">Les soutenir financièrement dans le développement de projets écologiques <br>dans le cadre du programme Evergreen.</p>
          </div>
        </div>
      </div>
    </div>

    <div class="img-full"></div>

    <div class="container-index">

      <h3 class="raleway-reg-p24">Nos critères de sélection d’hébergements</h3>
      <div class="row row-select">
        <div class="col-lg-6 col-xs-9">
          <div class="select-1 select">
            <div class="select-num">
              <h1 class="raleway-reg-p24">
                1
              </h1>
            </div>
            <div class="select-content">
              <h2 class="raleway-bold-p">L’excellence au service du tourisme durable</h2>
              <p class="raleway-reg-p15">Nous sélectionnons des boutiques hôtels, des écolodges et des chambres d’hôtes atypiques, uniques et authentiques, proposant une alliance parfaite entre service haut-de-gamme et respect de l’environnement.</p>
            </div>
          </div>
          <div class="select-2 select">
            <div class="select-num">
              <h1 class="raleway-reg-p24">
                2
              </h1>
            </div>
            <div class="select-content">
              <h2 class="raleway-bold-p">Sensibilité écologique</h2>
              <p class="raleway-reg-p15">Nos partenaires répondent à un ensemble de critères écologiques identifiés dans leur gestion opérationnelle quotidienne.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xs-9">
          <div class="select-3 select">
            <div class="select-num">
              <h1 class="raleway-reg-p24">
                3
              </h1>
            </div>
            <div class="select-content">
              <h2 class="raleway-bold-p">Âme des lieux</h2>
              <p class="raleway-reg-p15">Une attention toute particulière a été prêtée par les propriétaires à la création d’un lieu personnel et inspirant.</p>
            </div>
          </div>
          <div class="select-4 select">
            <div class="select-num">
              <h1 class="raleway-reg-p24">
                4
              </h1>
            </div>
            <div class="select-content last-select">
              <h2 class="raleway-bold-p">Place au service</h2>
              <p class="raleway-reg-p15">Nos partenaires prennent le service à cœur. Une notion bien souvent galvaudée que nous souhaitons remettre au centre des rapports entre nos partenaires hôteliers et nos clients.</p>
            </div>
          </div>
        </div>
      </div>
        <!-- Engagement section -->

      <h3 class="raleway-reg-p24 engagement-title text-center">Notre engagement</h3>
      <div class="row row-engagement">
        <div class="col-lg-3 col-xs-6 engage-col">
          <img src="../images/Hébergements eco-responsables.svg" v-set-alt>
          <p class="raleway-reg-p15">Sélectionner des hébergements réellement engagés dans une démarche éco-responsable</p>
        </div>
        <div class="col-lg-3 col-xs-6 engage-col">
          <img src="../images/Acteur engagé.svg" v-set-alt>
          <p class="raleway-reg-p15">Être un acteur engagé dans la promotion du tourisme durable</p>
        </div>
        <div class="col-lg-3 col-xs-6 engage-col-2">
          <img src="../images/Diminution CO2.svg" style="margin-bottom: 23px;" v-set-alt>
          <p class="raleway-reg-p15">Participer activement à la diminution de l’empreinte carbone de nos partenaires</p>
        </div>
        <div class="col-lg-3 col-xs-6 engage-col">
          <img src="../images/Sensibilisation.svg" v-set-alt>
          <p class="raleway-reg-p15">Sensibiliser les hôteliers et les voyageurs aux enjeux du tourisme durable</p>
        </div>
      </div>

        <!-- active hortense -->
      <div class="active-O">
        <div class="header">
          <h3 class="raleway-reg-p24 text-center mb-5">Hortense agit</h3>
        </div>
          <div class="col-12 col-img-phone">
            <img src="../images/Ohortense.svg" v-set-alt>
          </div>
        <div class="row row-active">
          <div class="col-lg-6 col-xs-9">
            <div class="activ-content">
              <h2 class="raleway-bold-p mb-4">Bilan carbone</h2>
              <p class="raleway-reg-p15">Marque déposée par l’Agence de l’Environnement et de la Maîtrise de l’Énergie (ADEME), le bilan carbone est un outil de diagnostic qui permet d’analyser les émissions de CO² d’un particulier, d’une entreprise, d’une collectivité ou encore d’une administration. <br><br> Faire son bilan carbone est une démarche indispensable pour les hébergements souhaitant intégrer la plateforme Hortense. Grâce à ses indicateurs, nous pouvons :</p>
            </div>
            <div class="act-1 col-lg-9 col-xs-11">
              <h1 class="merriweather-italic67">1</h1>
              <p class="raleway-reg-p15">Définir un plan d’actions destiné à améliorer leur bilan environnemental.</p>
            </div>
            <div class="act-2 col-12 offset-2">
              <h1 class="merriweather-italic67">2</h1>
              <p class="raleway-reg-p15">Suivre l’évolution de leur impact environnemental.</p>
            </div>
            <div class="act-3 col-11">
              <h1 class="merriweather-italic67">3</h1>
              <p class="raleway-reg-p15">Quantifier l’émission de CO² d’une nuitée, afin de la rendre visible des voyageurs qui pourront ensuite la contrebalancer en soutenant des projets écologiques portés par nos hébergements partenaires.</p>
            </div>
          </div>
          <div class="col-6 col-img">
            <img src="../images/Ohortense.svg" v-set-alt>
          </div>
        </div>
      </div>


      <!-- green Host -->

      <div class="row green-host-row">
        <div class="col-lg-6 col-xs-1 badge">
          <img src="../images/Label.svg" v-set-alt>
        </div>
        <div class="col-lg-6 col-xs-11 badge-content">
          <h2 class="raleway-bold-p mb-4">Le label Green Host</h2>
          <p class="raleway-reg-p15 mb-4">Développé par Hortense, “Green Host” est un label de confiance attribué aux hébergements engagés dans une démarche responsable de protection de l’environnement. <br><br>Pour l’obtenir et faire partie de la sélection d’Hortense, les hébergements doivent respecter 10 critères environnementaux déclinés en 150 indicateurs de conformité retranscrits dans la Green Note.</p>
          <a href="/label-ecoresponsable-green-host/" class="hortense-btn-primary">Découvrir le label Green Host</a>
        </div>
      </div>

      <!-- GREEN NOTE SECTION -->
      <div class="row green-note-row">
        <div class="col-xs-4 col-sm-8 offset-sm-4 img-phone">
          <img src="../images/greenFull.svg" v-set-alt>
        </div>
        <div class="col-lg-8 col-xs-9">
          <div class="header">
            <h2 class="raleway-bold-p mb-5">La Green Note</h2>
            <p class="raleway-reg-p15 mb-5">La Green Note évalue l’engagement éco-responsable réel des <br>hébergements partenaires et leur évolution selon 10 critères :</p>
          </div>
          <div class="row row-list">
            <div class="col-lg-6 col-sm-6 col-xs-9 margin-list">
              <div class="list-item">
                <h1 class="merriweather-italic30">1</h1>
                <p class="raleway-reg-p15">La politique et la gestion environnementale</p>
              </div>
              <div class="list-item">
                <h1 class="merriweather-italic30">2</h1>
                <p class="raleway-reg-p15">La responsabilité sociale</p>
              </div>
              <div class="list-item">
                <h1 class="merriweather-italic30">3</h1>
                <p class="raleway-reg-p15">La formation du personnel</p>
              </div>
              <div class="list-item">
                <h1 class="merriweather-italic30">4</h1>
                <p class="raleway-reg-p15">La sensibilisation des clients</p>
              </div>
              <div class="list-item">
                <h1 class="merriweather-italic30">5</h1>
                <p class="raleway-reg-p15">La gestion de l’énergie</p>
              </div>
            </div>
            <div class="col-lg-6 col-sm-6 col-xs-9 margin-list">
              <div class="list-item">
                <h1 class="merriweather-italic30">6</h1>
                <p class="raleway-reg-p15">La gestion de l’eau</p>
              </div>
              <div class="list-item">
                <h1 class="merriweather-italic30">7</h1>
                <p class="raleway-reg-p15">La gestion des déchets</p>
              </div>
              <div class="list-item">
                <h1 class="merriweather-italic30">8</h1>
                <p class="raleway-reg-p15">La politique d'achats responsables</p>
              </div>
              <div class="list-item">
                <h1 class="merriweather-italic30">9</h1>
                <p class="raleway-reg-p15">L'environnement extérieur et intérieur</p>
              </div>
              <div class="list-item">
                <h1 class="merriweather-italic30">10</h1>
                <p class="raleway-reg-p15">L’éco-construction</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 col-img">
          <img src="../images/greenFull.svg" v-set-alt>
        </div>
      </div>
    </div>

      <!-- EVERGREEN section -->

    <div class="evergreen-program">
      <div class="row row-evergreen">
        <div class="col-lg-6 col-xs-10 offset-lg-5 pl-5">
          <h2 class="raleway-bold-p">Le programme Evergreen</h2>
          <p class="raleway-reg-p15">Hortense est une plateforme engagée : Un pourcentage du prix de la nuitée et donc de notre chiffre d’affaires permet de financer des projets écotouristiques. <br><br>
           Les voyageurs ont la possibilité d’être acteurs du développement du tourisme durable : nous les invitons à participer au financement des projets écologiques de nos partenaires en apportant une contribution financière. <br> En retour de leur engagement, nous leur proposons des services exclusifs, des avantages et des privilèges négociés avec nos partenaires : petit-déjeuner offert, surclassement, accès au spa, repas gastronomique…</p>
          <p class="raleway-reg-p15 mt-4 mb-4">Quelques exemples de projets :</p>
          <ul>
            <li class="raleway-reg-p15 mb-1">Travaux d’isolation thermique </li>
            <li class="raleway-reg-p15 mb-1">Mise en place d’un système de récupération d’eau de pluie</li>
            <li class="raleway-reg-p15 mb-1">Installation de panneaux solaires</li>
            <li class="raleway-reg-p15 mb-1">Création d’un potager bio </li>
            <li class="raleway-reg-p15">Aménagement d’espaces verts</li>
          </ul>
          <a href="/programme-evergreen-demarche-environnementale/" class="hortense-btn-primary mt-5">Découvrir le programme Evergreen</a>
        </div>
      </div>
    </div>

    <!-- last section -->
    <div class="last">
      <img src="../images/Logo hortense.svg" v-set-alt >
      <h2 class="merriweather-italic24 mt-4">Des lieux exceptionnels où luxe et durabilité sont indissociables</h2>
    </div>
     <ScrollUp></ScrollUp>
  </div>
</template>


<script>

  import ScrollUp from 'components/btns/ScrollUp.vue';

  export default {
    name: 'Hortense',
    components: { ScrollUp },
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/hortenses'>

</style>
