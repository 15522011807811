<template>
  <div id="wedding">
    <div class="wedding-container-index">
      <h1 class="text-center header" :class="{'merriweather-reg-30':!isPhone,'merriweather-reg-24':isPhone}">Hortense For Wedding</h1>
      <div id="banner-wedding">
          <div class="banner-show-container">
            <div class="">
              <p id="desire-p" :class="{ 'merriweather-italic24': !isPhone,  'merriweather-italic-15': isPhone}">
                Envie d’organiser un mariage éco-responsable ? <br/>
                Hortense vous accompagne dans votre démarche <br/>
                et vous offre des avantages exclusifs.
              </p>
            </div>
          </div>
      </div>
      <p :class="{ 'raleway-reg-p24 mt-5 desktop-margin-left': !isPhone, 'raleway-semi-p15': isPhone }"><strong>Hortense For Wedding, c’est :</strong></p>
      <div class="explanation">
        <div class="title">
          <div :class="{ 'pr-5': !isPhone,  'text-center mb-3': isPhone}">
            <img src="../images/hortense-for-wedding/Groupe 102.png" v-set-alt>
          </div>
          <div class="list-title">
            <h3 :class="{'raleway-semi-p13 mb-2':isPhone,'raleway-semi-p15 mb-2':!isPhone}"> UN LIEU DE CÉLÉBRATION RESPECTUEUX DE L’ENVIRONNEMENT</h3>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Nous sélectionnons pour vous des hébergements (hôtels, chambres d’hôtes, gîtes, villas) et/ou des lieux de réception engagés dans une démarche environnementale.</p>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">L’engagement environnemental de nos établissements est vérifié via notre modèle de scoring environnemental : la Green Note.</p>
          </div>
        </div>
      </div>
      <div v-if="!isPhone">
          <div class="row mt-5 desktop-margin-right desktop-margin-left">
              <div class="centered-div text-center raleway-bold-p15 col">
                <img src="../images/hortense-for-wedding/Groupe 21.png" v-set-alt class="desktop-img">
                LA POLITIQUE ET LA GESTION <br/>ENVIRONNEMENTALE
              </div>
              <div class="centered-div raleway-bold-p15 col">
                <img src="../images/hortense-for-wedding/Groupe 24.png" v-set-alt class="desktop-img"> 
                LA RESPONSABILITÉ SOCIALE
              </div>
          </div>
          <div class="row mt-5 desktop-margin-right desktop-margin-left">
              <div class="centered-div raleway-bold-p15 col">
                <img src="../images/hortense-for-wedding/Groupe 27.png" v-set-alt class="desktop-img">
                LA FORMATION DU PERSONNEL
              </div>
              <div class="centered-div raleway-bold-p15 col">
                <img src="../images/hortense-for-wedding/Groupe 44.png" v-set-alt class="desktop-img"> 
                LA GESTION DE L’ÉNERGIE
              </div>
          </div>
          <div class="row mt-5 desktop-margin-right desktop-margin-left">
              <div class="centered-div raleway-bold-p15 col">
                <img src="../images/hortense-for-wedding/Groupe 33.png" v-set-alt class="desktop-img">
                LA SENSIBILISATION DES CLIENTS
              </div>
              <div class="centered-div raleway-bold-p15 col">
                <img src="../images/hortense-for-wedding/Groupe 36.png" v-set-alt class="desktop-img"> 
                LA GESTION DES DÉCHETS
              </div>
          </div>
          <div class="row mt-5 desktop-margin-right desktop-margin-left">
              <div class="centered-div raleway-bold-p15 col">
                <img src="../images/hortense-for-wedding/Groupe 41.png" v-set-alt class="desktop-img">
                LA GESTION DE L’EAU
              </div>
              <div class="centered-div raleway-bold-p15 col">
                <img src="../images/hortense-for-wedding/Groupe 30.png" v-set-alt class="desktop-img"> 
                LA POLITIQUE D’ACHATS RESPONSABLES
              </div>
          </div>
          <div class="row mt-5 desktop-margin-right desktop-margin-left">
              <div class="centered-div raleway-bold-p15 col">
                <img src="../images/hortense-for-wedding/Groupe 47.png" v-set-alt class="desktop-img">
                L’ENVIRONNEMENT EXTÉRIEUR ET INTÉRIEUR
              </div>
              <div class="centered-div raleway-bold-p15 col">
                <img src="../images/hortense-for-wedding/Groupe 50.png" v-set-alt class="desktop-img"> 
                L’ÉCO-CONSTRUCTION
              </div>
          </div>
      </div>
      <table v-else class="images-group">
        <tr>
          <td>
            <div class="row mt-5 mobile-margin-left mr-3">
                  <div class="centered-div raleway-bold-p11 text-center col-12 mb-3">
                    <img src="../images/hortense-for-wedding/Groupe 21.png" v-set-alt class="desktop-img">
                    LA POLITIQUE ET LA GESTION <br/>ENVIRONNEMENTALE
                  </div>
                  <div class="centered-div raleway-bold-p11 text-center col-12">
                    <img src="../images/hortense-for-wedding/Groupe 24.png" v-set-alt class="desktop-img"> 
                    LA RESPONSABILITÉ SOCIALE
                  </div>
            </div>
          </td>
          <td>
            <div class="row mt-5 mobile-margin-left mr-3">
                  <div class="centered-div raleway-bold-p11 text-center col-12 mb-3">
                    <img src="../images/hortense-for-wedding/Groupe 27.png" v-set-alt class="desktop-img">
                    LA FORMATION DU PERSONNEL
                  </div>
                  <div class="centered-div raleway-bold-p11 text-center col-12">
                    <img src="../images/hortense-for-wedding/Groupe 44.png" v-set-alt class="desktop-img"> 
                    LA GESTION DE L’ÉNERGIE
                  </div>
              </div>
          </td>
          <td>
              <div class="row mt-5 mobile-margin-left mr-3">
                  <div class="centered-div raleway-bold-p11 text-center col-12 mb-3">
                    <img src="../images/hortense-for-wedding/Groupe 33.png" v-set-alt class="desktop-img">
                    LA SENSIBILISATION DES CLIENTS
                  </div>
                  <div class="centered-div raleway-bold-p11 text-center col-12">
                    <img src="../images/hortense-for-wedding/Groupe 36.png" v-set-alt class="desktop-img"> 
                    LA GESTION DES DÉCHETS
                  </div>
              </div>
          </td>
          <td>
             <div class="row mt-5 mobile-margin-left mr-3">
                  <div class="centered-div raleway-bold-p11 text-center col-12 mb-3">
                    <img src="../images/hortense-for-wedding/Groupe 41.png" v-set-alt class="desktop-img">
                    LA GESTION DE L’EAU
                  </div>
                  <div class="centered-div raleway-bold-p11 text-center col-12">
                    <img src="../images/hortense-for-wedding/Groupe 30.png" v-set-alt class="desktop-img"> 
                    LA POLITIQUE D’ACHATS <br/> RESPONSABLES
                  </div>
              </div>
          </td>
          <td>
              <div class="row mt-5 mobile-margin-left mr-3">
                  <div class="centered-div raleway-bold-p11 text-center col-12  mb-3">
                    <img src="../images/hortense-for-wedding/Groupe 47.png" v-set-alt class="desktop-img">
                    L’ENVIRONNEMENT EXTÉRIEUR <br/> ET INTÉRIEUR
                  </div>
                  <div class="centered-div raleway-bold-p11 text-center col-12">
                    <img src="../images/hortense-for-wedding/Groupe 50.png" v-set-alt class="desktop-img"> 
                    L’ÉCO-CONSTRUCTION
                  </div>
              </div>
          </td>
        </tr>
      </table>

      <div id="synthesis" :class="{'merriweather-reg-15':!isPhone,'merriweather-reg-13':isPhone}">
        <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}"><strong>En synthèse, nous vous proposons :</strong></p>
        <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">- Un lieu de célébration éco-friendly</p>
        <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">- Des hébergements éco-responsables pour vos invités</p>
      </div>

      <div class="explanation">
        <div class="title">
          <div :class="{ 'pr-5': !isPhone,  'text-center mb-3': isPhone}">
            <img src="../images/hortense-for-wedding/Groupe 100.png" v-set-alt>
          </div>
          <div class="list-title">
            <h3 class="mb-2" :class="{'raleway-semi-p13':isPhone,'raleway-semi-p15':!isPhone}"> UN LIEU D’EXCEPTION AU SERVICE DU TOURISME DURABLE POUR VOTRE VOYAGE DE NOCES</h3>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Nous dénichons pour vous les plus belles adresses pour faire de votre lune de miel : un séjour en amoureux mémorable.</p>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Hôtels de charme, palaces somptueux, bungalows sur pilotis, lodges perchés sous les étoiles, Hortense vous propose des lieux uniques en fonction de vos envies avec des services exclusifs négociés rien que pour vous.</p>
          </div>
        </div>
      </div>

      <div class="explanation">
        <div class="title">
          <div :class="{ 'pr-5': !isPhone,  'text-center mb-3': isPhone}">
            <img src="../images/hortense-for-wedding/Groupe 101.png" v-set-alt>
          </div>
          <div class="list-title">
            <h3 :class="{'raleway-semi-p13 mb-2':isPhone,'raleway-semi-p15 mb-2':!isPhone}">L’ORGANISATION D’UNE LUNE DE MIEL ÉCO-RESPONSABLE</h3>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Nous vous accompagnerons dans l’organisation de votre séjour sur mesure, tout en limitant votre impact environnemental.</p>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Des hébergements, aux activités et aux bonnes adresses, votre travel planner réalisera un itinéraire personnalisé, à votre image avec un carnet de route détaillé, créé uniquement pour vous.</p>
          </div>
        </div>
      </div>
      <div class="container mt-2">
            <div class="row">
              <div class="col-12 text-center">
                <a :href="'/voyage-sur-mesure/'" class="hortense-btn-primary" :class="{'made-trip-button':!isPhone}">VOTRE VOYAGE SUR MESURE</a>
              </div>
            </div>
      </div>
      <!--Banner in the middle-->
      <div id="banner-in-middle">
          <div class="banner-show-container">
            <div class="">
              <p id="desire-p" :class="{ 'merriweather-italic24': !isPhone,  'merriweather-italic-15': isPhone}">
                Grâce à Hortense, organisez une </br> lune de miel inoubliable.
              </p>
            </div>
          </div>
      </div>

      <div class="explanation">
        <div class="title">
          <div :class="{ 'pr-5': !isPhone,  'text-center mb-3': isPhone}">
            <img src="../images/hortense-for-wedding/Groupe 59.png" v-set-alt width="150px" >
          </div>
          <div class="list-title">
            <h3 :class="{'raleway-semi-p13 mb-2':isPhone,'raleway-semi-p15 mb-2':!isPhone}">UNE ATTENTION PARTICULIÈRE POUR VOUS</h3>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Hortense vous offre une carte cadeau de 100€ pour tout séjour de 4 nuits et plus chez l’un de nos hébergements partenaires.</p>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">La carte cadeau est valable 1 an à compter de l’émission et est à utiliser sur le site hortense.green</p>
          </div>
        </div>
      </div>

       <div class="explanation">
        <div class="title">
          <div :class="{ 'pr-5': !isPhone,  'text-center mb-3': isPhone}">
            <img src="../images/hortense-for-wedding/Groupe 68.png" v-set-alt width="150px">
          </div>
          <div class="list-title">
            <h3 :class="{'raleway-semi-p13 mb-2':isPhone,'raleway-semi-p15 mb-2':!isPhone}">UNE ATTENTION PARTICULIÈRE POUR VOS INVITÉS</h3>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Nous offrons à tous vos invités 1000 points de fidélité de bienvenue.</p>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Ces points de fidélité leur permettront :</p>
            <ul>
              <li>d’accéder immédiatement au statut Green Trotter Plus de notre programme de fidélité dès leur première réservation</li>
              <li>et de bénéficier des services exclusifs négociés par Hortense avec chaque hébergement partenaire.</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mt-5" :class="{ 'desktop-margin-left desktop-margin-right': !isPhone }">
       <p class="raleway-semi-p13"><strong>NOTRE PROGRAMME DE FIDÉLITÉ HORTENSIA</strong></p>
        <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">En étant membre de notre programme de fidélité, vos proches agissent pour le développement du tourisme durable tout en profitant de privilèges lors de leur séjour.</p>
        <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Négociés avec les hébergements partenaires, de nombreux avantages comme le surclassement, l’accès gratuit au spa, une nuit offerte, un dîner offert, le petit-déjeuner offert ou encore le check-in avancé / tardif sont disponibles en fonction de chaque statut de fidélité.</p>
        <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Vos proches bénéficieront également d’attentions particulières avec des cadeaux offerts à leur retour.</p>
      </div>
      <div id="hortensia" :class="{'hortensia-mobile': isPhone}">
        <div class="card-statut">
          <div class="row row-statut" :class="{'desktop-row': !isPhone}">
              <div class="col-lg-2 col-xs-6 col-statut">
                <img src="/packs/media/images/badge-guest-9e5fca6b67499e5b2ea4ea223a3f040b.png" v-set-alt class="guest statut-img"> 
                <h3 class="raleway-bold-p">Green Trotter</h3>
                <p class="raleway-reg-p13">500 points soit 10 nuits réservées</p>
                <div>
                    <i class="fas fa-check" aria-hidden="true">
                      <p class="raleway-reg-p13" style="display: inline; font-style: italic;">Service Exclusif 1</p>
                    </i>
                </div>
                <br> <br> <br>
              </div>
              <div class="col-lg-2 col-xs-6 col-statut-trotter" style="border:1px solid #D3AF6C">
                <img src="/packs/media/images/badge-member-33479820a4a224dd3e016eb862bfd86c.png" v-set-alt class="guest statut-img"> 
                <h3 class="raleway-bold-p">Green Trotter <br>Plus</h3>
                <p class="raleway-reg-p13">1 000 points soit 20 nuits réservées</p>
                <div>
                    <i class="fas fa-check" aria-hidden="true">
                      <p class="raleway-reg-p13" style="display: inline; font-style: italic;">Service Exclusif 1</p>
                    </i>
                </div>
                <div>
                    <i class="fas fa-check" aria-hidden="true">
                      <p class="raleway-reg-p13" style="display: inline; font-style: italic;">Service Exclusif 2</p>
                    </i>
                </div>
                <br> <br>
              </div>
              <div class="col-lg-2 col-xs-6">
                <img src="/packs/media/images/badge-nomad@2x-c70ffa2081e9900406ce514f95722277.png" v-set-alt class="nomad statut-img"> 
                <p class="raleway-bold-p">Green Trotter <br>Premium</p>
                <p class="raleway-reg-p13">1 500 points soit 30 nuits réservées</p>
                <div>
                    <i class="fas fa-check" aria-hidden="true">
                      <p class="raleway-reg-p13" style="display: inline; font-style: italic;">Service Exclusif 1</p>
                    </i>
                </div>
                <div>
                    <i class="fas fa-check" aria-hidden="true">
                      <p class="raleway-reg-p13" style="display: inline; font-style: italic;">Service Exclusif 2</p>
                    </i>
                </div>
                <div>
                    <i class="fas fa-check" aria-hidden="true">
                      <p class="raleway-reg-p13" style="display: inline; font-style: italic;">Service Exclusif 3</p>
                    </i>
                </div>
                <br>
              </div>
              <div class="col-lg-2 col-xs-6">
                <img src="/packs/media/images/badge-trotter-6b18e0cf04f7a49d272a6fb49f6e7670.png" v-set-alt class="trotter statut-img"> 
                <p class="raleway-bold-p">Green Trotter <br>Infinite</p>
                <p class="raleway-reg-p13">2 500 points soit 50 nuits réservées</p>
                <div>
                    <i class="fas fa-check" aria-hidden="true">
                      <p class="raleway-reg-p13" style="display: inline; font-style: italic;">Service Exclusif 1</p>
                    </i>
                </div>
                <div>
                    <i class="fas fa-check" aria-hidden="true">
                      <p class="raleway-reg-p13" style="display: inline; font-style: italic;">Service Exclusif 2</p>
                    </i>
                </div>
                <div>
                    <i class="fas fa-check" aria-hidden="true">
                      <p class="raleway-reg-p13" style="display: inline; font-style: italic;">Service Exclusif 3</p>
                    </i>
                </div>
                <div>
                    <i class="fas fa-check" aria-hidden="true">
                      <p class="raleway-reg-p13" style="display: inline; font-style: italic;">Service Exclusif 4</p>
                    </i>
                </div>
              </div>
          </div>
        </div>
      </div>
      <!--Banner planete in the bottom-->
      <div id="banner-earth">
          <div class="banner-show-container">
            <div class="">
              <p id="desire-p" :class="{ 'merriweather-italic24': !isPhone,  'merriweather-italic-15': isPhone}">
                Avec Hortense, vous devenez un acteur du tourisme durable !
              </p>
            </div>
          </div>
      </div>

      <div class="mt-2" :class="{ 'desktop-engaged': !isPhone }">
        <p class="raleway-semi-p13">HORTENSE, UNE PLATEFORME DE RÉSERVATION ENGAGÉE</p>
        <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Hortense identifie et soutient des projets écologiques destinés à réduire l’empreinte environnementale de ses hébergements partenaires.</p>
        <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Voici quelques exemples de projets :</p>
      </div>
      <div class="container">
        <div class="text-center" :class="{'images-group': isPhone,'row': !isPhone}">
          <div :class="{'col-8': isPhone, 'col-3': !isPhone}">
               <img src="../images/hortense-for-wedding/voltaique.png" v-set-alt class="mb-2"> 
               <p class="raleway-reg-p13 text-center">Installation de panneaux </br> photovoltaiques</p>
          </div>
          <div :class="{'col-8': isPhone, 'col-3': !isPhone}">
               <img src="../images/hortense-for-wedding/potage.png" v-set-alt class="mb-2"> 
               <p class="raleway-reg-p13 text-center">Création d'un potager </br> bio</p>
          </div>
          <div :class="{'col-8': isPhone, 'col-3': !isPhone}">
               <img src="../images/hortense-for-wedding/pluie.png" v-set-alt class="mb-2"> 
               <p class="raleway-reg-p13 text-center">Installation d'un </br> système de </br> récupération d'eau de </br> pluie</p>
          </div>
           <div :class="{'col-8': isPhone, 'col-3': !isPhone}">
               <img src="../images/hortense-for-wedding/piscine.png" v-set-alt class="mb-2">  
               <p class="raleway-reg-p13 text-center">Création d'une piscine </br> naturelle</p>
          </div>
        </div>
      </div>

      <div class="container mt-2" :class="{ 'desktop-engaged': !isPhone }">
        <div class="row">
          <div :class="{ 'col-2':!isPhone,'col-3':isPhone }">
            <p class="merriweather-reg-40">1% </p>
          </div>
          <div :class="{ 'col-10':!isPhone,'col-9':isPhone }">
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">du montant de la location du lieu de réception et de la réservation d’une nuitée par vos invités est reversé pour financer le programme Evergreen.</p>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Pour vous et vos convives, le tarif ne change pas.</p>
            <p :class="{'raleway-reg-p13':isPhone,'raleway-reg-p15':!isPhone}">Le programme Evergreen est financé par Hortense via les commissions qu’elle reçoit des hébergements partenaires et des lieux de réception.</p>
          </div>            
        </div>
      </div>      
      <div class="contact-us text-center">
        <h4 class="raleway-bold-p16">CONTACTEZ NOUS</h4>
        <a class="raleway-reg-p13 link-hortense" href="mailto:contact@hortense.green">contact@hortense.green</a>
      </div>
    <ScrollUp></ScrollUp>
    </div>
  </div>
</template>


<script>

  import ScrollUp from 'components/btns/ScrollUp.vue';

  export default {
    name: 'wedding',
    components: { ScrollUp },
    computed: {
      isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
     }
    }

  }

</script>


<style lang="scss" scope='app/assets/stylesheets/components/static-pages'>
 .made-trip-button {
    width: 20vw;
    display: inline;
    //background-color: #3B6152;
  }
 .desktop-img{
  position: absolute;
  left: -37px;
  margin-right: 15px;
 }
 .images-group{
   display: flex;
   gap: 1.5rem;
   overflow-y: scroll;
 } 
 .hortensia-mobile{
   width: 88vw !important;
 }
.desktop-row{
  margin-left:-5px !important;
  margin-right:-290px !important;
}
.desktop-margin-left{
  margin-left: 10rem;
}
.mobile-margin-left{
  margin-left: 2rem;
}
.desktop-engaged{
  margin-left: 30rem;
  width: 40vw;
}
.desktop-margin-right{
  margin-right: 10rem;
}
.explanation{
  ul{
        list-style: none;
        list-style-image: url('../images/Icotype_puce.svg');
        font-family: $Raleway;
        font-weight: 400;
        font-size: 13px;
    }
}
.centered-div {
    color: white; 
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #305F51;
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      width: 66vw;   
    }
    width: 100vw; 
    height: 13vh;
    margin-left: 5vw;
}
.wedding-container-index {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 15vh;
  overflow: hidden;
}

#banner-in-middle {
  width: 100%;
  height: 290px;
  margin-top: 20vh;
  background-image: url("../images/hortense-for-wedding/middle-banner.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
   .banner-show-container {
      width: 100%;
      height: 250px;
      .catch-hub-show {
        color: white;
        margin-top: 4rem;
      }
      #desire-p {
          color: white;
          margin-left: 5vw;
      }
    }
    .btn-galerie {
      width: 100%;
      margin-left: 5rem;
      cursor: pointer;
      .galerie-icon {
        background-color: rgba(0, 0, 0, 0.4);
        // opacity: 0.4;
        border-radius: 60px;
        padding: 1rem 2rem;
        color: white;
        width: 152px;
        height: 48px;
      }
    }
    .btn-galerie-phone {
      display: none;
    }
  }

#banner-earth {
  width: 100%;
  height: 290px;
  margin-top: 20vh;
  background-image: url("../images/hortense-for-wedding/planete22x.png");
  background-color: #82c682;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    height: 220px;
  }
   .banner-show-container {
      width: 100%;
      height: 250px;

      .catch-hub-show {
        color: white;
        margin-top: 4rem;
      }
      #desire-p {
          color: white;
          margin-left: 40vw;
      }
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      .banner-show-container {
        height: 140px;
      }
    }

    .btn-galerie {
      width: 100%;
      margin-left: 5rem;
      cursor: pointer;
      .galerie-icon {
        background-color: rgba(0, 0, 0, 0.4);
        // opacity: 0.4;
        border-radius: 60px;
        padding: 1rem 2rem;
        color: white;
        width: 152px;
        height: 48px;
      }
    }
    .btn-galerie-phone {
      display: none;
    }
  }

#banner-wedding { 
    width: 100%;
    height: 694px;
    margin-top: 5vh;
    background-image: url("../images/hortense-for-wedding/wedding-banner.png");
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    .banner-show-container {
      width: 100%;
      height: 650px;

      .catch-hub-show {
        color: white;
        margin-top: 4rem;
      }
      #desire-p {
          color: white;
          margin-left: 55vw;
          margin-top: 30vw;
      }
    }
    .btn-galerie {
      width: 100%;
      margin-left: 5rem;
      cursor: pointer;
      .galerie-icon {
        background-color: rgba(0, 0, 0, 0.4);
        // opacity: 0.4;
        border-radius: 60px;
        padding: 1rem 2rem;
        color: white;
        width: 152px;
        height: 48px;
      }
    }
    .btn-galerie-phone {
      display: none;
    }
  }



@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .wedding-container-index {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 15vh;
      overflow: hidden;
  }
  #banner-wedding {
        margin-top: 5vh;
        display: block;
        .btn-galerie-phone {
          display: block;
          //width: 100%;
          margin-left: 1rem;
          .galerie-icon {
            background-color: rgba(0, 0, 0, 0.4);
            // opacity: 0.4;
            border-radius: 60px;
            padding: 1rem 2rem;
            color: white;
            width: 152px;
            height: 48px;
          }
        }
        .btn-galerie {
          display: none;
        }
        .banner-show-container {
          width: 100%;
          height: 700px;
          display: flex;
          align-items: flex-end;
          .catch-hub-show {
            margin-left: 1rem;
            p {
              font-size: 26px;
            }
          }
          #desire-p {
            color: white;
            margin-left: 5vw;
            margin-bottom: 20%;
          }
        }
      }
}
</style>
<style lang="scss" scope='app/assets/stylesheets/components/hortensia'></style>