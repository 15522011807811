<template>
  <div id="burger" class="first-nav">
    <Slide
      width="415"
      right
      @openMenu="removeItemInFront"
      @closeMenu="addItemInFront"
    >
      <a id="menu-b" href="/">
        <img src="../../images/Logo hortense.svg" v-set-alt>
      </a>
      <ul class="nav-link" style="list-style: none; padding-left: 3rem;">
        <!-- <li class="item-nav raleway-reg-p16"><a :href="'/club-hortense'" class="no-deco">{{ $t("clubs") }}</a></li> -->
        <li class="item-nav raleway-reg-p16"><a :href="'/reservation-hotel'" class="no-deco">{{ $t("selection") }}</a></li>
        <li class="item-nav raleway-reg-p16"><a :href="'/collections'" class="no-deco">{{ $t("collections") }}</a></li>
<!--        <li class="item-nav raleway-reg-p16"><a :href="'/experiences-bien-etre'" class="no-deco">{{ $t("yogacation") }}</a></li>-->
<!--        <li class="item-nav raleway-reg-p16"><a :href="'/offres-exclusives'" class="no-deco">{{ $t("exclusive") }}</a></li>-->
        <li class="item-nav raleway-reg-p16"><a :href="'/destination'" class="no-deco">{{ $t("destinations") }}</a></li>
        <li class="item-nav raleway-reg-p16"><a :href="'/inspirations-voyage-ecoresponsable'" class="no-deco">Inspirations</a></li>
<!--        <li class="item-nav raleway-reg-p16"><a :href="'/experiences'" class="no-deco">{{ $t("experiences") }}</a></li>-->
        <li class="item-nav raleway-reg-p16"><a :href="'/galerie-hotel-ecoresponsable'" class="no-deco">Galerie</a></li>
        <li class="item-nav raleway-reg-p16"><a href="https://www.blog.hortense.green/" class="no-deco">{{ $t("newspaper") }}</a></li>
      </ul>
      <vue-tree-navigation :items="items" :defaultOpenLevel="0" />
      <div class="login-section raleway-bold-p" >
        <div v-if="this.user" class="d-flex">
          <a  :href="'/dashboard/' + this.user.slug" class="no-deco-profil">Mon profil | </a>
          <a href="/users/sign_out" data-method="delete" class="no-deco-profil">Déconnexion</a>
        </div>
          <p v-else @click="openModalSign" class="raleway-bold-p">Créer un compte | Se connecter</p>
      </div>
    </Slide>
    <Modalsignup v-if="showModal" @close="setUser"></Modalsignup>
  </div>
</template>

<script>
  import Modalsignup from 'components/modals/Modalsignup.vue';
  import { Slide } from 'vue-burger-menu';

  export default{
    name: 'burger',
    props: ["user"],
    components: { Slide, Modalsignup },
    data() {
      return {
          showModal: false,
          isActiveHortense: false,
          isActiveCurrencies: false,
          selectedCurrency: "FR/EUR",
          currencies: [{"currency": "FR/EUR", "lang": "fr"}, {"currency": "UK/GBP", "lang": "en"}, {'currency': "US/USD", "lang": "en"}],
          locale: 'fr',
          // origin: window.location.origin,

        items: [
          { name: 'Qui est hortense?', children: [
          { name: 'Qui est hortense?', path: '/qui-est-hortense-voyage-ecoresponsable/' },
          { name: 'Le label green host', path: '/label-ecoresponsable-green-host/' },
          { name: 'La green note', path: '/green-note-engagement-environnemental' },
          { name: 'Le programme evergreen', path: '/programme-evergreen-demarche-environnementale/' },
          { name: 'Le programme de fidélité', path: '/programme-fidélité-hortensia/' },
          ]},
        ],

        itemsToggleDisplay: []
      };
    },
    watch: {
      locale(val) {
        this._i18n.locale = val
      }
    },
    methods: {
      userInit(string){
        const names = string.split(','),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      },
      setUser(user){
        this.user = user;
        this.showModal = false;
      },
      openModalSign(){
        this.showModal = !this.showModal
      },
      showHortense() {
        this.isActiveHortense = !this.isActiveHortense;
      },
      showCurrencies(e) {
        const el = e.currentTarget.lastElementChild;
        el.style.transform = el.style.transform ? '' : 'rotateZ(180deg)';
        this.isActiveCurrencies = !this.isActiveCurrencies;
      },
      currenciesLeft() {
        return this.currencies.filter(x => {return x.currency !== this.selectedCurrency })
      },
      selectCurrency(e) {
        this._i18n.locale = e.currentTarget.value;
        this.selectedCurrency = e.currentTarget.innerText;
        this.isActiveCurrencies = false;
        const el = document.querySelector('.arrow');
        el.style.transform = el.style.transform ? '' : 'rotateZ(180deg)';
      },
      removeItemInFront() {
        this.itemsToggleDisplay.forEach(item => {
          if(item)
            item.classList.add('d-none')
        })
      },
      addItemInFront() {
        this.itemsToggleDisplay.forEach(item => {
          if(item)
            item.classList.remove('d-none')
        })
      }
    },
    mounted() {
      this.itemsToggleDisplay = [
        document.querySelector('.phone-btn'),
        document.querySelector('.select-list')
      ]
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/burger' scoped>

</style>
