<template  v-if="this.yoga_teacher.teacher_name">
  <div class="container mt-lg-2 mt-md-2 pt-lg-2 pt-md-2" v-if="this.yoga_teacher.teacher_name">
    <div class="row pt-5 pb-5 pr-2">
      <div class="col-md-6 col-lg-6 col-sm-12 align-self-center">
        <div class="row">
          <h6 class="pb-2" style="font: normal normal normal 26px/33px Merriweather;">{{ this.yoga_teacher.teacher_name }}</h6>
          <a v-if="this.yoga_teacher.insta_link_one" class="ml-3 mt-2" target="_blank" :href="this.yoga_teacher.insta_link_one">
            <i style="color: #3B6152" class="fa-brands fa-instagram-square fa-2xl"></i>
          </a>
          <a v-if="this.yoga_teacher.insta_link_two" class="ml-3 mt-2" target="_blank" :href="this.yoga_teacher.insta_link_two">
            <i style="color: #3B6152" class="fa-brands fa-instagram-square fa-2xl"></i>
          </a>
          </div>
        <div class="mab raleway-reg-p15" style="line-height: 20px !important;" v-html="this.yoga_teacher.teacher_description"></div>
      </div>
      <div class="col-md-6 col-lg-6 p-3 col-sm-12">
        <img class="img-fluid" v-if="this.yoga_teacher.teacher_photo" :src="this.yoga_teacher.teacher_photo" v-set-alt>
      </div>
    </div>
  </div>
</template>

<script>
import myImage from '../../images/yoga_thumb.png';
export default {
  props: ['yoga_teacher'],
  data(){
    return{
      myImage:myImage,
    }
  },
  name: 'YogaTeacher',
  methods: {
  }
}
</script>

<style scoped>
.mab{
  margin-bottom: 0 !important;
  font: normal normal normal 15px/35px Raleway;
  line-height: 30px;
}
.mab2{
  font: normal normal 600 24px/42px Raleway;
  letter-spacing: 0px;
  line-height: 10px;
}
.mab3 {
  text-align: left;
  font: normal normal normal 15px/18px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

</style>