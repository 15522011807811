<template>
  <div id="cardTestimony">
    <div class="row row-testimony">
      <div class="col-6 offset-3 col-testimony">
        <p class="raleway-reg-p15 mb-5">«  ipsum dolor sit amet, consectetur adipiscing elit. Nullam justo est, condimentum ut placerat a, rhoncus ut ante. Donec placerat leo arcu, sit amet consectetur magna iaculis congue. culis congue."</p>
          <div class="lodging-info-testimony mb-5">
            <p class="raleway-bold-p mb-2">Hôtel Les Aiguillettes</p>
            <div class="standing mb-2">
              <img src="../../images/Etoiles.svg" v-set-alt>
              <img src="../../images/Etoiles.svg" v-set-alt>
              <img src="../../images/Etoiles.svg" v-set-alt>
              <img src="../../images/Etoiles.svg" v-set-alt>
              <img src="../../images/Etoiles.svg" v-set-alt>
            </div>
            <p class="description-hub raleway-reg-p13">Les Maldives, Asie du Sud</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default{
    name: 'cardTestimony',
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/fid'>

</style>
