<template>
  <div id="desire-list">
    <BannerProfile :user="this.user"></BannerProfile>
    <div class="container-profil">
      <Menuprofil :user="this.user"></Menuprofil>
      <div class="row">
        <div class="col-lg-4 col-xs-8 desire-title">
          <h3 class="merriweather-reg-26">Vos</h3>
          <h2 class="merriweather-reg-40 mb-5">Envies</h2>
        </div>
        <div class="col-lg-8 col-xs-7">
          <button class="btn-hortense-primary" @click="createDesireList" v-if="!this.desireLists.length">Créer une liste d'envie</button>
          <div class="desire-section">
            <div class="row row-desire">
              <div class="col-12 col-lg-6">
                <div class="form-desire">
                  <p class="raleway-bold-p16">1. Vous voyagez</p>
                  <div v-for="type in type_of_trips" class="d-flex mb-3" :key="type">
                    <input type="checkbox"  :id="type" :value="type" v-model="dataList.type_of_trips">
                    <label class="raleway-reg-p13 ml-2 mr-2 mb-0 " :for="type">{{formatName(type)}}</label>
                  </div>
                </div>
                <div class="form-desire">
                  <p class="raleway-bold-p16">2. Votre destination favorite</p>
                  <div v-for="country in countries" class="d-flex mb-3" :key="country">
                    <input type="checkbox"  :id="country" :value="country" v-model="dataList.countries">
                    <label class="raleway-reg-p13 ml-2 mr-2 mb-0 " :for="country">{{formatName(country)}}</label>
                  </div>
                </div>
                <div class="form-desire">
                  <p class="raleway-bold-p16">3. Votre ambiance</p>
                  <div v-for="ambiance in ambiances.slice(0,valueAmb)" class="d-flex mb-3" :key="ambiance">
                    <input type="checkbox"  :id="ambiance" :value="ambiance" v-model="dataList.ambiances">
                    <label class="raleway-reg-p13 ml-2 mr-2 mb-0 " :for="ambiance">{{formatName(ambiance)}}</label>
                  </div>
                  <div class="pagination-hub d-flex mt-3" v-if="valueAmb >= ambiances.length">
                    <a @click='SeeLessAmb'  class="link-hortense"> voir moins <img src="../../images/Fleche haut.svg" v-set-alt></a>
                  </div>
                  <div class="pagination-hub d-flex mt-3" v-else>
                    <a @click='SeeMoreAmb'  class="link-hortense"> voir plus <img src="../../images/flechebas-simple.svg" v-set-alt></a>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-6">
                <div class="form-desire">
                  <p class="raleway-bold-p16">4. Vos activités préférées en vacances</p>
                  <div v-for="activity in activities.slice(0,valueActivity)" class="d-flex mb-3" :key="activity">
                    <input type="checkbox"  :id="activity" :value="activity" v-model="dataList.activities">
                    <label class="raleway-reg-p13 ml-2 mr-2 mb-0 " :for="activity">{{formatName(activity)}}</label>
                  </div>
                  <div class="pagination-hub d-flex mt-3" v-if="valueActivity >= activities.length">
                    <a @click='SeeLessActivity'  class="link-hortense"> voir moins <img src="../../images/Fleche haut.svg" v-set-alt></a>
                  </div>
                  <div class="pagination-hub d-flex mt-3" v-else>
                    <a @click='SeeMoreActivity'  class="link-hortense"> voir plus <img src="../../images/flechebas-simple.svg" v-set-alt></a>
                  </div>
                </div>
                <div class="form-desire">
                  <p class="raleway-bold-p16">5. Votre style d’hébergement</p>
                  <div v-for="style in styles.slice(0,valueStyle)" class="d-flex mb-3" :key="style">
                    <input type="checkbox"  :id="style" :value="style" v-model="dataList.styles">
                    <label class="raleway-reg-p13 ml-2 mr-2 mb-0 " :for="style">{{formatName(style)}}</label>
                  </div>
                  <div class="pagination-hub d-flex mt-3" v-if="valueStyle >= styles.length">
                    <a @click='SeeLessStyle'  class="link-hortense"> voir moins <img src="../../images/Fleche haut.svg" v-set-alt></a>
                  </div>
                  <div class="pagination-hub d-flex mt-3" v-else>
                    <a @click='SeeMoreStyle'  class="link-hortense"> voir plus <img src="../../images/flechebas-simple.svg" v-set-alt></a>
                  </div>
                </div>
                <div class="form-desire">
                  <p class="raleway-bold-p16">6. Vos envies</p>
                  <div v-for="desire in desires" class="d-flex mb-3" :key="desire">
                    <input type="checkbox"  :id="desire" :value="desire" v-model="dataList.desires">
                    <label class="raleway-reg-p13 ml-2 mr-2 mb-0 " :for="desire">{{formatName(desire)}}</label>
                  </div>
                </div>
              </div>
            </div>
            <button class="hortense-btn-primary" @click="updateDesire">Valider</button>
          </div>
        </div>
      </div>
      <NewsLetter :user="this.user"></NewsLetter>
    </div>
  </div>
</template>

<script>

import BannerProfile from 'components/shared/BannerProfile.vue';
import Menuprofil from 'components/shared/Menuprofil.vue';
import NewsLetter from 'pages/dashboard/NewsLetter.vue';
import fetcher from 'packs/shared/api.js';
import Flash from 'alert/Flash.vue';

export default {
  name: 'desire-list',
  components: { NewsLetter, BannerProfile, Menuprofil, Flash },
  props: ["user", 'desire-lists'],
  data() {
    return {
      userMount: this.user,
      valueAmb: 6,
      valueStyle: 6,
      valueActivity: 6,
      text: '',
      status: '',
      stylesMount: [],
      styles: [],
      activitiesMount: [],
      activities: [],
      desiresMount: [],
      desires: [],
      servicesMount: [],
      services: [],
      ambiancesMount: [],
      ambiances: [],
      countryMount: [],
      desireListMount: null,
      countries: [],
      typeOfTripsMount: [],
      type_of_trips: [],
      dataList: {
        activities : [],
        ambiances: [],
        desires: [],
        styles: [],
        type_of_trips: [],
        countries:[],
      }
    }
  },
  methods: {
    SeeMoreAmb(e) {
      e.preventDefault();
      this.valueAmb += 3;
    },
    SeeLessAmb(e) {
      e.preventDefault();
      this.valueAmb = 6;
    },
    SeeMoreStyle(e) {
      e.preventDefault();
      this.valueStyle += 3;
    },
    SeeLessStyle(e) {
      e.preventDefault();
      this.valueStyle = 6;
    },
    SeeMoreActivity(e) {
      e.preventDefault();
      this.valueActivity += 3;
    },
    SeeLessActivity(e) {
      e.preventDefault();
      this.valueActivity = 6;
    },
    async createDesireList(){
      try{
        const res = await fetcher.post('/dashboard/'+this.user.slug+'/desire_lists')
      }catch(e){
        console.error(e)
      }
    },
    async fetchtypeOfTrips(){
      try {
        const res = await fetcher.get('type_of_trips.json')
        this.typeOfTripsMount = res.data;
        this.typeOfTripsMount.forEach((trip) => {
          this.type_of_trips.push(trip.name)
        })
      } catch(e) {
        console.error(e);
      }
    },
    async fetchStyles() {
      try {
        const response = await fetcher.get('styles.json');
        this.stylesMount = response.data;
        this.stylesMount.forEach((style) => {
          this.styles.push(style.name)
        })
      } catch(e) {
        console.error(e);
      }
    },
    async fetchActivities() {
      try {
        const response = await fetcher.get('activities.json');
        this.activitiesMount = response.data;
        this.activitiesMount.forEach((activity) => {
          this.activities.push(activity.name)
        })
      } catch(e) {
        console.error(e);
      }
    },
    async fetchDesires() {
      try {
        const response = await fetcher.get('desires.json');
        this.desiresMount = response.data;
        this.desiresMount.forEach((desire) => {
          this.desires.push(desire.name)
        })
      } catch(e) {
        console.error(e);
      }
    },
    async fetchAmbiances() {
      try {
        const response = await fetcher.get('ambiances.json');
        this.ambiancesMount = response.data;
        this.ambiancesMount.forEach((ambiance) => {
          this.ambiances.push(ambiance.name)
        })
      } catch(e) {
        console.error(e);
      }
    },
    async fetchCountries() {
      try {
        const response = await fetcher.get('/reservation-hotel');
        this.countryMount = response.data
        this.countryMount.forEach((el) => {
          if (el.name) {
            this.countries.push(el.name)
          }
        })
      } catch(e) {
        console.error(e);
      }
    },
    async updateDesire() {
      try {
        const response = await fetcher.patch('/dashboard/'+this.user.slug+'/desire_lists/'+ 1, {desire_list: this.dataList});
        this.desireListMount = response.data
        this.toggleStatus('success');
        this.text =  'Updated Successfully'
      } catch(e) {
        this.toggleStatus('alert')
        this.text =  'Unprocessable Entity'
      }
    },
    async fetchDesireList() {
      try {
        const response = await fetcher.get('/dashboard/' + this.user.slug + '/mes_envies');
        this.desireListMount = response.data;
        console.log(response.data);
        if (this.desireListMount) {
          if (this.desireListMount.type_of_trips) {
            this.dataList.type_of_trips = this.desireListMount.type_of_trips.map(el => el.name);
          }
          if (this.desireListMount.ambiances) {
            this.dataList.ambiances = this.desireListMount.ambiances.map(el => el.name);
          }
          if (this.desireListMount.styles) {
            this.dataList.styles = this.desireListMount.styles.map(el => el.name);
          }
          if (this.desireListMount.activities) {
            this.dataList.activities = this.desireListMount.activities.map(el => el.name);
          }
          if (this.desireListMount.desires) {
            this.dataList.desires = this.desireListMount.desires.map(el => el.name);
          }
          if (this.desireListMount.countries) {
            this.dataList.countries = this.desireListMount.countries.map(el => el.name);
          }
        }
      } catch (e) {
        console.error(e);
      }
    },
    formatName(name){
      const camelize = name.split(' ').map(x => x[0].toUpperCase() + x.slice(1)).join(' ')
      return camelize
    },
    toggleStatus(value="success") {
      this.status = this.status === value ? null : value
    },
  },
  mounted(){
    this.fetchStyles();
    this.fetchActivities();
    this.fetchDesires();
    this.fetchAmbiances();
    this.fetchtypeOfTrips();
    this.fetchCountries();
    this.fetchDesireList();
  }
}
</script>

<style lang="scss" scoped>
#desire-list{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .desire-section{
    .form-desire{
      margin-bottom: 3rem;
    }
    button{
      margin: 0 auto;
      margin-top: 6rem;
    }
  }
  .switch-news{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>