<template>
  <div id="wishlist-card">
    <div class="card-product">
      <img class="img-lodging" v-if="this.lodgingMount.photo_banner" :src="this.lodgingMount.photo_banner" />
      <img v-else src="https://images.unsplash.com/flagged/photo-1556438758-8d49568ce18e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80" class="img-lodging" :alt="this.lodgingMount.name">
      <div class="row card-product-infos">
        <div class="col-lg-6 col-xs-7 basic-infos">
          <p class="raleway-bold-p mb-0">{{this.lodgingMount.type}} {{this.lodgingMount.name}}</p>
            <div class="standing">
              <img src="../../images/Etoiles.svg" v-set-alt v-for='i in parseInt(this.lodgingMount.standing)' :key='i'>
              <!-- <img src="../../images/Etoiles.svg" v-set-alt v-for='i in 5 - parseInt(this.lodging.standing)'> -->
            </div>
            <p class="description-hub raleway-reg-p13 mb-0">{{ this.lodgingMount.address}}</p>
            <div class="location-hub d-flex">
              <img src="../../images/Localisation.svg" v-set-alt>
              <p class="raleway-reg-p13 pl-1 pr-2 mb-0"></p><a href="#" class="link-hortense">Voir sur la carte</a>
            </div>
              <p class="raleway-reg-p13 mb-0">A partir de <strong>_/nuit</strong></p>
            <div class="catch-hub">
            <p v-if="this.lodgingMount.lodging_description.catch_sentence" class="merriweather-italic-13 mb-0">{{ this.lodgingMount.lodging_description.catch_sentence }}</p>
            <p v-else class="merriweather-italic-13 mb-0">Un havre aussi éco-responsable que luxueux</p>
          </div>
          <div class="hub-reviews d-flex">
            <img class="mt-2 mr-2" src="../../images/avis.svg" v-set-alt>
            <div class="review-info d-flex">
              <div class="score-hub d-flex">
                <p class="raleway-reg-p13 mb-0 pl-1 pr-2"><b>{{this.lodging.lodging_description.rating_value || average}}</b>/5</p>
                <a :href="'/reservation-hotel/'+ this.lodgingMount.slug +'/avis'" target="_blank" class="link-hortense"> {{ this.lodging.lodging_description && this.lodging.lodging_description.review_count || ratedLodgings.length }} avis</a>
              </div>
              <p class="raleway-reg-p13">Note des voyageurs</p>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-xs-12 col-share">
          <div class="row row-share">
            <Modalshare v-if="showModal" @close="showModal = false" :for="this.lodging"></Modalshare>
            <div class="item col-lg-3 col-xs-5" @click="showModal = true">
              <img src="../../images/Share.svg" v-set-alt>
              <p class="raleway-reg-p13">Partager</p>
            </div>
           <!-- <div class="item col-5">
              <img src="../../images/icône liste.svg" v-set-alt>
              <p class="raleway-reg-p13">Créer une liste</p>
            </div> -->
            <div class="item col-lg-3 col-xs-5">
              <img src="../../images/whishlist on.svg" v-set-alt @click='$emit("toggle")'>
              <p class="raleway-reg-p13">Favori</p>
            </div>
          </div>
          <div class="col-lg-12 col-xs-6 dispo-btn p-0">
            <a :href="'/reservation-hotel/'+ this.lodgingMount.slug +'/reservation'" class="hortense-btn-primary">Voir les disponibilités</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';
  import Modalshare from 'components/modals/Modalshare.vue';

  export default {
    name: 'wishlist-card',
    components:{ Modalshare },
    props: ["lodging"],
    data() {
      return {
        lodgingMount: this.lodging,
        ratedLodgings: [],
        averageList:[],
        average: 0,
        showModal: false,
      }
    },
    mounted() {
      this.fetchLodging();
    },
    methods: {
      async fetchLodging() {
        try {
          // const response = await fetcher.get('/reservation-hotel/'+ this.lodging.slug)
          this.lodgingMount = this.lodging;
          this.ratedLodging();
          this.averageRate();
          this.averageLodging();
        } catch(e) {
          console.error(e)
        }
      },
      ratedLodging(){
        this.ratedLodgings = this.lodgingMount.reservations.filter(el => el.rated === true)
          return this.ratedLodgings
      },
      averageRate(){
        this.ratedLodgings.forEach((el) => {
          this.averageList.push(el.rate_average)
        })
        return this.averageList
      },
      averageLodging() {
      if (this.averageList.length > 0) {
        this.average = (this.averageList.reduce((a, b) => a + b, 0) / this.averageList.length).toFixed(1);
      } else {
        this.average = '-';
      }
    },
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/wishlist-user'>

</style>
