<template>
<div class="container mt-lg-5 mt-md-5 pt-lg-5 pt-md-5 pt-3 mt-3" v-if="this.yoga_stay">
<h6 v-if="!isPhone && !is_urban" class="justify-content-start" style="font: normal normal normal 26px/33px Merriweather;"> Informations sur le séjour </h6>
<h6 v-if="!isPhone && is_urban" class="justify-content-start" style="font: normal normal normal 26px/33px Merriweather;"> L'expérience Urban Retreat  </h6>
  <div class="row p-5 justify-content-center">
  <div class="col-md-4 pb-2 "v-for="(item, index) in this.yoga_stay.stay_pics_all" :key="index">
    <div class="card border-0" >
      <img class="card-img-top" :src="item" v-set-alt>
      <div class="py-3">
        <p class="c-text">{{yoga_stay.stay_titles_all[index]}}</p>
      </div>
    </div>
  </div>
</div>
</div>
</template>

<script>
import myImage from '../../images/BelAmi-Chambre.png';
export default {
  data(){
    return{
      myImage:myImage,
    }
  },
  name: 'YogaStay',
  props: ['yoga_stay','is_urban'],
  methods: {
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    selectDestination(destination) {
      this.isLoading = true;
      this.selectedDestination = destination;
      this.fetchDestinations();
    },
    isSelectDestination(name) {
      return this.selectedDestination === name && this.destinations;
    },
    async fetchDestinations() {
      try {
        const response = await fetcher.get(
            `/destination?country=${this.selectedDestination}`
        );
        this.destinations = response.data;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
computed: {
  isPhone() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      return true;
    }else{
      return false;
    }
  }
}
}
</script>

<style scoped>

.c-text{
  text-align: left !important;
  font: normal normal normal 14px/16px Raleway;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;

}

</style>