<template>
  <div id="giftcard">
    <div class="container-index row mt-5">
      <section  class="raleway-reg-p13">
        <h2>Résumé de votre commande :</h2>
        Votre carte cadeau a été créée, vous trouverez ci-dessous les détails :
        <h5> <strong> Carte cadeau : </strong></h5>
        <ul>
          <li><strong>N° de la carte :</strong> {{giftcardprops.number}}</li>
          <li><strong>Prix :</strong> {{giftcardprops.amount}} €</li>
          <li><strong>A envoyer à :</strong> {{receiveruserprops.last_name}} {{receiveruserprops.first_name}} ( {{receiveruserprops.email}} )</li>
          <li><strong>Date de validité :</strong> {{giftcardprops.validity_date}}</li>
        </ul>
      </section>
      <section>
            <div class="section-payment" v-if="1">
              <div class="link-container">
                <a class="link-item link-pages">{{ $t("payment") }}</a>
                <ul class="list-link">
                  <li class="link-item picto">
                    <img style="height: 4rem;" src="../../images/payment-1.svg" v-set-alt>
                  </li>
                  <li class="link-item picto">
                    <img style="height: 4rem;"  src="../../images/payment-2.svg" v-set-alt>
                  </li>
                  <li class="link-item picto">
                    <img style="height: 4rem;" src="../../images/payment-3.svg" v-set-alt>
                  </li>
                  <li class="link-item amex">
                    <img style="height: 4rem;" src="../../images/payment-4.svg" v-set-alt>
                  </li>
                  <li class="link-item paypal">
                    <img style="height: 4rem;" src="../../images/payment-5.svg" v-set-alt>
                  </li>
                </ul>
              </div>
              <div class="card-stripe mb-3">
                <div class="credit-card">
                  <p class="raleway-bold-p mb-1">Carte de crédit</p>
                </div>
                <div class="field">
                  <input class="border-input" type="text" placeholder="Nom/Prénom" v-model="cardDetails.holder" />
                </div>
                <div class="field">
                  <div id="card-number" class="input empty"></div>
                </div>
                <div class="field">
                  <div id="card-expiry" class="input empty"></div>
                </div>
                <div class="field">
                  <div id="card-cvc" class="input empty"></div>
                </div>
                <div id="card-errors" role="alert" ref="errorsCard"></div>
                <div class="btn">
                  <button
                    class="hortense-btn-primary mb-4 btn btn-md"
                    :disabled="isProcessingPayment"
                    @click.prevent="handlePayment"
                  >
                    {{ paymentButtonCaption }}
                  </button>
                </div>
              </div>
        </div>        
      </section>

      <Flash :text='this.text' :status='this.statusFlash' @destroy='toggleStatus(statusFlash)'></Flash>

    </div>
  </div>

</template>

<script>
  import fetcher from "packs/shared/api.js";
  import Flash from 'alert/Flash.vue';
  import AOS from 'aos';

export default {
  name: 'giftcardpaymentprocess',
  components: {
    Flash,
    AOS
  },
  props: {
    user: null,
    giftcardprops: null,
    senderuserprops: null,
    receiveruserprops: null
  },

  data() {
    return {
      /*senderInfo: {
        last_name: null,
        first_name: null,
        email: null,
      },
      recieverInfo: {
        last_name: null,
        first_name: null,
        email: null,
      },*/
      text:null,
      statusFlash: null,
      //messageText: null,
      //giftCardPrice: 50,
      //validityDate: null,
      //giftCardActivationCode: null,
     // giftCardCreated: null,

      status: {
        getRateDetails: null,
        createToken: null,
        createCustomer: null,
        addCard: null,
        paymentIntent: null,
        confirmCardPayment: null,
      },
      isProcessingPayment: false,
      cardDetails: {
        lastFourDigits: null,
        type: null,
        expiryMonth: null,
        expiryYear: null,
        holder: null,
      },
      paymentIntentId: null,
      clientSecret: null,
      displayError: this.$refs.errorsCard,
      card: null,
      stripeKey: document.querySelector("meta[name='stripe-key']").getAttribute("content"),
      stripe: null,
      userInfo: {
        last_name: null,
        first_name: null,
        email: null,
        phone: null,
        city: null,
        country: null,
      },
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cb: null,
      date: null,
      cvc: null,
      cardToken: null,
    };
  },

  watch: {
    cardToken() {
      this.cardDetails.lastFourDigits = this.cardToken.card.last4;
      this.cardDetails.type = this.cardToken.card.brand;
      this.cardDetails.expiryMonth = this.cardToken.card.exp_month;
      this.cardDetails.expiryYear = this.cardToken.card.exp_year;
    },
  },

  methods: {

    toggleStatus(value="success") {
        this.statusFlash = this.statusFlash === value ? null : value
      },

initStripe() {
      this.stripe = Stripe(this.stripeKey);
      const elements = this.stripe.elements();
      const elementStyles = {
        base: {
          color: "#32325D",
          fontWeight: 500,
          fontSize: "16px",
          fontSmoothing: "antialiased",
          "::placeholder": {
            color: "#848484",
          },
          ":-webkit-autofill": {
            color: "#e39f48",
          },
        },
        invalid: {
          color: "#E25950",
          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      };
      const elementClasses = {
        focus: "focused",
        empty: "empty",
        invalid: "invalid",
      };
      this.cardNumber = elements.create("cardNumber", {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardNumber.mount("#card-number");
      this.cardExpiry = elements.create("cardExpiry", {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardExpiry.mount("#card-expiry");
      this.cardCvc = elements.create("cardCvc", {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardCvc.mount("#card-cvc");
      this.displayError = this.$refs.errorsCard;
    },
    async addNewSripeCard() {
      try {
        const response = await fetcher.post("gift_card/add_card", { 
          card_token: this.cardToken.id, 
          user: this.senderuserprops });

        if (response.data.error) {
          this.displayError.textContent = response.data.error;
          this.status.addCard = false;
        } else {
          this.status.addCard = true;
        }
      } catch (e) {
        this.status.addCard = false;
        console.log("Failed addNewSripeCard");
        console.error(e);
      }
    },
    async initCreateCardToken() {
      try {
        const response = await this.stripe.createToken(this.cardNumber);
        if (response.error) {
          this.status.createToken = false;
          console.log("Failed createToken");
          console.log(response.error);
        } else {
          this.status.createToken = true;
          this.cardToken = response.token;
        }
      } catch (e) {
        this.status.createToken = false;
        console.log("Failed initCreateCardToken");
        console.log(e);
      }
    },
    async initCreateStripeCustomer() {
      try {
        const createCustomerResponse = await fetcher.post("gift_card/create_customer", {
          card_token: this.cardToken,
          user: this.senderuserprops,
        });
        this.status.createCustomer = true;
        this.senderuserprops.stripe_customer_id = createCustomerResponse.data.stripe_customer_id;
      } catch (e) {
        this.status.createCustomer = false;
        console.log("Failed initCreateStripeCustomer");
        console.log(e);
      }
    },
    async initPaymentIntent() {
      console.log("begin initPaymentIntent")
      try {
        const response = await fetcher.post("/gift_card/payment", {
          stripe_customer_id: this.senderuserprops.stripe_customer_id,          
          sender_user: this.senderuserprops,
          receiver_user: this.receiveruserprops,
          gift_card: this.giftcardprops
          /*giftCard: {
            validityDate: this.validityDate,
            price: this.giftcardprops.amount,
            activationCode: this.giftcardprops.activation_code,
            senderFirstName: this.senderuserprops.first_name,
            senderLastName: this.senderuserprops.last_name,
            senderEmail: this.senderuserprops.email,
            recieverFirstName: this.receiveruserprops.first_name,
            recieverLastName: this.receiveruserprops.last_name,
            recieverEmail: this.receiveruserprops.email
          },*/

        });
        this.status.paymentIntent = true;
        this.paymentIntentId = response.data.payment_intent_id;
        this.clientSecret = response.data.client_secret;
      } catch (e) {
        this.status.paymentIntent = false;
        console.log("Failed initPaymentIntent");
        console.log(e);
      }
    },
    async initConfirmCardPayment() {
      try {
        const response = await this.stripe.confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.cardNumber,
          },
        });
        if (response.error) {
          console.log("Failed confirmCardPayment");
          console.log(response.error);
          this.status.confirmCardPayment = false;
          this.displayError.textContent = response.error.message;
        } else {
          this.status.confirmCardPayment = true;
          try{
            const resp = await fetcher.post('/gift_card/payment/send_email', {
                        sender_user: this.senderuserprops,
                        receiver_user: this.receiveruserprops,
                        gift_card: this.giftcardprops
            })
            if (!resp.error){
              console.log('Emails envoyés')
              Turbolinks.visit("/carte-cadeau");
            }
          } catch(e)
          {
            console.log('payement effectué, mail non envoyé')
            console.error(e)
          }
        }
      } catch (e) {
        this.status.confirmCardPayment = false;
        console.log("Failed initConfirmCardPayment");
        console.log(e);
      }
    },
    async handlePayment() {
      this.displayError.textContent = "";
      this.isProcessingPayment = true;
      await this.initCreateCardToken();
      console.log('initCreateCardToken')
      if (!this.hasStatusError()) {
        if (!this.senderuserprops.stripe_customer_id) {
          await this.initCreateStripeCustomer();
          console.log('initCreateStripeCustomer')
        } else {
          await this.addNewSripeCard();
          console.log('addNewSripeCard')
        }
      }
      if (!this.hasStatusError() && this.giftcardprops.amount > 0) {
        await this.initPaymentIntent();
        console.log('initPaymentIntent')
        await this.initConfirmCardPayment();
        console.log('initConfirmCardPayment')
      }
      /*
      if (!this.hasStatusError()) {
        await this.createGiftCard();
      }*/
      this.isProcessingPayment = false;
    },
    hasStatusError() {
      const error = Object.values(this.status).find((el) => el === false);
      return error === false ? true : false;
    },
  },
  computed: {
    paymentButtonCaption() {
      if (this.hasStatusError()) {
        return "Erreur de validation, veuillez réessayer";
      } else if (this.isProcessingPayment) {
        return "Paiement en cours...";
      } else {
        return `Valider l'achat de la carte cadeau et payer ${this.giftcardprops.amount} €`;
      }
    },
  },

  mounted(){
    AOS.init();
    this.initStripe();
    /*this.fetchUser();
    console.log('this.giftcardprops = ')
    console.log(this.giftcardprops)
    console.log('this.senderuserprops = ')
    console.log(this.senderuserprops)
    console.log('this.receiveruserprops = ')
    console.log(this.receiveruserprops)*/
  },
  
  
};
</script>
<style lang="scss" scoped>

#giftcard{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 8%;

  .card-stripe{
    .field{
      border: none;
      border-bottom: 1px solid #ccc;
      padding: 0.5rem 1rem;
    }  
  }  

  .raleway-bold-p{
    text-transform: none;
  }

  .merriweather{
    font-family: Merriweather;    
  }
  .italic{
    font-style: italic;
  }
  .container-index {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 25vh;
    min-height: 100vh;
    overflow: hidden;

    .why-gift-card{
      display: block;

    }

    #description-1{
      display: block;
      position: inherit;
      width: 90%;
      margin-top: -15%;
      margin-left: 5%;
      padding-top: 7%;
      padding: 5%;
      background-color: #fff;
    }

    #picture-enterprise-ce{
      display: block;
      margin-top: 5%;
    }

    #description-2{
      display: block;
      position: inherit;
      width: 90%;
      margin-top: -20%;
      margin-left: 10%;
      padding: 5%;
      background-color: #fff;
    }

    #section-experience{
      margin-top: 5%;
      padding-top: 3%;
      border-top: solid 2px #aba;

      .description-experience{
        margin-top: 5%;
      }
    }

    .form-user-info {
      border: 2px solid grey;
      padding: 10px;
    }

  }
}


  .gift-card-overview{
    /*border: 35px #CFE5DC solid;*/
    border: 15px #3B6152 solid;
    padding-top: 25px;
    padding-bottom: 10px;

    .gift-card-overview-title{
      font-family: Abuget;
      font-size: 80px;
    }

    .gift-card-overview-body{
      display: block;
      margin-top: 50px;
      margin-bottom: 50px ;
      font-weight: bolder;
      color: #3B6152;

      .gift-card-overview-price{
        font-weight: bolder;
        border-bottom: solid 2px #3B6152;
        color: #000;
        inline-size: 200px;
      }

      .vertical-text {
        transform: rotate(-90deg);
        display: none;
        /*transform-origin: left top 0;
        padding-right: -15%;*/
      }
    }

    .gift-card-overview-footer{
      line-height: 0.5px;
    }
  }

  .text-info-experience{
    background-color: #CFE5DC;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding: 20px;
  }

  .add-btn{
    margin-top: 3rem;
    button {
      display: flex;
    }
    a, button{
      text-decoration: none;
      padding: 1rem 1rem;
      border : 1px solid $light-kaki;
      width: 20%;
      text-align: center;
      font-family: $Raleway;
      font-size: 14px;
      font-weight: 500;
      background: $light-kaki;
      color: white;
    }

    i{
      padding-left: 5px;
    }
  }



  .info-payment {
    width: 100%;
    background: #f8f9ff;
    padding: 1rem;
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      margin-right: 1rem;
    }
  }
  .link-container {
    display: flex;
    justify-content: center;
    padding-left: 0;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      margin: 2rem 0;
    }

    .link-pages {
      margin-top: 5rem;
      margin-bottom: 3rem;

      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        white-space: nowrap;
        margin: 0.5rem 0.5rem 0 0;
      }
    }

    .list-link {
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        padding-left: 0;
      }
    }

    a,
    p {
      text-decoration: none;
      font-family: $Raleway;
      font-size: 13px;
      font-weight: 400;
      color: #000000;
      margin-right: 3rem;
    }

    .picto {
      margin-top: 5rem;
      margin-bottom: 3rem;
      margin-right: 1rem;

      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        margin: 0.5rem;
      }
    }
    .paypal {
      img {
        margin-top: 5.2rem;
        margin-bottom: 3rem;
        margin-right: 1rem;

        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
          margin: 0.5rem;
        }
      }
    }
    .amex {
      img {
        margin-top: 4.7rem;
        margin-bottom: 3rem;
        margin-right: 1rem;

        @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
          margin: 0.5rem;
        }
      }
    }
  }

</style>