<template>
  <div id="cardDesti">
    <div class="card-desti">
      <img v-if="destination.photo_key" :src="destination.photo_key" :alt="destination.name">
      <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" :alt="destination.name">
      <p class="subtitle-card raleway-bold-p mt-4">{{ destination.name }}</p>
      <p class="description-card raleway-reg-p13 text-center">{{ destination.content }}</p>
      <a :href="'/destination/'+ destination.slug " class="link-hortense">{{ this.formatlodgings() }}</a>
    </div>
      <slither-slider :options="{numberOfSlides: 3,
                                  dots: true,
                                  controls: true}">
      <div v-for="(region, index) in destination.regions" :key='index'>
        <div class="col-grid-desti" v-if="region.photo">
          <img :src="region.photo" :alt="region.name">
          <a :href="'/destination/'+ region.slug" class="link-hortense">
            <p class="region-name raleway-reg-p15">{{ region.name }}</p>
          </a>
        </div>
        <div class="col-grid-desti" v-else>
          <a :href="'/destination/'+ region.slug" class="link-hortense">
            <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" :alt="region.name">
          </a>
            <p class="region-name raleway-reg-p15">{{ region.name }}</p>
        </div>
        <template slot="previous">
          <img src="../../images/fleche gauche.svg" v-set-alt>
        </template>
        <template slot="next">
          <img src="../../images/fleche droite.svg" v-set-alt>
        </template>
      </div>
    </slither-slider>
  </div>
</template>

<script>
  export default {
    name: 'CardDesti',
    props: ['destination'],
    options: {
      autoplay: false,
      transition: "slide",
      dots: true,
      animatedDots: false,
      dotLimit: false,
      fullscreen: false,
      fullscreenOffset: null,
      controls: true,
      numberOfSlides: 3,
      controlsWrapperClass: null,
      animationDuration: 500,
      animationEasing: "easeOutQuint",
      slidePosition: "center",
      slideClass: null,
      sliderClass: null,
      secondsOnSlide: 4,
      clickableSlides: false,
      endless: false,
      cuts: "right",
      gap: 30,
      adaptiveHeight: false,
      loop: true,
      extras: 3,
      overflowHiddenPadding: { top: 0, left: 0, right: 0, bottom: 0 },
      touch: true,
      preserveGrid: false,
      swipeTolerance: 80
    },
    methods: {
      formatlodgings() {
        return (this.lodgingsCount() > 0 ? this.lodgingsCount() : "") + (this.lodgingsCount() > 1 ? ' établissements' : this.lodgingsCount() == 1 ? ' établissement' : 'DÉCOUVRIR');
      },
      lodgingsCount(){
        return this.destination.get_lodgings.length
      }
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/destination'>

</style>
