<template>
	<div id="card-explodging">
		<div>
			<img v-if="this.experience.photo_key" :src="this.experience.photo_key" v-set-alt class="exp-img" />
			<img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" v-set-alt class="exp-img" />
		</div>
		<div class="d-flex align-items-end">
			<div class="info-exp">
				<div class="header d-flex align-items-center justify-content-between">
					<h3 class="category-exp merriweather-italic">{{ this.experience.name }}</h3>
					<div>
						<img src="../../images/wishlistOn.svg" v-set-alt v-if="isIncludeInWishlist()" @click="toggleLike()" />
						<img src="../../images/wishlist.svg" v-set-alt v-else @click="toggleLike()" />
					</div>
				</div>
				<h3 class="raleway-bold-p16 mb-0">{{ this.experience.catch_phrase }}</h3>
				<p v-if="!isPhone" class="raleway-reg-p15 mt-4">{{ this.experience.description }}</p>

				<p v-if="isPhone" class="raleway-reg-p15 mt-4">
					
					<span v-if="!showMore">
                {{ truncatedText(150) }}
				</span>
				<span v-else>
					{{ this.experience.description }}
				</span>
					</p>
					<button v-if="isPhone" @click="toggleText" class="show-more-btn">
              {{ showMore ? 'Afficher moins' : 'Afficher plus' }}
            </button>
				<p class="raleway-bold">Où ?</p>
				<p class="raleway-reg-p15">{{ this.experience.address }}</p>
			</div>
		</div>
	</div>
</template>
<script>
	import fetcher from 'packs/shared/api.js';

	export default {
		name: 'CardExplodging',
		props: ['experience', 'user'],
		data() {
			return {
				wishlistExp: null,
				showMore: false,
			};
		},
		methods: {
			truncatedText(textLength) {
			const text = this.experience.description;
			const maxLength = textLength;
			if (text.length > maxLength) {
				return text.substring(0, maxLength) + "...";
			}
			return text;
			},
			toggleText() {
			this.showMore = !this.showMore;
			},
			isIncludeInWishlist() {
				if (this.wishlistExp) {
					for (const experience of this.wishlistExp) {
						if (experience.id === this.experience.id) {
							return true;
						}
					}
				}
				return false;
			},
			async toggleLike() {
				try {
					if (Object.entries(this.user).length != 0) {
						const response = await fetcher.patch('/wishlists/' + this.user.id, { experience_id: this.experience.id });
						this.wishlistExp = response.data.experiences;
					} else {
						this.$root.$emit('openModal');
						window.scrollTo({
							top: 0,
							behaviour: 'smooth',
						});
					}
				} catch (e) {
					console.error(e);
				}
			},
			async fetchWishlist() {
				try {
					if (this.user) {
						const response = await fetcher.get('/wishlists.json');
						console.log(response);
						this.wishlistExp = response.data.experiences;
					}
				} catch (e) {
					console.error(e);
				}
			},
		},
		mounted() {
			this.fetchWishlist();
		},
		computed: {
			isPhone() {
				if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
				return true;
				}else{
				return false;
				}
			},
		}
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-show"></style>
