<template>
  <div id="gift-card-home">
    <div class="card-container">
      <img class="img-fluid" id="o-gift-card" src="../images/icotype.svg" v-set-alt data-aos="zoom-out" data-aos-duration="1000">
      <div class="row row-hubs">
        <div class="col-4">
          <div class="gift_cards-header">
            <h2 class="merriweather-reg-26"> Notre </h2>
            <h2 class="merriweather-reg-40 mb-4">Carte cadeau</h2>
            <p class="raleway-reg-p16">Hortense propose la sélection la plus exclusive d’hébergements éco-responsables. Séjourner dans un hôtel sélectionné par Hortense c’est la garantie de séjourner dans un hébergement d’exception engagé dans une démarche de développement durable. <br/>Surprenez vos proches en leur offrant une nuit ou plus dans un lieu d’exception engagé dans une démarche environnementale.</p>
          </div>
            <a class="hortense-btn-primary raleway-bold-p mt-5" :href="'/carte-cadeau'">{{ $t("gift_cards.viewbtn") }}</a>
        </div>
        <div class="col-8 pt-2">
          <img class="img-fluid" data-aos="fade-right" data-aos-duration="1000" id="gift-home" src="../images/giftcard/home-presentation.jpg" v-set-alt>       
          <div id="gift-home-container">
            <div class="text-center gift-card-overview">
              <h2 class="gift-card-overview-title">Plaisir d'offrir</h2>
              <div class="card-number">Carte n°000XX</div>
              <div class="gift-card-overview-body line-form form-group">
                <div class="row">
                  <div class="col-sm-12 px-0">
                    <span>Montant :</span>
                    <input class="w-50 border-input text-right" type="text" value="XX"/>
                    <span>€</span>        
                  </div>
                </div>
                <p></p>
              </div>
              <div class="gift-card-overview-footer">
                <p class="p-container">Hortense green <br/> Code d'activation : <span class="gift-card-overview-activation-code">xxxxxx</span> valable jusqu'au <span>XX/XX/XXXX</span></p>
              </div>
            </div>
          </div>               
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AOS from 'aos';

  export default {
    name: 'gift-card-home',
    props: {
      
    },
    data() {
      return {
      }
    },
    methods: {
      
    },
    mounted() {
      AOS.init();
    }
  }
</script>


<style lang="scss">
  #gift-card-home{
    margin-bottom: 11rem;

    #o-gift-card{
      z-index: 100;
      position: absolute;
      width: 320px;
      right: -13%;
      margin-top: 16%;
    }

    .border-input{
      border: none;
      border-bottom: 1px solid #ccc;
    }

    #gift-home-container{
      width: 60%;
      display: block;
      margin: 11% 22%;
      z-index: 2111;
      position: absolute;
      background: white;
    }

    #gift-home{
      position: absolute;
      width: 100%;
      z-index: 1;
      height: 100%;
    }

    .gift-card-overview{
      border: 9px #3B6152 solid;
      padding-top: 10px;
      padding-bottom: 10px;
      font-family: raleway;

      .gift-card-overview-title{
        font-family: Abuget;
        font-size: 60px;
      }

      .card-number{
        float: right;
        transform: rotate(-90deg);
        padding: 0 !important;
        margin: 0 !important;
        font-size: 10px;
        right: -13px;
        position: absolute;
      }

      .p-container{
        margin: 0 !important;
        padding: 0 !important;
        line-height: 1.3;
        font-family: $Raleway;
        font-size: 12px;
      }

      .gift-card-overview-body{
        display: block;
        margin-top: 10px;
        margin-bottom: 45px ;
        font-weight: bolder;
        font-family: raleway;
        color: #3B6152;

        .gift-card-overview-price{
          font-weight: bolder;
          border-bottom: solid 2px #3B6152;
          color: #000;
          inline-size: 200px;
        }
      }

      .gift-card-overview-footer{
        line-height: 0.5px;
      }
    }
  }

</style>
