<template>
  <div id="selectionHost">
      <div id="cardHost">
        <img class="hub-img" :src="lodging.photo_banner" :alt="lodging.name" v-if="lodging.photo_banner">
        <img class="hub-img" src="https://images.unsplash.com/flagged/photo-1556438758-8d49568ce18e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80" :alt="lodging.name" v-else>
        <div class="sticker-hub d-flex mt-4 pl-2">
          <img :src="greenNotePicto((lodging.score).toFixed())" :alt="(lodging.score).toFixed()" v-if="lodging.score">
          <div class="sticker-info d-flex" v-if="lodging.score">
            <p class="raleway-reg-p13 mb-0 pl-1 pr-2"> <b>{{( lodging.score).toFixed() }}</b>/10</p>
            <p class="raleway-reg-p13 mb-0 pl-1 pr-2">Green note</p>
          </div>
        </div>
        <div class="card-hub-info">
          <div class="hub-header d-flex">
            <a :href="'/reservation-hotel/'+ lodging.id" class="link-title"><h1 class="raleway-bold-h3">{{ lodging.name }}</h1></a>
              <!-- <img src="../../images/Label.svg" class="img-label" v-set-alt> -->
            <div>
              <img src="../../images/wishlistOn.svg" v-set-alt v-if='isIncludeInWishlist()' @click='toggleLike()'>
              <img src="../../images/wishlist.svg" v-set-alt v-else @click='toggleLike()'>
            </div>
          </div>
          <Mapfind v-if='showMap' :markers='this.markers' @close='showMap = false'></Mapfind>
          <div class="standing">
            <img src="../../images/Etoiles.svg" v-set-alt v-for='i in parseInt(lodging.standing)' :key='i'>
          </div>
          <p class="description-hub raleway-reg-p13">{{lodging.country.name}}, {{ lodging.region.name}}</p>
          <div class="location-hub d-flex">
            <img src="../../images/Localisation.svg" v-set-alt>
            <!-- <p class="raleway-reg-p13 pl-1 pr-2">3,4km du centre</p> -->
            <a class="link-hortense ml-2" @click="displayMap">Voir sur la carte</a>
          </div>
          <div class="catch-hub" v-if='lodging.lodging_description.catch_sentence'>
            <p class="merriweather-italic">{{ lodging.lodging_description.catch_sentence }}</p>
          </div>
          <div class="catch-hub" v-else>
            <p class="merriweather-italic">Un havre aussi éco-responsable que luxueux</p>
          </div>
          <a :href="'/reservation-hotel/'+ lodging.slug" class="xs-btn-primary text-center">Voir les disponibilités</a>
        </div>
      </div>
    </div>
</template>


<script>
import fetcher from 'packs/shared/api.js';
import Mapfind from 'components/Mapfind.vue';

export default{
    name: 'SelectionHost',
    props: [ 'user','lodging'],
    components: { Mapfind },
    data() {
      return {
        lodgingsByRegionMounted: [],
        wishlistLodgings: null,
        showMap: false,
        markers: [],
      }
    },
    computed: {
      defineWishlistLodgings() {
        return this.wishlistLodgings = this.wishlist.lodgings
      }
    },
    methods: {
       greenNotePicto(score){
         if (score === `${score}`){
          return require(`../../images/greennote ${score}.svg`)
        } else {
          return require("../../images/blanc.png")
        }
      },
      isIncludeInWishlist() {
        if (this.wishlistLodgings) {
          for (const lodging of this.wishlistLodgings) {
            if (lodging.id === this.lodging.id) {
              return true
            }
          }
        }
        return false
      },
      async toggleLike() {
        try {
          if (Object.entries(this.user).length !== 0) {
            const response = await fetcher.patch('/wishlists/'+ this.user.id, { lodging_id: this.lodging.id });
            this.wishlistLodgings = response.data.lodgings;
          } else {
            this.$root.$emit('openModal');
            window.scrollTo({
              top: 0,
              behaviour: 'smooth'
            });
          }
        } catch(e) {
          console.error(e)
        }
      },
       markerMap() {
         return [this.lodging.longitude, this.lodging.latitude];
      },
      displayMap() {
        this.showMap = !this.showMap;
        this.markers = this.markerMap();
       },
      formatSource() {
        if (this.lodgingsByRegionMounted[0].photo_banner) {
          return this.lodgingsByRegionMounted[0].photo_banner
        } else {
          return 'https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0=';
        }
      },
      async fetchWishlist() {
        try {
          if (this.user) {
            const response = await fetcher.get('/wishlists.json')
            this.wishlistLodgings = response.data.lodgings
          }
        } catch(e) {
          console.error(e)
        }
      },
    },
    mounted() {
      this.fetchWishlist();
    }
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/select-host'>

</style>
