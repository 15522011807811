<template>
	<div id="lodgingCard">
		<div class="card-container">
			<div class="row row-hubs">
				<div class="col-4">
					<div class="lodging-header">
						<h2 class="merriweather-reg-26">Nos</h2>
						<h2 class="merriweather-reg-40 mb-5">{{ $t('lodgings') }}</h2>
						<p class="raleway-reg-p16">{{ $t('LodgingCard.title') }}</p>
					</div>
				    <div class="lodging-filter">
				   <!--<ul class="filter-list">
							<li>
								<a class="lodging-category active" href="#" @click.prevent="selectOption"
									><p class="raleway-reg-p16" data-value="Hotel">Hôtels</p>
									<img src="../../images/flechedroite.png" alt="flèche droite"
								/></a>
							</li>
							<li>
								<a class="lodging-category" href="#" @click.prevent="selectOption"
									><p class="raleway-reg-p16" data-value="Guestroom">{{ $t('guestrooms') }}</p>
									<img src="../../images/flechedroite.png" alt="flèche droite"
								/></a>
							</li>
							<li>
								<a class="lodging-category" href="#" @click.prevent="selectOption"
									><p class="raleway-reg-p16" data-value="Guesthouse">Maisons d'hôtes</p>
									<img src="../../images/flechedroite.png" alt="flèche droite"
								/></a>
							</li>
							<li>
								<a class="lodging-category" href="#" @click.prevent="selectOption"
									><p class="raleway-reg-p16" data-value="Villa">Villas</p>
									<img src="../../images/flechedroite.png" alt="flèche droite"
								/></a>
							</li>
							<li>
								<a class="lodging-category" href="#" @click.prevent="selectOption"
									><p class="raleway-reg-p16" data-value="Lodge">Lodges</p>
									<img src="../../images/flechedroite.png" alt="flèche droite"
								/></a>
							</li>
							<li>
								<a class="lodging-category" href="#" @click.prevent="selectOption"
									><p class="raleway-reg-p16" data-value="Gite">Gîtes</p>
									<img src="../../images/flechedroite.png" alt="flèche droite"
								/></a>
							</li>
							<li>
								<a class="lodging-category" href="#" @click.prevent="selectOption"
									><p class="raleway-reg-p16" data-value="Appartment">Appartements</p>
									<img src="../../images/flechedroite.png" alt="flèche droite"
								/></a>
							</li>
							<li>
								<a class="lodging-category" href="#" @click.prevent="selectOption"
									><p class="raleway-reg-p16" data-value="Other">{{ $t('unusual') }}</p>
									<img src="../../images/flechedroite.png" alt="flèche droite"
								/></a>
							</li>
						</ul>-->
						<a class="hortense-btn-primary raleway-bold-p" :href="'/reservation-hotel'">{{ $t('LodgingCard.view') }}</a>
					</div>
				</div>
				<!--<div class="col-8 pt-2" v-if="lodgingsFiltered">
					<div v-for="(lodging, index) in lodgingsFiltered" :key="index">
						<CardProduct style="margin-bottom: 5px" :wishlist="wishlist" :lodging="lodging" :destination="lodging.destination" :user="isUserConnected()" @connection="$emit('connection')"></CardProduct>
					</div>
					<div v-if="lodgingsFiltered.length" class="pagination-hub d-flex mt-3">
						<a href="#" @click.prevent="loadMoreLodgings" class="link-hortense"> voir plus <img src="../../images/flechebas-simple.svg" v-set-alt /></a>
					</div>
					<p v-else class="raleway-reg-p15 text-left">Hortense est à la recherche d'hébergements à vous proposer</p>
				</div>-->
				<div class="col-8 pt-2">
					<div class="row row-weekend">
						<div class="col-6 col-weekend">
							<!--<a :href="'/hotel-de-charme'" class="link-hortense" style="text-decoration: none">-->
								<div class="card-weekend" @click="goToGroup('/hotel-de-charme')" style="cursor:pointer" data-aos="fade-up" data-aos-duration="900">
								    <img src="../../images/lodging-group/hotel-charme-hortense.jpeg" v-set-alt>
									<p class="subtitle-card raleway-bold-p">NOS HÔTELS</p>
								</div>
							<!--</a>-->
						</div>
						<div class="col-6 col-weekend">
							<!--<a :href="'/villa-de-charme'" class="link-hortense" style="text-decoration: none">-->
								<div class="card-weekend" @click="goToGroup('/villa-de-charme')" style="cursor:pointer" data-aos="fade-up" data-aos-duration="900">
								    <img src="../../images/lodging-group/villa-charme-hortense.jpeg" v-set-alt>
									<p class="subtitle-card raleway-bold-p">NOS MAISONS ET VILLAS</p>
								</div>
							<!--</a>-->
						</div>
						<div class="col-6 col-weekend">
							<!--<a :href="'/chambres-dhotes-de-charme'" class="link-hortense" style="text-decoration: none">-->
								<div class="card-weekend" @click="goToGroup('/chambres-dhotes-de-charme')" style="cursor:pointer" data-aos="fade-up" data-aos-duration="900">
								    <img src="../../images/lodging-group/chambre-hotes-charme-hortense.jpeg" v-set-alt>
									<p class="subtitle-card raleway-bold-p">NOS CHAMBRES D’HÔTES</p>
								</div>
							<!--</a>-->
						</div>
						<div class="col-6 col-weekend">
							<!--<a :href="'/logement-insolite-de-luxe'" class="link-hortense" style="text-decoration: none">-->
								<div class="card-weekend" @click="goToGroup('/logement-insolite-de-luxe')" style="cursor:pointer" data-aos="fade-up" data-aos-duration="900">
								    <img src="../../images/lodging-group/logement-insolite-hortense.jpg" v-set-alt>
									<p class="subtitle-card raleway-bold-p">NOS LODGES ET LIEUX INSOLITES</p>
								</div>
							<!--</a>-->
						</div>
						<div class="col-6 col-weekend">
							<!--<a :href="'/appartement-de-vacances'" class="link-hortense" style="text-decoration: none">-->
								<div class="card-weekend" @click="goToGroup('/appartement-de-vacances')" style="cursor:pointer" data-aos="fade-up" data-aos-duration="900">
								    <img src="../../images/lodging-group/appartement-vacances-hortense.jpeg" v-set-alt>
									<p class="subtitle-card raleway-bold-p">NOS APPARTEMENTS</p>
								</div>
							<!--</a>-->
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import CardProduct from '../../components/cards/CardProduct.vue';
	import fetcher from 'packs/shared/api.js';

	export default {
		name: 'lodgingCard',
		props: {
			lodgings: Array,
			user: Object,
			wishlist: Object,
		},
		components: { CardProduct },
		data() {
			return {
				lodgingsList: this.lodgings,
				lodgingsFiltered: this.lodgings,
				SelectedOption: 'Hotel',
				lodgingsCurrentPage: 2, // starts at 2 as the first page is loaded by the parent component.
			};
		},
		watch: {
			SelectedOption() {
				// reset current page when switching type
				this.lodgingsCurrentPage = 2;
			},
		},
		methods: {
			goToGroup(url){
				//Turbolinks.visit(url);
                window.location.href = url;
            },
			isUserConnected() {
				return this.user ? this.user : {};
			},
			async loadMoreLodgings(e) {
				const responseLodging = await fetcher.get(`/reservation-hotel?page=${this.lodgingsCurrentPage}&lodgings_count=3&type=${this.SelectedOption}`);
				this.lodgingsCurrentPage++;
				// this.SeeMoreLodging(e);
				this.lodgingsFiltered = this.lodgingsFiltered.concat(responseLodging.data);
			},
			async selectOption(e) {
				const activeEl = document.querySelector('.active');
				activeEl.classList.remove('active');
				e.target.parentNode.classList.add('active');
				this.SelectedOption = e.target.dataset.value;
				if (this.SelectedOption) {
					const responseLodging = await fetcher.get(`/reservation-hotel?page=1&lodgings_count=3&type=${this.SelectedOption}`);
					this.lodgingsFiltered = responseLodging.data;
				} else {
					this.lodgingsFiltered = [];
				}
			},
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-card"></style>
