<template>
    <div id='card-galeries'>
      <a :href="'/reservation-hotel/' + lodging.slug">
        <div class="gallery-container">
          <div
            class="gallery-item"
            v-for="(img, index) in images"
            :key="index">
            <div class="sticker" v-if="index === 0">
              <h6 style="font-size: 0.8rem;">{{ lodging.name }}</h6>
              <div class="standing-galerie">
                <img src="../../images/star.svg" v-set-alt v-for='i in parseInt(lodging.standing)' :key='i'>
              </div>
              <p style="font-size: 0.7rem; margin-bottom: 0px;">{{ lodging.city.name }}, {{ lodging.country.name }}</p>
            </div>
            <img :src="img" class="galerie-img" :alt="lodging.name">
          </div>
        </div>
      </a>
    </div>
  </template>
  
  <script>
  export default {
    name: 'CardGaleriesTwo',
    props: ['lodging'],
    computed: {
      images() {
        const outsides = this.lodging.outside_urls;
        const commons = this.lodging.common_part_urls;
        return outsides.concat(commons);
      },
    },
  };
  </script>
  
  <style scoped>
  #card-galeries {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  .gallery-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    width: 90%;
    margin: auto;
  }
  
  .gallery-item {
    position: relative;
    overflow: hidden;
  }
  
  .galerie-img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .sticker {
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    padding: 8px 12px;
    border-top-right-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .gallery-container {
      grid-template-columns: repeat(2, 1fr);
    }
    .galerie-img {
      height: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .gallery-container {
      grid-template-columns: 1fr;
    }
    .galerie-img {
      height: 150px;
    }
  }
  </style>  