<template>
  <div id="guest-info">
    <div class="container-guest">
      <div class="adult choice-number row">
        <div class="col-4">
          <p class="raleway-reg-p15 mb-0">Adultes</p>
        </div>
        <div class="col-3" @click="decreaseAdults()">
          <img src="../../images/Moins.svg" v-set-alt />
        </div>
        <div class="col-2">
          <p class="raleway-bold-p mb-0">{{ adults }}</p>
        </div>
        <div class="col-2" @click="increaseAdults()">
          <img src="../../images/Plus.svg" v-set-alt />
        </div>
      </div>

      <div class="child choice-number row">
        <div class="col-4">
          <p class="raleway-reg-p15 mb-0">Enfants</p>
        </div>
        <div class="col-3" @click="decreaseChilds()">
          <img src="../../images/Moins.svg" v-set-alt />
        </div>
        <div class="col-2">
          <p class="raleway-bold-p mb-0">{{ childs }}</p>
        </div>
        <div class="col-2" @click="increaseChilds()">
          <img src="../../images/Plus.svg" v-set-alt />
        </div>
      </div>
      <!-- <div class="room choice-number row">
        <div class="col-4">
          <p class="raleway-reg-p15 mb-0">Chambres</p>
        </div>
        <div class="col-3" @click="decreaseRooms()">
          <img src="../../images/Moins.svg" v-set-alt />
        </div>
        <div class="col-2">
          <p class="raleway-bold-p mb-0">{{ rooms }}</p>
        </div>
        <div class="col-2" @click="increaseRooms()">
          <img src="../../images/Plus.svg" v-set-alt />
        </div>
      </div> -->
      <div class="dropdown-age" v-if="childs">
        <p class="raleway-reg-p15">
          Quel est l’âge {{ formatName(childs) }} voyageant avec vous ?
        </p>
        <template v-for="child in childs">
          <v-select
            class="select-item"
            placeholder="age"
            @input="selectAges"
            :options="agesChoices"
          ></v-select>
        </template>
      </div>
      <div class="closing-cross">
        <button class="" @click="close()">
          <img src="../../images/Croix fermer.svg" v-set-alt />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuestInfo",
  data() {
    return {
      rooms: 1,
      childs: 0,
      adults: 2,
      ageSelected: [],
      agesChoices: [
        "1 an",
        "2 ans",
        "3 ans",
        "4 ans",
        "5 ans",
        "6 ans",
        "7 ans",
        "8 ans",
        "9 ans",
        "10 ans",
        "11 ans",
        "12 ans",
        "13 ans",
        "14 ans",
        "15 ans",
        "16 ans",
        "17 ans",
      ],
    };
  },

  methods: {
    close() {
      this.$emit("closed");
    },
    selectAges(value) {
      this.ageSelected.push(value);
      this.$emit("ageSelected", this.ageSelected);
    },
    formatName(childs) {
      childs.length > 1 ? "des enfants" : "de l'enfant";
    },
    decreaseRooms() {
      if (this.rooms > 0) {
        this.rooms -= 1;
        this.$emit("decreaseRooms", this.rooms);
      }
    },
    increaseRooms() {
      if (this.rooms < 9) {
        this.rooms += 1;
        this.$emit("increaseRooms", this.rooms);
      }
    },
    decreaseChilds() {
      if (this.childs > 0) {
        this.childs -= 1;
        this.$emit("decreaseChilds", this.childs);
      }
    },
    increaseChilds() {
      if (this.childs < 9) {
        this.childs += 1;
        this.$emit("increaseChilds", this.childs);
      }
    },
    decreaseAdults() {
      if (this.adults > 0) {
        this.adults -= 1;
        this.$emit("decreaseAdults", this.adults);
      }
    },
    increaseAdults() {
      if (this.adults < 9) {
        this.adults += 1;
        this.$emit("increaseAdults", this.adults);
      }
    },
  },
};
</script>
<style lang="scss" scope="app/assets/stylesheets/components/search-btn"></style>
