<template>
  <div id="fidPhone">
    <div class="phone-container">
      <div class="header-fid">
        <h2 class="merriweather-reg-24"> {{ $t("Fid.head") }} </h2>
        <h1 class="merriweather-reg-24 mb-3" >{{ $t("Fid.title") }}</h1>
      </div>
        <p class="raleway-reg-p15 mb-3">{{ $t("Fid.content") }}</p>
        <button v-if="this.privilegeRegistration" @click="createPrivilege" class="hortense-btn-primary btn-fid mb-4">{{ $t("Fid.viewbtn") }}</button>
        <p v-else-if="this.privilegeRegistration === false" class="raleway-reg-p15 mb-4">Vous êtes inscrit</p>
        <button v-else @click='$emit("connection")'class="hortense-btn-primary btn-fid mb-4">{{ $t("Fid.viewbtn") }}</button>
        <a :href="'/programme-fidélité-hortensia/'" class="link-hortense">{{ $t("Fid.link") }}</a>
        <div class="fid-badges">
          <img src="../images/badge-nomad@2x.png" v-set-alt class="nomad">
          <img src="../images/badge-guest.png" class="guest" v-set-alt>
          <img src="../images/badge-trotter.png" v-set-alt class="trotter">
        </div>
      </div>
    <Flash :text='this.text' :status='this.status' @destroy='toggleStatus(status)'></Flash>
  </div>
</template>


<script>
  import fetcher from 'packs/shared/api.js';
  import TestimonyPhone from 'components/cards-phone/TestimonyPhone.vue';
  import Flash from 'alert/Flash.vue';

  export default {
    name: 'fidPhone',
    props: [ 'user' ],
    components : { TestimonyPhone, Flash },
    data() {
      return {
        status: null,
        text: null,
        privilegeRegistration: null,
      }
    },
    methods: {
      async createPrivilege(){
        try {
          const response = await fetcher.post('/privileges');
          this.text = "Inscription au prpgramme de fidélité";
          this.toggleStatus('success');
        }catch(e){
          this.toggleStatus('alert');
          this.text = 'Veuillez réessayer';
        }
      },
      toggleStatus(value="success") {
        this.status = this.status === value ? null : value
      },
      privilegeCondition(){
        return this.privilegeRegistration = this.user && this.user.privilege_id === null
      },
    },
    mounted() {
      this.privilegeCondition();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/fid-phone'>

</style>
