<template>
  <div id="card-message">
    <div class="card-inbox">
      <div class="first-part">
        <p class="raleway-bold-p16 mb-0 mr-5">Hôtel Les Aiguillettes</p>
        <p class="raleway-reg-p15 mb-0">Annulation séjour</p>
      </div>
      <div class="last-part">
        <p class="raleway-reg-p15 mb-0 mr-4">23/02/2020</p>
        <img src="../../images/flechebas-simple.svg" v-set-alt>
      </div>
    </div>
  </div>
</template>

<script>
  import fetcher from "packs/shared/api";
  
  export default{
    name: 'card-message',
    // props: {
    //   user: Object,
    //   reservations: Number,
    //   cancellations: Number,
    //   clients: Number,
    //   hortenses: Number,
    // },
    // methods: {
    //   async createRoom(){
    //     const res = await fetcher.post('/chat_rooms', { chat_room: {
    //                                                                         object: 'hortense',
    //                                                                         subject: '',
    //                                                                         receiver_id: '',
    //                                                                       }
    //                                                           })
    //     Turbolinks.visit('/chat_rooms/'+res.data.id)
    //   },
    // }
  }

</script>

<style lang="scss" scoped>
#card-message{
  .card-inbox{
    display:flex;
    justify-content: space-between;
    background: #F7F7F7;
    padding: 1rem;

    .first-part{
      display: flex;
      align-items:center;
    }
    .last-part{
      display: flex;
      align-items:baseline;
      img{
        width:12px;
      }
    }
  }
}

</style>
