<template>
<div class="container mt-5 pt-5" style="margin-top: 100px !important;">
<div class="row mt-5 pt-5">
  <div class="col-lg-10 col-md-10 col-sm-12 col-xsm-12">
    <h4 style="font: normal normal normal 26px/33px Merriweather;">{{ this.yoga_place.title }}</h4>
    <div class="raleway-reg-p15" v-html="this.yoga_place.description"></div>
  </div>
  <div class="col-md-6 col-lg-6 col-sm-12 mt-3" >
    <img class="img-fluid" style="height: 579px;" :src="this.yoga_place.photos_keys[0]" v-set-alt>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-3 col-xsm-3 mt-3">
    <div  v-for="photo in this.yoga_place.photos_keys.slice(1)" class=" pb-2  mb-1  ">
      <img class="img-fluid"  :src="photo" v-set-alt>
    </div>
  </div>
  <div class="col-lg-3 col-md-3 col-sm-12 col-xsm-12">
    <h6 class="pt-5 mt-3 pb-4" style="font: normal normal bold 16px/19px Raleway;">ÉQUIPEMENTS ET SERVICES</h6>
    <ul>
      <li style="font: normal normal normal 15px/24px Raleway;" v-for="service in this.yoga_services" class="pb-3">{{ service.name }}</li>
    </ul>
  </div>
</div>
</div>


</template>
<script>
import myImageSmall from '../../images/yogaplace2.png';
import myImageLarge from '../../images/yogaplace1.png';

export default{
  props: ['yoga_place', 'yoga_services'],
  name:'YogaPlace',
  data(){
    return{
      myImageSmall:myImageSmall,
      myImageLarge: myImageLarge
    }
  }
}
</script>

<style scoped>

</style>