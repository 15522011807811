<template>
  <div id="multiselectnote">
    <div class="dropdown dropdown-filter" @click="showDropdown">
      <div class="overselect"></div>
      <div class="c-form-input">
        <h3 class="raleway-semi-p13">Votre expérience culinaire</h3>
        <img src="../../images/Fleche haut.svg" v-set-alt v-if="show" />
        <img src="../../images/flechebas-simple.svg" v-set-alt v-else />
      </div>
    </div>
    <div class="multiselect" v-if="show">
      <ul>
        <div class="row row-selectoptions mt-3">
          <div class="col-10">
            <li>
              <input type="checkbox" id="Délice" :value="false" v-model="selected" />
              <label class="raleway-reg-p13 ml-2" for="Délice">Délice au Réveil - Petit déjeuner inclus</label>
            </li>
            <li>
              <input type="checkbox" id="Escapade" :value="false" v-model="selected2" />
              <label class="raleway-reg-p13 ml-2" for="Escapade">Escapade Gourmande - Demi-pension</label>
            </li>
            <li>
              <input type="checkbox" id="Évasion" :value="false" v-model="selected3" />
              <label class="raleway-reg-p13 ml-2" for="Évasion">Évasion Culinaire - Pension complète</label>
            </li>
            <li>
              <input type="checkbox" id="Plaisirs" :value="false" v-model="selected4" />
              <label class="raleway-reg-p13 ml-2" for="Plaisirs">Plaisirs Illimités - Tout inclus</label>
            </li>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import fetcher from 'packs/shared/api.js';

export default {
  name: 'multiselectexperience',
  props: ['options'],
  data() {
    return {
      show: false,
      selected: false,
      selected2: false,
      selected3: false,
      selected4: false,

    };
  },
  methods: {
    showDropdown() {
      this.show = !this.show;
    },
  },

  watch: {
    selected(val) {
      this.$emit('checked', val, 'delice');
    },
    selected2(val) {
      this.$emit('checked', val, 'escapade');
    },
    selected3(val) {
      this.$emit('checked', val, 'evasion');
    },
    selected4(val) {
      this.$emit('checked', val, 'plaisirs');
    },
  },
};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
