<template>
  <div id="newsletter">
      <div class="row">
      <div class="col-lg-5 col-xs-8">
        <h3 class="merriweather-reg-26">Gestion</h3>
        <h2 class="merriweather-reg-40 mb-5">Des newsletters</h2>
      </div>
      <div class="col-lg-4 col-xs-7 col-news">
        <Subscribe v-if="showModalSubscribe" @closeSubscribe="closeSubscribe" @close="showModalSubscribe = false"></Subscribe>
        <div class="switch-news" v-if="this.userMount.newsletter === false">
          <p class="raleway-reg-p13">Newletter</p>
          <img src="../../images/switch off.svg" v-set-alt @click="showModalSubscribe = true" >
        </div>
        <div class="switch-news" v-if="this.userMount.newsletter === true">
          <p class="raleway-reg-p13">Newletter</p>
          <img src="../../images/switch on.svg" v-set-alt @click="updateSuscribe()">
        </div>
      </div>
    </div>
    <Flash :text='this.text' :status='this.status' @destroy='toggleStatus(status)'></Flash>
  </div>
</template>

<script>

 
  import fetcher from 'packs/shared/api.js';
  import Subscribe from 'components/modals/Subscribe.vue';
  import Flash from 'alert/Flash.vue';

  export default {
    name: 'newsletter',
    components: { Subscribe, Flash },
    props: ["user"],
    data() {
      return {
        userMount: this.user,
        showModalSubscribe: false,
        text: '',
        status: '',
      }
    },
    mounted() {

    },
    methods: {
      async updateSuscribe(){
        try{
          const res = await fetcher.patch('/dashboard/'+this.user.id, { user: {newsletter: false}})
          this.userMount = res.data
        }catch(e){
          console.error(e)
        }
      },
      closeSubscribe() {
        this.showModalSubscribe = false;
        this.text = 'Subscription successfully done.';
        this.toggleStatus('success');
        location.reload();
      },
      toggleStatus(value="success") {
        this.status = this.status === value ? null : value
      },
    }
  }
</script>

<style lang="scss" scoped>
  #newsletter{
    width: 100%;
    height: 100%;
    margin-top: 6rem;
    .col-news{
      display: flex;
      align-items: self-end;
    }
    .switch-news{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }
</style>
