<template>
  <div id="login">
    <div v-if="forgotPassword" class="content">
      <div class="modal-content-form">
        <div class="header-modal">
          <h2 class="merriweather-reg-26">Mot de passe oublié</h2>
        </div>
        <div v-if="sent" class="form-signup raleway-reg-p15">
          <div class="form-item">
            <p>Nous venons d’envoyer un e-mail contenant des instructions ainsi qu’un lien pour réinitialiser votre mot de passe à l’adresse {{this.forgotMail}}. Il est possible que vous deviez patienter quelques minutes avant de le recevoir.</p>
          </div>
          <a href="/" class="hortense-btn-primary">Retour à l'accueil</a>
        </div>

        <div v-else class="form-signup raleway-reg-p15">
          <div class="form-item">
            <label for="name">Saisissez votre adresse email</label>
            <input v-model="forgotMail" type="text">
          </div>
          <div>
          </div>
          <button @click="sendForget" class="hortense-btn-primary">Envoyer le lien de réinitialisation</button>
        </div>
      </div>

    </div>
    <div v-else class="content">
      <div class="content-form">
        <div class="header-modal">
          <h2 class="merriweather-reg-26">Mon compte</h2>
        </div>
        <div class="form-signup raleway-reg-p15">
          <p style='color: red;' v-if='errorMessage'>invalid email or password</p>
          <div class="form-item">
            <label for="name">Email</label>
            <input v-model="userInfo.email" type="text">
          </div>
          <div class="form-item">
            <label for="name">Mot de passe</label>
            <input v-model="userInfo.password" type="password">
          </div>
          <a style="cursor: pointer" @click="forgotPassword = true"  class="link-hortense mb-4">Mot de passe oublié?</a>
          <button @click="UserBasicInfo" class="hortense-btn-primary">Me connecter</button>
        </div>
      </div>
      <div class="social-connection">
        <p class="raleway-reg-p15">ou</p>
        <p class="raleway-semi-p15">Se connecter avec :</p>
      </div>
      <div class="social-connection-btn">
        <div class="social-btn">
          <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/google.png" v-set-alt>
          <a :href="'/users/auth/google_oauth2'">Google</a>
        </div>
        <div class="social-btn">
          <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/facebook.png" v-set-alt>
          <a :href="'/users/auth/facebook'">Facebook</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import fetcher from 'packs/shared/api.js';

  export default{
    name: 'LoginForm',
    props: {
      redirectUrl: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        userInfo: {
          email: '',
          password: ''
        },
        showModal: false,
        errorMessage: false,
        forgotPassword: false,
        forgotMail: '',
        sent: false
      };
    },
    methods: {
      async UserBasicInfo(){
        try {
          const res = await fetcher.post('/users/sign_in', {
            user: {
              email: this.userInfo.email,
              password: this.userInfo.password,
              role: 'customer'
            }
          })
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'login',
            method: 'email_password',
          });
          this.$emit('close', res.data)
          location.replace(this.redirectUrl);
        } catch(e) {
          this.errorMessage = true;
        }
      },
      async sendForget(){
        try {
          const res = await fetcher.post('/send-reset-password', {
            email: this.forgotMail
          })
          console.log(res)
        } catch(e){
          console.log(e)
        }
        this.sent = true
      },
      setUser(user){
        this.user = user;
        this.showModallogin = false;
      },
    },
    mounted() {
      console.log(this.redirectUrl)
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/modal-signup'>

</style>
