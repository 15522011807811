<template>
  <div id="weekend-show">
    <div class="container-index">
      <div class="coll-header text-center ">
        <h1 class="merriweather-reg-40">Week-end {{ this.weekend.key_word }}</h1>
        <!-- <p class="merriweather-italic24">{{ this.weekend.subtitle_hub }}</p> -->
        <p class="raleway-reg-p15">{{ this.weekend.description }}</p>
        <p class="raleway-reg-p15">{{ this.formatLodgings() }}</p>
      </div>
       <div class="btn-filters">
        <div class="green-note-filter mr-3 df-jsb-aic" @click="sortByNote">
          <p class="raleway-reg-p13 mb-0 mr-3">Green note</p>
          <img src="../../images/fleches dropdown.svg" v-set-alt>
        </div>
        <div v-if="this.lodgingsCounter !== 0" class="map-redirection" @click="displayMap">
          <p class="raleway-reg-p13 mb-0">Carte</p>
        </div>
      </div>
      <div class="filters-phone">
        <p class="raleway-reg-p15 mb-0" @click="showFilters"  v-if="showFilt">Masquer les filtres</p>
        <p class="raleway-reg-p15 mb-0" @click="showFilters" v-else>Appliquer les filtres</p>
      </div>
      <div class="row row-page">
        <div class="col-lg-3 col-xs-0 col-filter">
          <Filterlodgings @checked='filterLodgings' @switch="filteringLodgings"></Filterlodgings>
        </div>
        <div class="col-lg-9 col-xs-10">
          <div class='lodging-collection' v-if="this.lodgingsMount">
            <Maptest v-if="showMap" :markers="this.markers"  :lodgings="this.filteredLodgings" @markerSelected="selectCardMap" :selectCardMap="selectCardMap" :selectCard="selectCard" @close="showMap = false"></Maptest>
            <div v-if='!selectedMarker'>
              <CardProductbig v-for='(lodging, index) in this.filteredLodgings' :key='index' :lodging="lodging" :user='isUserConnected()' :destination='lodging.destination' v-if='index === 0' :data-marker='index' :data-lodging='lodging.id'></CardProductbig>
              <CardProductbig :lodging="lodging" :user='isUserConnected()' :destination='lodging.country' v-else style='margin-top: 20px' :data-marker='index' :data-lodging='lodging.id'></CardProductbig>
            </div>
            <div v-else>
              <Cardmap :lodging='this.selectedLodging' :country='this.selectedLodging.country' @unselect='selectCard' :idMarker='selectedCard'></Cardmap>
            </div>
          </div>
          <div v-if=" lodgingsCounter === 0" class="no-lodging">
            <p class="raleway-reg-p15 text-center">Aucun hébergement disponible.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';
  import CardProductbig from 'components/cards/CardProductbig.vue';
  import Filterlodgings from 'pages/destinations/Filterlodgings.vue';
  import Cardmap from 'components/cards/Cardmap.vue';
  import Maptest from 'components/Maptest.vue';

  export default{
    name: 'weekend-show',
    props: [ 'weekend', 'lodgings', 'user'],
    components: { CardProductbig, Filterlodgings, Maptest, Cardmap },
    data() {
      return {
        lodgingsMount: this.lodgings,
        lodgingsCounter: 0,
        filteredLodgings: this.lodgings,
        showMap: false,
        markers: [],
        showFilt: false,
        selectedMarker: false,
        selectedLodging: null,
        selectedCard: null,
        current: 1,
        asc: true,
      }
    },

    methods: {
      showFilters(){
        const filter = document.querySelector('#filter-lodging')
        if(filter.style.display === 'block') {
          this.showFilt = false
          filter.style.display = 'none';
        } else {
          filter.style.display ='block'
          this.showFilt = true
        }
      },
      selectCardMap(attr){
        this.selectCard(attr);
        if(this.showMap){
          setTimeout(() => {this.showMap = false}, 10);
          setTimeout(() => {this.showMap = true}, 10);
        }
      },
      isUserConnected() {
        return this.user ? this.user : {}
      },
      filteringLodgings(value = '', key = '') {
        if (value[0] === '' || value[0] === undefined) {
          this.filteredLodgings = this.lodgingsMount
          console.log(this.filteredLodgings)
        } else {
          this.filteredLodgings = this.filteredLodgings.filter( lodging => {
            return lodging.lodging_profile.animal === true
          })
        }
        this.lodgingsCounter = this.filteredLodgings.length
      },
      filterLodgings(value = '', key = '') {
        if (value[0] === '' || value[0] === undefined) {
          this.filteredLodgings = this.lodgingsMount
        } else {
          this.filteredLodgings = this.lodgingsMount.filter(el => {
            return (value.includes(el[key].toString()) || ( Array.isArray(el[key]) && this.includesIn(value, el[key]?.map(e => e.name))))
          })
        }
        this.lodgingsCounter = this.filteredLodgings.length
      },
      selectCard(attr) {
        const cards = document.querySelectorAll('#cardProduct-big')
        const selectedCard = cards[attr[0]]
        this.selectedCard = attr[0]
        this.selectedMarker = !this.selectedMarker;
        cards.forEach(el => {
          if (el?.dataset?.marker !== selectedCard?.dataset?.marker) {
            el.style.display === 'none' ? el.style.display = 'flex' : el.style.display = 'none'
          }
        })
        this.selectedLodging = this.lodgingsMount.filter(lod => {return lod.id === parseInt(selectedCard?.dataset?.lodging)})[0]
      },
      sortByNote(){
        this.filteredLodgings.sort((a, b) => {
          if ( this.asc ) {
            return a.score - b.score
          } else {
            return b.score - a.score
          }
        })
        this.asc = !this.asc
      },
      markerMap() {
        const markers = [];
        this.filteredLodgings.map(el => {
          markers.push([el.longitude, el.latitude,el.id,  el.lowest_price])
        })
        return markers;
      },
      displayMap() {
        this.showMap = !this.showMap;
        this.markers = this.markerMap();
      },
      includesIn(array1, array2) {
        for (const val of array1) {
          if (array2.includes(val)) {
            return true
          }
        }
        return false
      },
      formatLodgings() {
        let hébergement = ' hébergement';
        if (this.lodgingsCounter > 1) {
          hébergement += 's';
        }
        return  this.lodgingsCounter  + hébergement;
      },
      lodgingCount() {
        this.lodgingsCounter += this.lodgingsMount.length;
      },
      /*async fetchWeekends() {
        try {
          // const response = await fetcher.get('/weekends/' + this.weekend.id)
          // this.lodgingsMount = response.data;
          //  this.filteredLodgings = response.data;
        } catch(e) {
          console.error(e)
        }
      },*/
    },
    mounted() {
      this.lodgingCount();
    }
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/weekend-show'>


</style>
