<template>
  <div id="reassurance">
    <div class="container-label">
      <div class="row row-reas">
        <div class="col-2 col-reas">
          <img src="../images/hébergements.svg" v-set-alt>
          <p class="raleway-reg-p13 text-center"> {{ $t("Reassurance.one") }} </p>
        </div>
        <div class="col-2 col-reas">
          <img src="../images/Indicateurs.svg" v-set-alt>
          <p class="raleway-reg-p13 text-center">{{ $t("Reassurance.two") }}</p>
        </div>
        <div class="col-2 col-reas">
          <img src="../images/PGM.svg" v-set-alt>
          <p class="raleway-reg-p13 text-center">{{ $t("Reassurance.three") }}</p>
        </div>
        <div class="col-2 col-reas">
          <img src="../images/experiences.svg" v-set-alt>
          <p class="raleway-reg-p13 text-center">{{ $t("Reassurance.four") }}</p>
        </div>
        <div class="col-2 col-reas">
          <img src="../images/CO2.svg" v-set-alt>
          <p class="raleway-reg-p13 text-center">{{ $t("Reassurance.five") }}</p>
        </div>
        <div class="col-2 col-reas">
          <img src="../images/securite.svg" v-set-alt>
          <p class="raleway-reg-p13 text-center">{{ $t("Reassurance.six") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    name: 'reassurance',
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/reassurance'>

</style>
