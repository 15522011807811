<template>
  <div id="experience">
    <div class="card-container">
      <div class="row row-hubs">
        <div class="col-4">
          <div class="experience-header">
            <h2 class="merriweather-reg-26">Pour quelles</h2>
            <h2 class="merriweather-reg-40 mb-5"> Expériences ?</h2>
            <p class="raleway-reg-p16">Vous n’imaginez pas votre séjour sans une dose de découverte ? Voici notre sélection d’expériences locales, à faire entre amis, en famille ou à deux. Nature, sport, culture, gastronomie… Il y a une aventure pour chacun d’entre vous.</p>
          </div>
            <a class="hortense-btn-primary raleway-bold-p mt-5" :href="'/experiences'">Voir toutes les idées d'expériences</a>
        </div>
        <div class="col-8 pt-2">
          <div class="row row-experience">
            <div class="col-6 col-experience" v-for="(category, index) in categoryNames">
              <CardExperience :category='category' v-if='index === 0' data-aos="fade-up" data-aos-duration="900"></CardExperience>
              <CardExperience :category='category' v-else-if='seeMore(index)' data-aos="fade-up" data-aos-duration="900"></CardExperience>
            </div>
          </div>
            <div class="pagination-hub d-flex mt-3">
              <a href="#" @click='SeeMoreExperience' class="link-hortense"> voir plus <img src="../images/flechebas-simple.svg" v-set-alt></a>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

  import AOS from 'aos';
  import CardExperience from '../components/cards/CardExperience.vue';

  export default {
    name: 'experience',
    components: { CardExperience },
    props: {
      experiencesByCategory: Object,
    },
    data() {
      return {
        categoriesMounted: this.experiencesByCategory,
        numberOfVisibleExperience: 4,
        categoryNames:[]
      }
    },
    methods: {
      getKeys(){
        Object.entries(this.experiencesByCategory).forEach(([key, value]) => this.categoryNames.push(key));
      },
      seeMore(index) {
        return index < this.numberOfVisibleExperience;
      },
      SeeMoreExperience(e) {
        e.preventDefault();
        this.numberOfVisibleExperience += 4;
      },
      SeeLessExperience(e) {
        e.preventDefault();
        this.numberOfVisibleExperience -= 4;
      },
    },
    mounted(){
      this.getKeys();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/experience'>

</style>
