<template>
	<div id="multiselectstanding">
		<div class="dropdown dropdown-filter" @click="showDropdown">
			<div class="overselect"></div>
			<div class="c-form-input">
				<h3 class="raleway-semi-p13">Nombre d'étoiles</h3>
				<img src="../../images/Fleche haut.svg" v-set-alt v-if="show" />
				<img src="../../images/flechebas-simple.svg" v-set-alt v-else />
			</div>
		</div>
		<div class="multiselect" v-if="show">
			<ul>
				<div class="row row-selectoptions mt-3">
					<div class="col-8">
						<li>
							<input type="checkbox" id="0-star" value="0" v-model="selectedStar" />
							<label class="raleway-reg-p13 ml-2" for="0-star">Non classé</label>
						</li>
						<li>
							<input type="checkbox" id="1-star" value="1" v-model="selectedStar" />
							<label class="raleway-reg-p13 ml-2" for="1-star">1 étoile</label>
						</li>
						<li>
							<input type="checkbox" id="2-star" value="2" v-model="selectedStar" />
							<label class="raleway-reg-p13 ml-2" for="2-star">2 étoiles</label>
						</li>
						<li>
							<input type="checkbox" id="3-star" value="3" v-model="selectedStar" />
							<label class="raleway-reg-p13 ml-2" for="3-star">3 étoiles</label>
						</li>
						<li>
							<input type="checkbox" id="4-star" value="4" v-model="selectedStar" />
							<label class="raleway-reg-p13 ml-2" for="4-star">4 étoiles</label>
						</li>
						<li>
							<input type="checkbox" id="5-star" value="5" v-model="selectedStar" />
							<label class="raleway-reg-p13 ml-2" for="5-star">5 étoiles</label>
						</li>
					</div>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
	import Sliderstars from 'pages/destinations/Sliderstars';

	export default {
		name: 'multiselectstanding',
		components: { Sliderstars },
		props: ['options'],
		data() {
			return {
				show: false,
				selectedStar: [],
			};
		},
		methods: {
			showDropdown() {
				this.show = !this.show;
			},
		},
		watch: {
			selectedStar(val) {
				this.$emit('checked', val, 'standing');
			},
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
