<template>
  <div class="container mt-4">
    <div class="row">
      <div class="col-lg-10 col-md-10 col-sm-12 col-xsm-12">
        <h4 style="font: normal normal normal 16px/20px Merriweather;">{{ this.yoga_place.title }} </h4>
        <div class="raleway-reg-p13" v-html="this.yoga_place.description"></div>
        <br><br>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6 col-lg-6 col-9 mr-0  pl-0 pr-0"><img :src="this.yoga_place.photos_keys[0]" class="img-fluid"></div>
        <div class="col-lg-3 col-md-3 col-2 pl-3 pr-1">
          <div class="mb-2"  v-for="photo in this.yoga_place.photos_keys.slice(1)">
            <img class="img-fluid"  :src="photo" v-set-alt>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-12 col-xsm-12">
        <h6 class="pt-5 mt-3 pb-4" style="font: normal normal bold 16px/19px Raleway;">ÉQUIPEMENTS ET SERVICES</h6>
        <ul>
          <li style="font: normal normal normal 15px/24px Raleway;" v-for="service in this.yoga_services" class="pb-3">{{ service.name }}</li>
        </ul>
      </div>
    </div>
    <br><br>
  </div>


</template>
<script>
import myImageSmall from '../../images/yogaplace2.png';
import myImageLarge from '../../images/yogaplace1.png';

export default{
  props: ['yoga_place', 'yoga_services'],
  name:'YogaPlacePhone',
  data(){
    return{
      myImageSmall:myImageSmall,
      myImageLarge: myImageLarge
    }
  }
}
</script>

<style scoped>

</style>