<template>
	<div id="lodginghome-phone">
		<div class="phone-container">
			<div class="lodging-header">
				<h2 class="merriweather-reg-26"> Nos </h2>
        		<h2 class="merriweather-reg-40 mb-5">{{ $t('lodgings') }}</h2>
				<p class="raleway-reg-p16">{{ $t('LodgingCard.title') }}</p>
			</div>
			<div class="lodging-header">
				<slither-slider :options="{loop: true, autoplay: true, numberOfSlides: 1,dots: false, controls: false}">
					<div class="row row-weekend">
							<!--<a :href="'/hotel-de-charme'" class="link-hortense" style="text-decoration: none">-->
								<div class="col-12" @click="goToGroup('/hotel-de-charme')">
									<img src="../images/lodging-group/hotel-charme-hortense.jpeg" v-set-alt class="img-group">
									<p class="subtitle-card raleway-bold-p">NOS HÔTELS</p>
								</div>
							<!--</a>-->
							<!--<a :href="'/villa-de-charme'" class="link-hortense" style="text-decoration: none">-->
								<div class="col-12" @click="goToGroup('/villa-de-charme')">
									<img src="../images/lodging-group/villa-charme-hortense.jpeg" v-set-alt class="img-group">
									<p class="subtitle-card raleway-bold-p">NOS MAISONS ET VILLAS</p>
								</div>
							<!--</a>-->
					</div>
					<div class="row row-weekend">
						    <!--<a :href="'/chambres-dhotes-de-charme'" class="link-hortense" style="text-decoration: none">-->
								<div class="col-12" @click="goToGroup('/chambres-dhotes-de-charme')">
									<img src="../images/lodging-group/chambre-hotes-charme-hortense.jpeg" v-set-alt class="img-group">
									<p class="subtitle-card raleway-bold-p">NOS CHAMBRES D’HÔTES</p>
								</div>
							<!--</a>-->
							<!--<a :href="'/logement-insolite-de-luxe'" class="link-hortense" style="text-decoration: none">-->
								<div class="col-12" @click="goToGroup('/logement-insolite-de-luxe')">
									<img src="../images/lodging-group/logement-insolite-hortense.jpg" v-set-alt class="img-group">
									<p class="subtitle-card raleway-bold-p">NOS LODGES ET LIEUX INSOLITES</p>
								</div>
							<!--</a>-->
					</div>
					<div class="row row-weekend">
						<!--<a :href="'/appartement-de-vacances'" class="link-hortense" style="text-decoration: none">-->
							<div class="col-12" @click="goToGroup('/appartement-de-vacances')">
								<img src="../images/lodging-group/appartement-vacances-hortense.jpeg" v-set-alt class="img-group">
								<p class="subtitle-card raleway-bold-p">NOS APPARTEMENTS</p>
							</div>
						<!--</a>-->
					</div>
				</slither-slider>
			</div>
		</div>
	</div>
</template>

<script>
	import CardLodging from 'components/cards-phone/CardLodging.vue';
	import fetcher from 'packs/shared/api.js';

	export default {
		name: 'lodginghome-phone',
		components: { CardLodging },
		props: {
			lodgings: Array,
			user: Object,
		},
		data() {
			return {
				SelectedOption: 'Hotel',
				lodgingsList: [],
				lodgingsFiltered: [],
			};
		},
		methods: {
			goToGroup(url){
				//Turbolinks.visit(url);
                window.location.href = url;
            },
			isUserConnected() {
				return this.user ? this.user : {};
			},
			selectOption(e) {
				const activeEl = document.querySelector('.active');
				activeEl.classList.remove('active');
				e.target.parentNode.classList.add('active');
				e.preventDefault();
				this.SelectedOption = e.target.dataset.value;
				console.log(this.SelectedOption);
				if (this.SelectedOption) {
					this.fetchLodgings(this.SelectedOption);
					// this.lodgingsFiltered = this.lodgings.filter(lodging => lodging.type === this.SelectedOption);
				} else {
					this.lodgingsFiltered = [];
				}
			},
			async fetchLodgings(type) {
				const responseLodging = await fetcher.get(`/reservation-hotel?page=1&lodgings_count=3&type=${type}`);
				this.lodgingsFiltered = responseLodging.data;
			}
		},
		created() {
			this.lodgingsList = this.lodgings;
			this.lodgingsFiltered = this.lodgings;
			this.fetchLodgings('Hotel');
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodginghome-phone">
  .img-group {
	height:170px;
	width:100%;
  }
</style>
