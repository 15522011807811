<template>
  <div id="tips" :class="{'desktop-margin': !isPhone}">
    <div class="titles center tips-margin-top ">
      <h1 class="title-first merriweather-reg-26 mt-4">{{ $t("Propositions.title1") }}</h1>
      <h1 class="title-second merriweather-reg-40">{{ $t("Propositions.title2") }}</h1>
      <p class="raleway-reg-p16">
         Hortense a déniché pour vous les plus beaux hébergements éco-responsables.<br>
         Des lieux authentiques et pleins de charme soigneusement sélectionnés pour leur engagement environnemental.<br>
         Une Green Note minimale de 5/10 est nécessaire pour intégrer notre collection.<br>
         Séjourner avec Hortense, c’est l’assurance d’un lieu d'exception respectueux de l’environnement.<br>
         Chaque réservation contribue à promouvoir le tourisme durable.
      </p>
    </div>
    <div class="container-fluid py-2">
    <div class="d-flex flex-row flex-nowrap row-weekend mb-5" id="scrollerr"  style="overflow: scroll; overflow-y: hidden">
        <div class="col-proposition col-weekend">
          <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
            <img src="../images/proposition/proposition-1.jpeg" v-set-alt class="mb-2">
            <p class="element raleway-bold-p mt-2 mb-4">{{ $t("Propositions.content.first") }}</p>
            <a href="/reservation-hotel?search=" class="anchor">{{ $t("Propositions.anchor.first") }}</a>
          </div>
        </div>

        <div class="col-proposition col-weekend">          
          <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
            <img src="../images/proposition/proposition-2.png" v-set-alt class="mb-2">
            <p class="element raleway-bold-p mt-2 mb-4">{{ $t("Propositions.content.second") }}</p>
            <a href="/programme-fidélité-hortensia" class="anchor">{{ $t("Propositions.anchor.second") }}</a>
          </div>
        </div>
<!-- 
      <div class="col-proposition col-weekend">
        <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
          <img src="../images/proposition/proposition-7.png" v-set-alt class="mb-2">
          <p class="element raleway-bold-p mt-2 mb-4 pb-3">{{ $t("Propositions.content.seven") }}</p>
          <a href="/club-hortense" class="anchor">{{ $t("Propositions.anchor.seven") }}</a>
        </div>
      </div> -->

<!--      <div class="col-proposition col-weekend">-->
<!--        <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">-->
<!--          <img src="../images/proposition/sixith.jpeg" v-set-alt class="mb-2">-->
<!--          <p class="element raleway-bold-p mt-2 mb-4">Des journées et séjours bien-être</p>-->
<!--          <a href="/experiences-bien-etre" class="anchor">Découvrez nos expériences bien-être</a>-->
<!--        </div>-->
<!--      </div>-->

      <div class="col-proposition col-weekend">
        <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
          <img src="../images/proposition/nos-img.jpg" v-set-alt class="mb-2">
          <p class="element raleway-bold-p mt-2 mb-4">Des nouveautés tous les mois dans notre sélection</p>
          <a href="/les-nouveautes-hortense" class="anchor">Réservez votre prochain séjour</a>
        </div>
      </div>

        <div class="col-proposition col-weekend">          
          <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
            <img src="../images/proposition/proposition-5.png" v-set-alt class="mb-2">
            <p class="element raleway-bold-p mt-2 mb-4">{{ $t("Propositions.content.fifth") }}</p>
            <a href="/voyage-sur-mesure" class="anchor">{{ $t("Propositions.anchor.fifth") }}</a>
          </div>
        </div>

        <div class="col-proposition col-weekend">          
          <div class="card-proposition" data-aos="fade-up" data-aos-duration="1000">
            <img src="../images/proposition/proposition-4.jpg" v-set-alt class="mb-2">
            <p class="element raleway-bold-p mt-2 mb-4">{{ $t("Propositions.content.fourth") }}</p>
            <a href="/programme-evergreen-demarche-environnementale" class="anchor">{{ $t("Propositions.anchor.fourth") }}</a>
          </div>
        </div>
      </div>
      <div class="flex-shrink-0" style="position: absolute; margin-left: 41%;">
        <button @click="scroll_left" class="btn-left btn-link p-2 toggle text-dark"><img style="width: 12px; height: 24px;" src="../images/fleche gauche.svg" v-set-alt /></button>
      </div>
      <div class="flex-shrink-0" style="position: absolute; margin-left: 45%;">
        <button @click="scroll_right" class="btn-right btn-link toggle p-2 text-dark"><img style="width: 12px; height: 24px;" src="../images/fleche droite.svg" v-set-alt /></button>
      </div>
    </div>
    <div class="mt-5"></div>
  </div>
</template>

<script>
  import AOS from 'aos';

  export default {
    name: 'propositions',
    data() {
      return {
        cssProps: {
          backgroundImage: `url(${require('../images/icotype.svg')})`
        }
      }
    },
    methods: {
      scroll_left() {
        let content = document.querySelector("#scrollerr");
        content.scrollLeft -= 220;
      },
      scroll_right() {
        let content = document.querySelector("#scrollerr");
        content.scrollLeft += 220;
      }
    },
    mounted() {
      AOS.init();
    },
    computed: {
      isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
     }
    },
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/tips'>

</style>
<style lang="scss" scoped>
 .col-proposition {
    //flex: 0 0 20%;
    //max-width: 20%;
  }
  .desktop-margin {
    margin-top: 1rem !important;
  }
  .center{
    text-align: center;
  }
  .anchor{
        text-decoration: underline;
        font-family: $Raleway;
        font-weight: 500;
        font-size: 15px;
        color: #000000;
  }
  .subtitle{
    width: 670px;
  }
  .element{
    width: 240px;
  }
  .card-proposition{
    img{
      width: 220px;
      height: 280px;
      object-fit: cover;
    }
  }
  .tips-margin-top {
    margin-top: 3rem;
  }
</style>

