<template>
  <div class="container horizontal-scrollable mt-2 pt-2" v-if="this.yoga_program">
    <h3 v-if="!is_urban && this.yoga_program.length != 0" style="font: normal normal normal 26px/33px Merriweather;" >Le programme du séjour</h3>
    <h3 v-if="is_urban && this.yoga_program.length != 0" style="font: normal normal normal 26px/33px Merriweather;" >Le programme de la journée</h3>
    <p class="pb-4 raleway-reg-p15" style="font: normal normal normal 15px/18px Raleway;">{{ this.yoga_schedule.program_description}}</p>
    <div class="row border p-5">
      <div class="col-md-3 col-lg-3 col-12" v-if="yoga_program.filter(resa => resa[2] === 1).length != 0">
        <ul class="experiences" style="list-style-type: none" >
          <li  v-for="program in program1"><MiniList :title= "program[0]" :description="program[1]" /></li>
          <MiniList />
        </ul>
      </div>
      <div v-if="yoga_program.filter(resa => resa[2] === 3).length == 0 && yoga_program.filter(resa => resa[2] ===4).length == 0 && yoga_program.filter(resa => resa[2] ===5).length == 0 " class="col-md-6 col-lg-6 p-3 col-sm-12">
        <img class="img-fluid" v-if="this.yoga_schedule.program_photo" :src="this.yoga_schedule.program_photo" v-set-alt>
      </div>
      <div class="col-md-3 col-lg-3 col-12" v-if="yoga_program.filter(resa => resa[2] === 2).length != 0">
        <ul class="experiences" style="list-style-type: none">
          <li  v-for="program in program2"><MiniList :title= "program[0]" :description="program[1]" /></li>
          <MiniList />
        </ul>
      </div>
      <div class="col-md-3 col-lg-3 col-12" v-if="yoga_program.filter(resa => resa[2] === 3).length != 0">
        <ul class="experiences" style="list-style-type: none">
          <li  v-for="program in program3"><MiniList :title= "program[0]" :description="program[1]" /></li>
          <MiniList />
        </ul>
      </div>
      <div class="col-md-3 col-lg-3 col-12" v-if="yoga_program.filter(resa => resa[2] ===4).length != 0">
        <ul class="experiences" style="list-style-type: none">
          <li  v-for="program in this.program4"><MiniList :title= "program[0]" :description="program[1]" /></li>
          <MiniList />
        </ul>
      </div>
      <div class="col-md-3 col-lg-3 col-12" v-if="yoga_program.filter(resa => resa[2] ===5).length != 0">
        <ul class="experiences" style="list-style-type: none">
          <li  v-for="program in this.program5"><MiniList :title= "program[0]" :description="program[1]" /></li>
          <MiniList />
        </ul>
      </div>
      <div class="col-md-3 col-lg-3 col-12" v-if="yoga_program.filter(resa => resa[2] ===6).length != 0">
        <ul class="experiences" style="list-style-type: none">
          <li  v-for="program in this.program6"><MiniList :title= "program[0]" :description="program[1]" /></li>
          <MiniList />
        </ul>
      </div>
    </div>
    <p class="pt-4 raleway-reg-p15" style="font: normal normal normal 15px/18px Raleway;">{{ this.yoga_schedule.day_description}}</p>
  </div>
</template>
<script>
import MiniList from "./MiniList.vue";
import myImage from '../../images/bullet.png';
export default {
  name: 'YogaProgram',
  components: {MiniList},
  props: ['yoga_program', 'yoga_schedule', 'is_urban'],
  data(){
    return{
      myimage: myImage
    }
  },
  methods: {
  },
  computed: {
    program1() {
      return this.yoga_program.filter(resa => resa[2] === 1);
    },
    program2() {
      return this.yoga_program.filter(resa => resa[2] === 2);
    },
    program3() {
      return this.yoga_program.filter(resa => resa[2] === 3);
    },
    program4() {
      return this.yoga_program.filter(resa => resa[2] === 4);
    },
    program5() {
      return this.yoga_program.filter(resa => resa[2] === 5);
    },
    program6() {
      return this.yoga_program.filter(resa => resa[2] === 6);
    }
  }
}
</script>
<style scoped>
ul.experiences li {
  position:relative; /* so that pseudoelements are positioned relatively to their "li"s*/
  /* use padding-bottom instead of margin-bottom.*/
  margin-bottom: 0; /* This overrides previously specified margin-bottom */
  padding-bottom: 0.5em;
}

ul.experiences li:after {
  /* bullets */
  content: url('../../images/bullet.png');
  position: absolute;
  left: -23px; /*adjust manually*/
  top: 0px;
}

ul.experiences li:before {
  /* lines */
  content:"";
  position: absolute;
  left: -16px; /* adjust manually */
  border-left: 1px solid #3b6152;
  height: 100%;
  width: 1px;
}

ul.experiences li:first-child:before {
  /* first li's line */
  top: 6px; /* moves the line down so that it disappears under the bullet. Adjust manually */
}

ul.experiences li:last-child:before {
  /* last li's line */
  height: 6px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
}


/* The heart of the matter */

</style>