<template>
  <div id="card-map">
    <div class="cross" @click='$emit("unselect", idMarker)'>
      <img src="../../images/Croix fermer.svg" v-set-alt>
    </div>
    <div class="card-map">
      <img class="hub-img" :src="this.formatSource()" :alt="this.lodging.name">
      <div class="sticker-hub d-flex mt-4 pl-2">
        <img :src="greenNotePicto((this.lodging.score).toFixed())" :alt="(this.lodging.score).toFixed()" v-if="this.lodging.score">
        <div class="sticker-info d-flex" v-if="this.lodging.score">
          <p class="raleway-reg-p13 mb-0 pl-1 pr-2"> <b>{{ (this.lodging.score).toFixed() }}</b>/10</p>
          <p class="raleway-reg-p13 mb-0 pl-1 pr-2">Green note</p>
        </div>
      </div>
      <div class="card-hub-info">
        <div class="hub-header d-flex">
          <h1 class="raleway-bold-h3">{{this.lodging.name}}</h1>
          <!--  <div>
              <img src="../../images/wishlistOn.svg" v-set-alt v-if='isIncludeInWishlist()' @click='toggleLike()'>
              <img src="../../images/wishlist.svg" v-set-alt v-else @click='toggleLike()'>
            </div> -->
        </div>
        <div class="standing" v-if="parseInt(this.lodging.standing)">
            <img src="../../images/Etoiles.svg" v-set-alt v-for='i in parseInt(this.lodging.standing, 10)' :key='i'>
        </div>
        <!--<div class="standing">
          <img src="../../images/Etoiles.svg" v-set-alt>
          <img src="../../images/Etoiles.svg" v-set-alt>
          <img src="../../images/Etoiles.svg" v-set-alt>
          <img src="../../images/Etoiles.svg" v-set-alt>
          <img src="../../images/Etoiles.svg" v-set-alt>
        </div>-->
        <p class="description-hub raleway-reg-p13" v-if='this.country'>{{this.country.name}}, région {{this.lodging.region.name}}</p>
        <!--<div class="location-hub d-flex">
          <img src="../../images/Localisation.svg" v-set-alt>
          <p class="raleway-reg-p13 pl-1 pr-2">3,4km du centre</p>
          <a href="#" class="link-hortense">Voir sur la carte</a>
        </div>-->
        <div class="catch-hub" v-if='this.lodging.lodging_description.catch_sentence'>
          <p class="merriweather-italic">{{ this.lodging.lodging_description.catch_sentence }}</p>
        </div>
        <div class="catch-hub" v-else>
          <p class="merriweather-italic">Un havre aussi éco-responsable que luxueux</p>
        </div>
        <p class="raleway-reg-p13">A partir de {{ this.lodging.lowest_price }}€<strong>/nuit</strong></p>
        <div class="reviews-btn">
          <!-- <div class="hub-reviews d-flex">
            <img class="mt-2 mr-2" src="../../images/avis.svg" v-set-alt>
            <div class="review-info d-flex">
              <div class="score-hub d-flex">
                <p class="raleway-reg-p13 mb-0 pl-1 pr-2"><b>5</b>/10</p>
                <a href="#" class="link-hortense">10 avis</a>
              </div>
              <p class="raleway-reg-p13">Note des voyageurs</p>
            </div>
          </div> -->
          <a :href="'/reservation-hotel/'+this.lodging.slug" class="xs-btn-primary text-center">Voir les disponibilités</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default{
   name: 'card-map',
   props: ['lodging', 'country', 'user', 'idMarker'],
    data() {
      return {
        wishlistLodgings: null,
      }
    },
    methods: {
      greenNotePicto(score){
        if (score === `${score}`){
          return require(`../../images/greennote ${score}.svg`)
        } else {
          return require("../../images/blanc.png")
        }
      },
      formatSource() {
        if (this.lodging.photo_banner) {
          return this.lodging.photo_banner
        } else {
          return 'https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0=';
        }
      },
      async toggleLike() {
        if (this.user) {
          const response = await fetcher.patch('/wishlists/'+ 1, { lodging_id: this.lodging.id })
          this.wishlistLodgings = response.data.lodgings;
        } else {
          this.$emit('connection');
        }
      },
      isIncludeInWishlist() {
        if (this.wishlistLodgings) {
          for (const lodging of this.wishlistLodgings) {
            if (lodging.id === this.lodging.id) {
              return true
            }
          }
        }
        return false
      },
      async fetchWishlist() {
        if (this.user) {
        const response = await fetcher.get('/wishlists.json')
        this.wishlistLodgings = response.data.lodgings
      }
      }
    },
    beforeMount() {
      this.fetchWishlist()
    }
 }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/map'>

</style>
