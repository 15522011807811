<template>
  <div id='card-galeries'>
    <a :href="'/reservation-hotel/' + lodging.slug">
    <div :class='"gallery_item item_1 structure-galerie"' v-if="this.images" >
      <div
        class="position"
        v-for="(img, index) in this.images"
        :key='index' v-if="index < 4"
      >
        <div class="sticker" v-if='index === 2'>
          <h2>{{ lodging.name }}</h2>
          <div class="standing-galerie">
            <img src="../../images/star.svg" v-set-alt v-for='i in parseInt(lodging.standing)' :key='i'>
          </div>
          <p>{{lodging.city.name}}, {{lodging.country.name}}</p>
        </div>
        <img :src="img" class="galerie-img" :alt="lodging.name">
      </div>
    </div>
    </a>
  </div>
</template>

<script>

export default {
    name: 'CardGaleries',
    props: ['lodging'],
    computed: {
      images() {
        const outsides = this.lodging.outside_urls
        const commons = this.lodging.common_part_urls
        return outsides.concat(commons)
      },
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/galerie'>

</style>