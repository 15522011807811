<template>
	<div id="experience-grid">
		<!-- <figure class='structure-galerie gallery-item'> -->
		<!-- <div class="gallery-item-1"> -->
		<img v-if="experience.photo_key" :src="experience.photo_key" class="gallery__img" :alt="experience.name" />
		<img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" class="gallery__img" :alt="experience.name" />
		<div class="card-content mt-3">
			<h2 class="category-exp merriweather-italic mt-4">
				{{ experience.name }}
			</h2>
			<div class="title-card d-flex">
				<h3 class="raleway-bold-p16 mr-4 mb-0">
					{{ experience.catch_phrase }}
				</h3>
				<div class="like-icon">
					<img src="../../images/wishlistOn.svg" v-set-alt v-if="isIncludeInWishlist()" @click="toggleLike()" />
					<img src="../../images/wishlist.svg" v-set-alt v-else @click="toggleLike()" />
				</div>
			</div>
			<p class="raleway-reg-p15 mt-4">{{ experience.description }}</p>
			<p class="raleway-bold">Où ?</p>
			<p class="raleway-reg-p15">{{ experience.address }}</p>
			<a :href="`/experiences/${experience.slug}`" class="link-hortense">Voir les hébergements à proximité</a>
		</div>
		<!-- </div> -->
		<!-- </figure> -->
	</div>
</template>
<script>
	import fetcher from 'packs/shared/api.js';
	import Vue from 'vue/dist/vue.esm';
	import VueLazyload from 'vue-lazyload';

	Vue.use(VueLazyload, {
		lazyComponent: true,
		preLoad: 1.3,
		attempt: 1,
	});

	export default {
		name: 'experience-grid',
		props: {
			experience: Object,
			categoryName: String,
			user: Object,
			wishlist: Array,
		},
		data() {
			return {
				wishlistExp: null,
			};
		},
		methods: {
			isIncludeInWishlist() {
				if (this.wishlistExp) {
					for (const experience of this.wishlistExp) {
						if (experience.id === this.experience.id) {
							return true;
						}
					}
				}
				return false;
			},
			async toggleLike() {
				try {
					if (this.user) {
						const response = await fetcher.patch('/wishlists/' + this.user.id, {
							experience_id: this.experience.id,
						});
						this.wishlistExp = response.data.experiences;
					} else {
						this.$root.$emit('openModal');
						window.scrollTo({
							top: 0,
							behaviour: 'smooth',
						});
					}
				} catch (e) {
					console.error(e);
				}
			},
		},
		watch: {
			experience: {
				handler(newVal) {
					this.experience = newVal;
				},
				immediate: true,
			},
			wishlist: {
				handler() {
					this.wishlistExp = this.wishlist;
				},
				immediate: true,
			},
		},
	};
</script>
