<template>
	<div id="multiselectbedding" class="mt2">
		<div class="dropdown dropdown-filter" @click="showDropdown">
			<div class="overselect"></div>
			<div class="c-form-input">
				<h2 class="raleway-semi-p13">Literie</h2>
				<img src="../../images/Fleche haut.svg" v-set-alt v-if="show" />
				<img src="../../images/flechebas-simple.svg" v-set-alt v-else />
			</div>
		</div>
		<div class="multiselect" v-if="show">
		    <input type="text" v-model="selected">
			<!--<ul>
				<div class="row row-selectoptions mt-3">
					<div class="col-10">
						<li>
							<input type="checkbox" id="Lit double - Standard" :value="['Lit double', 'Lit double - Standard', 'Standard', 'standard']" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="Lit double - Standard">Lit double - Standard</label>
						</li>
						<li>
							<input type="checkbox" id="Lit double - King size" :value="['Lit double', 'Lit double - King size', 'King-size', 'king-size']" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="Lit double - King size">Lit double - King size</label>
						</li>
						<li>
							<input type="checkbox" id="Lit double - Queen size" :value="['Lit double', 'Lit double - Queen size', 'Queen size', 'queen-size']" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="Lit double - Queen size">Lit double - Queen size</label>
						</li>
						<li>
							<input type="checkbox" id="Lit simple" value="Lit simple" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="Lit simple">Lit simple</label>
						</li>
						<li>
							<input type="checkbox" id="Lits jumeaux" value="Lits jumeaux" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="Lits jumeaux">Lits jumeaux</label>
						</li>
					</div>
				</div>
			</ul>-->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'multiselectbedding',
		props: ['options'],
		data() {
			return {
				show: false,
				selected: [],
			};
		},
		methods: {
			showDropdown() {
				this.show = !this.show;
			},
		},
		watch: {
			selected(val) {
				this.$emit('checked', val.flat(), 'beddings');
			},
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
