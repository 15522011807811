<template>
  <div id="evergreen-phone">
    <div class="phone-container">
      <div class="planete">
         <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/planete2.png" v-set-alt>
      </div>
        <div class="evergreen-header">
          <h2 class="merriweather-reg-24"> {{ $t("Evergreen.program") }} </h2>
          <p class="raleway-reg-p16">{{ $t("Evergreen.content") }}</p>
        </div>
        <!-- <splide :options="Evergreen">
          <splide-slide>
            <CardEverPhone></CardEverPhone>
          </splide-slide>
          <splide-slide>
            <CardEverPhone></CardEverPhone>
          </splide-slide>
          <splide-slide>
            <CardEverPhone></CardEverPhone>
          </splide-slide>
        </splide> -->
      <a class="hortense-btn-primary btn-evergreen raleway-bold-p" :href="'/programme-evergreen-demarche-environnementale/'">{{ $t("Evergreen.viewbtn") }}</a>
    </div>
  </div>
</template>


<script>

  import CardEverPhone from 'components/cards-phone/CardEverPhone.vue';
  // import { Splide, SplideSlide } from '@splidejs/vue-splide';


  export default {
    name: 'evergreenHome',
    components:{  CardEverPhone },
    data() {
      return {
        Evergreen: {
          arrows: 0,
          rewind : true,
          width  : '100%',
          height: 350,
          padding: {
            right: '4rem',
          },
          perPage: 1,
          perMove:1,
          gap    : '1rem',
          // pagination:,
        },
      };
    },
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/ever-phone'>

</style>
