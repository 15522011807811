<template>
  <div id="map-test" class="mt-5">
    <mapbox
        access-token="pk.eyJ1IjoiaG9ydGVuc2VncmVlbiIsImEiOiJja3N1Nm9iNGEwNjUyMm9xY2RlMDlwYWsxIn0.AMGi9L_80RYWk4sB5JYU5w"
        :map-options="{
        style: 'mapbox://styles/mapbox/streets-v10',
        zoom: 5,
        center: [this.markers[0][0], this.markers[0][1]],
      }"
        @map-init="displayMarkers"
    />
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl-vue';
export default{
  name: 'yoga-map',
  props: ['markers'],
  components: { Mapbox },
  data(){
    return{

    }
  },
  methods: {
    displayOneMarker(map, mk) {
      const marker = new mapboxgl.Marker()
          .setLngLat(mk)
          .addTo(map);
    },
    displayMarkers(map) {
      this.fitMapToMarkers(map, this.markers);
      this.markers.forEach((marker, index) => {
        let el = document.createElement('div');
        el.id = marker[2];
        el.addEventListener('click', (e) => {
          this.$emit('markerSelected', el.id);
          document.querySelectorAll('.marker').forEach(mk => {
            if (mk.id != el.id) {
              mk.style.display === 'none' ? mk.style.display = 'block' : mk.style.display = 'none';
            }
            else
            {
              console.log('selected marker');
              console.log(marker);
              map.flyTo({
                center: [marker[0], marker[1]],
                zoom: 5
              })
            }
          })
        })
        el.className = 'marker';
        new mapboxgl.Marker(el)
            .setLngLat([marker[0], marker[1]])
            .addTo(map);
      })
      map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
          })
      );
    },
    fitMapToMarkers(map, markers){
      const bounds = new mapboxgl.LngLatBounds();
      markers.forEach(marker => bounds.extend([ marker[0], marker[1] ]));
      map.fitBounds(bounds, { padding: 70, maxZoom: 10, duration: 0 });
    },
  },
}
</script>
<style lang="scss">

</style>
