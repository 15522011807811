<template>
  <div id="cardLodging" v-if="this.lodging">
    <div class="card-hub">
      <div class="position-relative">
        <div class="catch-hub" v-if='this.lodging.lodging_description.catch_sentence'>
          <p class="merriweather-italic">{{ this.lodging.lodging_description.catch_sentence }}</p>
        </div>
        <div class="catch-hub" v-else>
          <p class="merriweather-italic">Un havre aussi éco-responsable que luxueux</p>
        </div>
        <div class="pack-sticker">
          <div class="sticker-hub d-flex pl-3">
            <img :src="greenNotePicto((this.lodging.score).toFixed())" :alt="(this.lodging.score).toFixed()" v-if="this.lodging.score">
            <div class="sticker-info d-flex" v-if="this.lodging.score">
              <p class="raleway-reg-p13 mb-0 pl-1 pr-2"> <b>{{ (this.lodging.score).toFixed() }}</b>/10</p>
              <p class="raleway-reg-p13 mb-0 pl-1 pr-2">Green note</p>
            </div>
          </div>
        </div>
        <img class="hub-img" :src="formatSource()" :alt="this.lodging.name">
      </div>
      <div class="card-hub-info">
        <div>
          <div class="hub-header mt-3 d-flex">
            <h1 class="raleway-bold-h3 mb-0">{{this.lodging.name}}</h1>
            <div class="like-icon">
              <img src="../../images/wishlistOn.svg" v-set-alt v-if='isIncludeInWishlist()' @click='toggleLike()'>
              <img src="../../images/wishlist.svg" v-set-alt v-else @click='toggleLike()'>
            </div>
          </div>
          <div class="standing" v-if="parseInt(this.lodging.standing)">
            <img src="../../images/Etoiles.svg" v-set-alt v-for='i in parseInt(this.lodging.standing, 10)' :key='i'>
          </div>
          <div class="standing" v-else>
            <img src="../../images/Etoiles.svg" v-set-alt style="opacity: 0;">
          </div>
          <p class="description-hub raleway-reg-p13 mb-0">{{formatStyle(this.lodging.country.name)}}, région {{this.lodging.region.name}}</p>
          <div class="location-hub d-flex">
          </div>
          <div class="hub-reviews d-flex">
            <img class="mt-2 mr-2" src="../../images/avis.svg" v-set-alt>
            <div class="review-info d-flex">
              <div class="score-hub d-flex">
                <p class="raleway-reg-p13 mb-0 pl-1 pr-2"><b>{{this.lodging.lodging_description.rating_value || this.average}}</b>/10</p>
                <a :href="'/lodgings/'+ this.lodging.slug +'/review_list'" class="link-hortense"> {{this.lodging.lodging_description.review_count || this.ratedLodgings.length}} avis</a>
              </div>
              <p class="raleway-reg-p13">Note des voyageurs</p>
            </div>
          </div>
        </div>
      </div>
      <div style="height: 100%;display: flex;flex-direction: column;justify-content: flex-end;">
        <a :href="'/reservation-hotel/'+ this.lodging.slug" class="xs-btn-primary text-center">Voir les disponibilités</a>
      </div>
    </div>
  </div>
</template>

<script>
  import fetcher from 'packs/shared/api.js';
  export default{
    name: 'CardLodging',
    props: {
      lodging: {
        type: Object,
        required: true
      },
      user: {
        type: Object,
        default: {}
      }
    },
    data(){
      return{
        ratedLodgings: [],
        averageList:[],
        average: 0,
        wishlistLodgings: [],
      }
    },
    methods:{
      greenNotePicto(score){
        if (score === '4'){
          return require(`../../images/greennote 4.svg`)
        } else if (score === '5'){
          return require(`../../images/greennote 5.svg`)
        } else if (score === '6'){
          return require(`../../images/greennote 6.svg`)
        } else if (score === '7'){
          return require(`../../images/greennote 7.svg`)
        } else if (score === '8'){
          return require(`../../images/greennote 8.svg`)
        } else if (score === '9'){
          return require(`../../images/greennote 9.svg`)
        } else if (score === '10'){
          return require(`../../images/greennote 10.svg`)
        } else {
          return require("../../images/blanc.png")
        }
      },
      formatStyle(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      formatSource() {
        if (this.lodging.photo_banner) {
          return 'https://www.hortense.green' + this.lodging.photo_banner
        } else {
          return 'https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0=';
        }
      },
      async toggleLike() {
        try {
          if (this.user && Object.entries(this.user).length != 0) {
            const response = await fetcher.patch('/wishlists/'+ this.user.id, { lodging_id: this.lodgingMount.id });
            this.wishlistLodgings = response.data.lodgings;
          } else {
            this.$root.$emit('openModal');
            window.scrollTo({
              top: 0,
              behaviour: 'smooth'
            });
          }
        } catch(e) {
          console.error(e)
        }
      },
      ratedLodging(){
        this.ratedLodgings = this.lodging.reservations.filter(el => el.rated === true)
          return this.ratedLodgings
      },
      averageRate(){
        this.ratedLodgings.forEach((el) => {
          this.averageList.push(el.rate_average)
        })
        return this.averageList
      },
      averageLodging(){
        if(this.averageList != 0){
          this.average = (this.averageList.reduce((a, b) => a + b, 0) / this.averageList.length).toFixed(1);
          return this.average
        } else {
          return this.average = '-'
        }
      },
      isIncludeInWishlist() {
        return this.wishlistLodgings.map(l => l.id).includes(this.lodging.id)
      },
      async fetchWishlist() {
        try {
          if (this.user && Object.entries(this.user).length != 0) {
            const response = await fetcher.get('/wishlists');
            this.wishlistLodgings = response.data.lodgings
          }
        } catch(e) {
          console.error(e);
        }
      },
    },
    mounted() {
      this.fetchWishlist();
    },
  };
</script>

<style lang="scss" scope='app/assets/stylesheets/components/lodginghome-phone'>

</style>
