<template>
  <div id="menu-profil">
    <div class="menu-profil">
      <ul class="list-menu">
        <li class="menu-item raleway-reg-p13">
          <a :href="'/dashboard/' + this.user.slug">Mon Profil</a>
        </li>
        <li class="menu-item raleway-reg-p13">
          <a :href="'/reservations'">Mes réservations</a>
        </li>
        <li class="menu-item raleway-reg-p13 d-flex">
          <a href="#" @click="openDropdown" class="dropdown-toggle">Ma wishlist <img src="../../images/flechebas-simple.svg" v-set-alt></a>
          <div v-show="showDropdown" class="dropdown-menu">
            <a :href="'/wishlists?type=lodging'" class="dropdown-item">Mes Hébergements favoris</a>
            <a :href="'/wishlists?type=experience'" class="dropdown-item">Mes Expériences favorites</a>
          </div>
        </li>
        <li class="menu-item raleway-reg-p13">
          <a :href="'/privileges'">Programme de fidélité Hortensia</a>
        </li>
        <li class="menu-item raleway-reg-p13">
          <a :href="'/comments'">Mes commentaires</a>
        </li>
        <li class="menu-item raleway-reg-p13">
          <a :href="'/dashboard/' + this.user.slug + '/mes_envies'">Newsletters</a>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'menu-profil',
  props: ["user"],
  data() {
    return {
      showDropdown: false,
    };
  },
  methods: {
    openDropdown(event) {
      event.preventDefault();
      event.stopPropagation();
      this.showDropdown = !this.showDropdown;
    },
    closeDropdown() {
      if (this.showDropdown) {
        this.showDropdown = false;
      }
    },
    goToWishlistLodging() {
      window.location.href = "/wishlists/"
    }
  },
  mounted() {
    document.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
}
</script>
<style lang="scss" scope='app/assets/stylesheets/components/registration-user'>
.menu-item {
  position: relative;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  z-index: 1000;
  width: auto;
  padding: 5px 0;
  display: block;
}

.dropdown-item:hover {
  background-color: #f0f0f0;
}
</style>
