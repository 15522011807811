<template>
  <div id="ethic">
    <div class="container-index">
      <h1 class="merriweather-italic24 text-center mb-5 header">Avant votre départ, nous vous invitons à lire la Charte Éthique du voyageur. Cette charte présente les bonnes pratiques du voyage responsable afin de vous guider tout le long de votre séjour.</h1>
      <div class="img-whois-header">
        <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/Etiq-1.jpg" v-set-alt>
      </div>
      <p class="raleway-reg-p15 text-center mb-5">Hortense vous propose de découvrir des lieux authentiques, loin des circuits touristiques et des itinéraires tout tracés. Ces lieux sont tenus par des hommes et des femmes engagés qui contribuent tous les jours au développement durable et écologique de leur environnement. En faisant le choix de loger chez eux, vous les soutenez dans leur démarche et contribuez à être un voyageur responsable, attentif au monde, respectueux de la nature et du vivant. Découvrez les conseils d’Hortense pour devenir un acteur du tourisme responsable :</p>
      <div class="tip-item">
        <div class="number-tip">
          <h1 class="raleway-reg-h1">1</h1>
        </div>
        <h3 class="merriweather-reg-24 mb-2"> Privilégier une destination locale </h3>
      </div>
      <p class="raleway-reg-p15 pl-5 mb-5">Il existe mille et une façons de voyager. Vagabonder le long d’un littoral, traverser l’océan au bord d’un cinq mat, crapahuter au cœur d’un volcan, errer dans un désert saharien… Notre monde regorge de territoires inexplorés, de merveilles insoupçonnées. Mais parfois, ce désir insatiable de voyage nous fait oublier la chance que nous avons d’habiter un pays si riche en culture et en diversité. Alors que nous sommes nombreux à vouloir sortir de nos murs et élargir nos horizons, le voyageur responsable, lui fait le choix de poser ses bagages en terre connue et prend le temps de découvrir plus en profondeur la vie locale. Son objectif n’est plus de partir au bout du monde et d'effectuer des sauts de puces de villes en villes. Il s’autorise à prendre le temps. Le temps de s’éveiller à la nature, de découvrir la culture locale, d’apprécier les différents terroirs. Tout en célébrant cette philosophie de la lenteur, Hortense vous invite à (re) découvrir votre pays, dans ce qu’il a de plus beau et de plus précieux. Nos partenaires, hôtes et hôtesses vous accueillent le temps d’un week-end ou plus pour partager avec vous leur quotidien et vous faire découvrir leur lieu d’habitat. Ce séjour sera l’occasion de partir à la rencontre de locaux, de savourer les plaisirs de la table, et de vous imprégner pleinement de la nature. </p>
      <div class="tip-item">
        <div class="number-tip">
          <h1 class="raleway-reg-h1">2</h1>
        </div>
        <h3 class="merriweather-reg-24 mb-2"> Choisir un mode de transport durable </h3>
      </div>
      <p class="raleway-reg-p15 pl-5 mb-5">Nous partons en vacances de plus en plus loin, et de plus en plus souvent… Pourtant, nos déplacements ont un lourd impact sur l’environnement. En tant que voyageur, vous avez la possibilité de limiter l’empreinte de vos déplacements. Cela passe par des distances plus courtes et des voyages moins réguliers. Pour vous permettre de voyager sans compromettre votre budget carbone, Hortense vous propose une sélection d’hébergements proches, accessibles en quelques heures seulement. Pour vous y rendre, nous vous recommandons d’emprunter un mode de transport propre et économe en énergie. La voiture et le train sont à privilégier ! Si toutefois vous n’avez d’autre solution que de prendre l’avion, vous pouvez limiter votre empreinte par la compensation carbone. Via un organisme privé ou directement par le site de la compagnie aérienne, vous pouvez compenser les émissions de votre vol en contribuant financièrement à un projet de protection du climat.</p>
      <div class="tip-item">
        <div class="number-tip">
          <h1 class="raleway-reg-h1">3</h1>
        </div>
        <h3 class="merriweather-reg-24 mb-2">Faire vivre l’économie locale</h3>
      </div>
      <p class="raleway-reg-p15 pl-5 mb-5">Le tourisme durable est un tourisme qui contribue au développement économique et culturel des territoires ainsi qu’au développement humain des populations qui y vivent. Pour que nos séjours s’inscrivent dans une logique de développement des territoires, nous encourageons les voyageurs à participer aux activités proposées sur place et à se restaurer dans les commerces et restaurants locaux. Cette action favorise l’emploi et l’économie locale et contribue à l’amélioration des communautés visitées, notamment pas une répartition équitable des ressources touristiques. Considérer le développement durable, c’est aussi contribuer au développement local !</p>
      <div class="img-whois-header">
        <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/Etiq-2.jpeg" v-set-alt>
      </div>
      <div class="tip-item">
        <div class="number-tip">
          <h1 class="raleway-reg-h1">4</h1>
        </div>
        <h3 class="merriweather-reg-24 mb-2">Adopter une routine zéro déchets, même en vacances. </h3>
      </div>
      <p class="raleway-reg-p15 pl-5 mb-5">Les vacances nous amènent souvent à produire davantage de déchets que dans notre quotidien. Entre les emballages alimentaires, les bouteilles d’eau, et les échantillons de beauté, tous ces déchets finissent par peser lourd dans la balance ! Chez Hortense, nous sommes particulièrement attentifs à ce que les séjours que nous organisons aient une empreinte positive sur les lieux visités. C’est pourquoi nous encourageons les voyageurs à adapter leur consommation, de sorte à limiter le plus possible leur production de déchets jetables. Si vous n’en avez pas déjà un, vous pouvez vous constituer un kit zéro-déchet avant de partir, et le garder à portée de main tout le long du voyage. Dans un sac en tissu, mettez une gourde en inox, un gobelet, des couverts, une serviette lavable. Ils vous seront utiles dès que vous planifierez un déjeuner sur le pouce ! Aussi, nous vous conseillons d’emporter avec vous le strict nécessaire et de limiter les achats lors de votre séjour. Sur place, le meilleur moyen de consommer sans produire de déchet est d’acheter des produits locaux et frais, et aller au restaurant plus souvent !</p>
      <div class="tip-item">
        <div class="number-tip">
          <h1 class="raleway-reg-h1">5</h1>
        </div>
        <h3 class="merriweather-reg-24 mb-2">Compenser son empreinte carbone en soutenant des projets concrets de développement durable.</h3>
      </div>
      <p class="raleway-reg-p15 pl-5 mb-5">Le voyageur responsable manifeste son engagement pour l’environnement et le social par un ensemble d’actions et d'initiatives vertueuses. Il pourra faire le choix de s’engager au sein d’une ONG ou réaliser des missions d’éco volontariat dans le but d’aider les populations les plus fragiles. Afin de permettre à ses clients d’avoir un impact positif sur le monde, et de réduire l’empreinte environnementale de ses hébergements partenaires, <em>Hortense</em> a créé le programme <em>Evergreen</em>. À chaque réservation effectuée, 1% du tarif de la réservation d’une nuitée est reversé pour financer des projets de protection et de sensibilisation à l'environnement et des actions écocitoyennes portés par ses hébergements partenaires.</p>
      <div class="tip-item">
        <div class="number-tip">
          <h1 class="raleway-reg-h1">6</h1>
        </div>
        <h3 class="merriweather-reg-24 mb-2">S’imprégner de la culture ambiante en privilégiant les échanges avec la population locale</h3>
      </div>
      <p class="raleway-reg-p15 pl-5 mb-5">Le voyage, c’est avant tout la rencontre de deux mondes. Celui du voyageur et des populations hôtes. Pour que cette rencontre soit un moment de partage et d’échange, il est important que le voyageur puisse s’ouvrir à la population locale et agisse dans le respect de la culture et des traditions. <em>Chez Hortense</em>, nous considérons que la vraie richesse de nos séjours se trouve dans le partage d’instants de vie avec les populations locales. Cette plongée inédite au cœur des traditions est une phase nécessaire pour apprendre à vivre en communion avec les populations. Elle vous permettra de comprendre les différences entre les cultures, et d’acquérir des bons réflexes pour vivre en harmonie avec cet environnement nouveau.</p>
      <div class="img-whois-header">
        <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/Etiq-3.jpg" v-set-alt>
      </div>
      <div class="tip-item">
        <div class="number-tip">
          <h1 class="raleway-reg-h1">7</h1>
        </div>
        <h3 class="merriweather-reg-24 mb-2">Préserver les ressources naturelles locales et privilégier des destinations respectueuses de l’environnement.</h3>
      </div>
      <p class="raleway-reg-p15 pl-5 mb-5">Le tourisme durable repose sur le fait d’organiser un séjour ayant une incidence minime sur l’environnement. Afin de limiter son empreinte, le voyageur privilégiera des destinations peu fréquentées et préservées du tourisme et préférera les sites naturels ou les villes tournées vers le tourisme vert. À travers ces choix, l’objectif est de contribuer à la préservation du patrimoine naturel et de soutenir le développement durable de ces régions. En outre, selon son choix de destination, il pourra contribuer au développement d’alternatives durables, comme l’agriculture biologique ou paysanne, l’élevage extensif, la pêche raisonnée, ou même contribuer à l'amélioration de l’efficacité énergétique du territoire. Le visiteur a également un rôle clef dans la sauvegarde des écosystèmes et des milieux fragiles. À ce titre, il doit se montrer responsable et s’assurer que son passage n’impacte en aucun cas les sites naturels visités.</p>
      <div class="tip-item">
        <div class="number-tip">
          <h1 class="raleway-reg-h1">8</h1>
        </div>
        <h3 class="merriweather-reg-24 mb-2">Opter pour un hébergement éco-responsable</h3>
      </div>
      <p class="raleway-reg-p15 pl-5 mb-5">Afin d’agir pour un tourisme plus durable, il est nécessaire de privilégier un hébergement responsable, respectueux de l’environnement social et naturel dans lequel il se trouve. Par définition, l’hébergement éco-responsable est un type d'hébergement touristique écologiquement responsable. Ces habitations se distinguent par leurs pratiques éco-responsables simples, visant à réduire les impacts de leur activité sur l’environnement. Elles contribuent à protéger les espaces naturels, et ont été pensées en accord avec les principes du développement durable.</p>
      <div class="img-whois-header">
        <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/Etiq-4.jpeg" v-set-alt>
      </div>
      <p class="raleway-it-l15">En tant qu’acteur du tourisme durable, Hortense se donne pour mission de promouvoir les hébergements éco-responsables. Aussi, notre choix s’est porté sur des hébergements de charme, lieux d’accueil authentiques et singuliers, pour vous offrir une immersion totale et une expérience inoubliable : un hôtel étoilé en pleine nature landaise, une écolodge en Corrèze, une table d’hôte dans un corps de ferme normand… Dans ce cadre exceptionnel, vous pourrez apprécier le charme et le confort des hébergements écologiques haut-de-gamme.</p>
      <div class="box">
        <p class="raleway-bold">Hortense et son engagement environnemental</p>
        <p class="raleway-bold-p">Green Host</p>
        <p class="raleway-reg-p15 mb-3">Dans l’objectif de participer activement au développement de ces alternatives durables, <em>Hortense</em> a créé <em>Green Host</em>, une marque, qui vise à promouvoir nos hébergements partenaires en faisant reconnaître leur engagement. Le label <em>Green Host</em> certifie que les établissements concernés sont engagés dans la préservation de l’environnement et, en particulier, qu’ils mènent une politique de gestion efficace de l’énergie, de l’eau et des déchets, et qu’ils assurent la préservation du site.</p>
        <p class="raleway-bold-p">Green Note</p>
        <p class="raleway-reg-p15 mb-3">Les critères essentiels et communs à tous pour répondre à la charte <em>Green Host</em> ont été établis par un scoring environnemental, développé par Hortense : <em>La Green Note</em>. Cet outil est basé sur un questionnaire reprenant 150 indicateurs d’éco-responsabilité répartis en 10 critères. La Green Note permet d’attribuer à chacun de nos hébergements partenaires une note attestant de leurs niveaux d’engagement environnemental et nous apporte une clé de lecture pour mieux comprendre les enjeux liés à l’habitat durable. Grâce à ce système, nous souhaitons améliorer la performance environnementale et économique du secteur, proposer des outils pragmatiques basés sur des fondamentaux techniques, et partager et diffuser les connaissances au plus grand nombre. <br> Enfin, la <em>Green Note</em> permet de déterminer le montant des commissions pour chaque hébergement. Nous proposons à nos partenaires un taux de commission équitable basé sur des critères objectifs sur lequel ils peuvent agir : plus leur bilan environnemental est positif, plus le taux de commission est bas !</p>
        <p class="raleway-bold-p">Programme Evergreen</p>
        <p class="raleway-reg-p15"><em>Hortense</em> identifie et finance des projets écologiques destinés à réduire l’empreinte environnementale de ses hébergements partenaires. Pour chaque réservation effectuée, elle reverse un pourcentage des commissions qu’elle reçoit à ses hébergements partenaires. <em>Evergreen</em> est un programme de financement solidaire créé par Hortense destiné à soutenir ses hébergements partenaires dans leur transition écologique et la réduction de leur impact carbone.</p>
      </div>
      <p class="raleway-reg-p13">“L’ensemble des acteurs du secteur de l’hôtellerie et plus largement les hébergements touristiques doivent agir pour générer un véritable impact positif. Nous souhaitons valoriser les hébergements respectueux de l’environnement et les accompagner dans la mise en place d’une démarche environnementale.”</p>
      <p class="raleway-bold-p15">Aurore Sterling, Fondatrice d’Hortense</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ethic',
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/static-pages'>
</style>
