<template>
  <div id="exclusive">
    <div class="card-container">
      <div class="row row-hubs">
        <div class="col-4">
          <div class="exclusive-header">
            <h2 class="merriweather-reg-26"> Nos </h2>
            <h2 class="merriweather-reg-40 mb-5">Offres exclusives</h2>
            <p class="raleway-reg-p16">Voyager différemment grâce à Hortense. Hortense vous offre la possibilité de faire de votre voyage plus qu'un simple souvenir, une expérience dont vous parlerez grâce à nos offres exclusives réservées aux membres du programme de fidélité Hortensia.</p>
          </div>
            <a class="hortense-btn-primary raleway-bold-p mt-5" :href="'/offres-exclusives'">{{ $t("Exclusive.viewbtn") }}</a>
        </div>
        <div class="col-8">
          <div class="row row-exclusive">
            <div v-for='exclusive,index in exclusivesMounted' class="col-6 col-exclusive">
              <CardExclusive :exclusive="exclusive" v-if='index === 0' data-aos="fade-up" data-aos-duration="900"></CardExclusive>
              <CardExclusive :exclusive="exclusive" v-else-if='seeMore(index)' data-aos="fade-up" data-aos-duration="900"></CardExclusive>
            </div>
          </div>
          <div class="pagination-hub d-flex mt-3">
            <a href="#" @click='SeeMoreExclusive' class="link-hortense"> voir plus <img src="../images/flechebas-simple.svg" v-set-alt></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import AOS from 'aos';
  import CardExclusive from 'components/cards/CardExclusive.vue';
  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'exclusive',
    components: { CardExclusive },
    props: {
      exclusives: Array
    },
    data() {
      return {
         exclusivesMounted: this.exclusives,
         numberOfVisibleExclusive: 4,
      }
    },
    methods: {
      seeMore(index) {
        return index < this.numberOfVisibleExclusive;
      },
      SeeMoreExclusive(e) {
        e.preventDefault();
        this.numberOfVisibleExclusive += 3;
      },
      SeeLessExclusive(e) {
        e.preventDefault();
        this.numberOfVisibleExclusive -= 3;
      },
    },
    mounted() {
      AOS.init();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/exclusive'>

</style>
