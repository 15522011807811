<template>
  <div id="france-card">
    <div class="row row-desti-map">
      <div class="col-lg-6 col-xs-6 col-map">
        <France @goToCard='redirectToCard' :destination='destinations[0]' v-if='destinations'></France>
       <!-- <img src="../../images/france (1).svg" v-set-alt> -->
      </div>
      <div class="col-lg-4 col-xs-10 col-mapcontent">
        <h2 class="raleway-reg-p24 mb-4"  style="text-align: center;"> La France </h2>
        <p class="raleway-reg-p15 mb-4" style="text-align: center;">{{ destinations[0].content_destination }}</p>
        <!-- <div class="link-video mb-4">
          <img src="../../images/play video.svg" v-set-alt>
          <a href="#" class="link-hortense">Voir la video</a>
        </div> -->
        <template v-if='destinations'>
          <a v-if='lodgingCount !== 0' :href="'/destination/France'" class="hortense-btn-primary btn-desti mb-4">{{ lodgingCount }} établissements</a>
          <a v-if='lodgingCount === 0' :href="'/destination/France'" class="hortense-btn-primary btn-desti mb-4">DÉCOUVRIR</a>
          <!-- <div class="info-loca">
            <img src="../../images/Localisation.svg" v-set-alt>
            <p class="raleway-reg-p13">{{ lodgingCount }} Nombre d’établissements</p>
          </div> -->
        </template>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-6 col-xs-10 offset-xs-1 offset-lg-3 mt-5">
        <p class="merriweather-italic text-center">Participez au développement du tourisme durable en privilégiant des destinations respectueuses de l’environnement, des ressources naturelles et de l’économie locale.​
        </p>
      </div>
    </div>
    <div v-if="destinations" v-for='(destination, ind) in destinations' :key='ind'>
      <CardRegion class="card-region" :index="index" :region="region" :id="region.name" v-for="(region, index) in destination.regions" :key='index' data-aos="fade-up" data-aos-duration="900"></CardRegion>
    </div>
  </div>
</template>

<script>

  import France from 'components/destinations/France.vue';
  import CardRegion from 'components/cards/CardRegion.vue';
  import AOS from 'aos';

  export default {
    name: 'france-card',
    components: {  France, CardRegion },
    props: {
      destinations: Array
    },
    data() {
      return {
        lodgingsCount: 0,
      }
    },
    methods: {
      redirectToCard(name) {
        const map = {
          'ô': 'o',
          'é': 'e',
          'è': 'e',
          'ê': 'e',
          'à': 'a',
          'ù': 'u',
          'ç': 'c'
        };

        const formattedName = name
            .toLowerCase()
            .replace(/[^\w\s-]/g, function(match) {
              return map[match] || match;
            })
            .replace(/\s+/g, '-');

        window.open(`/destination/${formattedName}`, '_blank');
      },
      scrollToMe(name) {
        const elementFound = this.$children.find(e => e.$el.id === name);
        const topEl = elementFound.$el.offsetTop;

        window.scrollTo(0, topEl);
      },
    },
    mounted(){
      AOS.init();
    },
    computed: {
      lodgingCount() {
        this.destinations.forEach((destination) => {
          const lodging = destination.get_lodgings.length
          this.lodgingsCount += lodging
        })
        return this.lodgingsCount
      },
    }
  }
</script>
