<template>
  <div id="reassurance" style="margin-top: 12px !important;">
    <div class="container-label">
      <div class="row row-reas justify-content-center">
        <div class="col-2 col-reas">
          <img style="max-width: 129px;max-height: 129px;" src="../../images/bar_one.png" v-set-alt>
          <p style="font: normal normal normal 16px/20px Raleway;" class=" text-center"> Bien-être & Nature </p>
        </div>
        <div class="col-2 col-reas ml-4">
          <img style="max-width: 129px;max-height: 129px;" src="../../images/bar_two.png" v-set-alt>
          <p style="font: normal normal normal 16px/20px Raleway;" class=" text-center">Convivialité & Rencontre</p>
        </div>
        <div class="col-2 col-reas ml-4">
          <img style="max-width: 129px;max-height: 129px;" src="../../images/bar_three.png" v-set-alt>
          <p style="font: normal normal normal 16px/20px Raleway;" class=" text-center">Des activités pour tout le monde</p>
        </div>
        <div class="col-2 col-reas ml-4">
          <img style="max-width: 129px;max-height: 129px;" src="../../images/bar_four.png" v-set-alt>
          <p style="font: normal normal normal 16px/20px Raleway;" class=" text-center">Respect de l'environnement</p>
        </div>
        <div class="col-2 col-reas ml-4">
          <img style="max-width: 129px;max-height: 129px;" src="../../images/bar_five.png" v-set-alt>
          <p style="font: normal normal normal 16px/20px Raleway;" class=" text-center">Cuisine végétarienne</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default{
  name: 'ClubExpBar',
}
</script>


<style lang="scss" scope='app/assets/stylesheets/components/reassurance'>

</style>
