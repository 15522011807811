<template>
  <div id="hortensia">
    <div class="container-index">
      <h1 class="merriweather-reg-40 text-center header">Le programme de fidélité Hortensia</h1>
      <div class="row row-fid">
        <div class="col-lg-7 col-xs-9 fid-content">
          <div class="header-fid">
            <h2 class="merriweather-italic24"> Agissez pour le développement du tourisme durable tout <br>en profitant de privilèges sur votre séjour.</h2>
            <p class="raleway-reg-p15 mb-5">Négociés avec les hébergements partenaires, de nombreux avantages comme le surclassement, l’accès gratuit au spa, le petit-déjeuner offert ou encore le check-in tardif seront disponibles en fonction de votre statut de fidélité.</p>
          </div>
            <button v-if="this.privilegeRegistration" @click="createPrivilege" class="hortense-btn-primary btn-fid mb-4">{{ $t("Fid.viewbtn") }}</button>
            <p v-else-if="this.privilegeRegistration === false" class="raleway-reg-p15 mb-4">Vous êtes inscrit</p>
            <button v-else @click='openModal'class="hortense-btn-primary btn-fid mb-4">{{ $t("Fid.viewbtn") }}</button>
        </div>
        <div class="col-lg-5 col-xs-11 fid-badges">
          <img src="../images/badge-guest.png" class="guest" v-set-alt>
          <img src="../images/badge-nomad@2x.png" v-set-alt class="nomad">
          <img src="../images/badge-trotter.png" v-set-alt class="trotter">
        </div>
      </div>

      <div class="row row-fid-points">
        <div class="col-lg-7 col-xs-9 offset-lg-3 offset-xs-2">
          <h3 class="raleway-reg-p24 mb-5">Comment cumuler des points de fidélité Green Points ?</h3>
          <p class="raleway-reg-p15 mt-4 mb-5">Chaque réservation sur Hortense vous fait gagner des points de fidélité Green Points.</p>
        </div>
        <div class="col-3 offset-2 mt-4">
          <div class="fid-item">
            <h1 class="raleway-reg-p58">1</h1>
            <p class="raleway-reg-p15 text-center">Nuit réservée</p>
          </div>
        </div>
        <div class="col-1 equal">
          <p class="raleway-bold-p33">=</p>

        </div>
        <div class="col-3 mt-4">
          <div class="fid-item">
            <h1 class="raleway-reg-p58">50</h1>
            <p class="raleway-reg-p15">Points</p>
          </div>
        </div>
      </div>
      <br></br>
      <br/></br>
      <br/></br>
      <p class="merriweather-italic24">
        Voyagez comme un invité privilégié avec Hortense. 
        Un accueil exceptionnel vous sera <br>accordé chez tous nos hébergements partenaires avec des avantages exclusifs sur toutes <br> les réservations effectuées sur notre site à partir du statut Green Trotter.
      </p>
      <div class="card-statut">
        <div class="row row-statut">
          <div class="col-lg-4 col-xs-9 statut-content">
            <p class="raleway-reg-p24 mb-5">Les statuts du programme Hortensia</p>
            <p class="raleway-reg-p15">Tous les 500 points, vous atteignez un <br>nouveau statut de fidélité qui vous <br>permet d’accéder à de nouveaux <br>privilèges, avantages² et cadeaux.</p>
            <br/>
            <br/>
          </div>
          <div class="col-lg-2 col-xs-6 col-statut">
            <img src="../images/badge-guest.png" class="guest statut-img" v-set-alt>
            <h3 class="raleway-bold-p">Green Trotter</h3>
            <p class="raleway-reg-p13">500 points soit 10 nuits réservées</p>
            <div>
              <i class="fas fa-check"><p style="display: inline; font-style: italic" class="raleway-reg-p13">Service Exclusif 1</p></i>
            </div>
            <br/>
            <br/>
            <br/>
          </div>
          <div class="col-lg-2 col-xs-6 col-statut-trotter">
            <img src="../images/badge-member.png" class="guest statut-img" v-set-alt>
            <h3 class="raleway-bold-p">Green Trotter <br>Plus</h3>
            <p class="raleway-reg-p13">1 000 points soit 20 nuits réservées</p>
            <div>
              <i class="fas fa-check"><p style="display: inline; font-style: italic" class="raleway-reg-p13">Service Exclusif 1</p></i>
            </div>
            <div>
              <i class="fas fa-check"><p style="display: inline; font-style: italic" class="raleway-reg-p13">Service Exclusif 2</p></i>
            </div>
            <br/>
            <br/>
          </div>
          <div class="col-lg-2 col-xs-6">
            <img src="../images/badge-nomad@2x.png" class="nomad statut-img" v-set-alt>
            <p class="raleway-bold-p">Green Trotter <br>Premium</p>
            <p class="raleway-reg-p13">1 500 points soit 30 nuits réservées</p>
            <div>
              <i class="fas fa-check"><p style="display: inline; font-style: italic" class="raleway-reg-p13">Service Exclusif 1</p></i>
            </div>
            <div>
              <i class="fas fa-check"><p style="display: inline; font-style: italic" class="raleway-reg-p13">Service Exclusif 2</p></i>
            </div>
            <div>
              <i class="fas fa-check"><p style="display: inline; font-style: italic" class="raleway-reg-p13">Service Exclusif 3</p></i>
            </div>
            <br/>
          </div>
          <div class="col-lg-2 col-xs-6">
            <img src="../images/badge-trotter.png"  class="trotter statut-img" v-set-alt>
            <p class="raleway-bold-p">Green Trotter <br>Infinite</p>
            <p class="raleway-reg-p13">2 500 points soit 50 nuits réservées</p>
            <div>
              <i class="fas fa-check"><p style="display: inline; font-style: italic" class="raleway-reg-p13">Service Exclusif 1</p></i>
            </div>
            <div>
              <i class="fas fa-check"><p style="display: inline; font-style: italic" class="raleway-reg-p13">Service Exclusif 2</p></i>
            </div>
            <div>
              <i class="fas fa-check"><p style="display: inline; font-style: italic" class="raleway-reg-p13">Service Exclusif 3</p></i>
            </div>
            <div>
              <i class="fas fa-check"><p style="display: inline; font-style: italic" class="raleway-reg-p13">Service Exclusif 4</p></i>
            </div>
          </div>
        </div>
      </div>

      <div class="how-to">
        <div class="row row-how">
          <div class="col-lg-7 col-xs-9 offset-lg-3 offset-xs-2">
            <h3 class="raleway-reg-p24 mb-5">Comment utiliser mes points ?</h3>
            <p class="raleway-reg-p15 mb-5">Les points sont échangeables contre des nuits offertes : les Green Nights³. Vous pouvez <br>réduire le montant total de votre séjour en utilisant vos points, directement depuis l’espace <br>de réservation. </p>
            <p class="raleway-semi-p15">Dès 500 points cumulés :</p>
            <ul>
              <li>Vous obtenez le premier statut de fidélité du programme, le statut Green Trotter, qui vous fait profiter de premiers avantages.</li>
            </ul>
            <p class="raleway-semi-p15">Dès 2500 points cumulés :</p>
             <ul>
              <li>Hortense vous offre une nuit d’une valeur de 100€ sous forme de carte cadeau </li>
              <li>Hortense vous offre une nuit toutes les tranches de 500 points sous forme de bon Green Night³ </li>
              <li>Vous pouvez échanger votre cagnotte contre 1 nuit offerte⁴. La valeur d’une nuit offerte est fixée à 100€.</li>
            </ul>
          </div>
        </div>
       </div>

      <div class="row row-how-p">
        <div class="col-3 mt-4">
          <div class="fid-item">
            <h1 class="raleway-reg-p58">500</h1>
            <p class="raleway-reg-p15">Points</p>
          </div>
        </div>
        <div class="col-1 equal">
          <p class="raleway-bold-p33">=</p>

        </div>
        <div class="col-3 mt-4">
          <div class="fid-item">
            <h1 class="raleway-reg-p58">1</h1>
            <p class="raleway-reg-p15">Nuit offerte</p>
          </div>
        </div>
        <div class="col-1 equal">
          <p class="raleway-bold-p33">=</p>

        </div>
        <div class="col-3 mt-4">
          <div class="fid-item">
            <h1 class="raleway-reg-p58">100</h1>
            <p class="raleway-reg-p15">Euros</p>
          </div>
        </div>
      </div>
    </div>
    <div class="advantages">
      <div class="col-8 offset-1">
        <h3 class="raleway-reg-p24 mb-4">Les avantages Hortense</h3>
        <p class="raleway-reg-p15">Profitez de ces privilèges en fonction de votre statut de fidélité. Négociés avec les hébergements partenaires, ils peuvent être différents d’un hôtel à l’autre :</p>
      </div>
      <div class="row row-advantage">
        <div class="col-sm col-3">
          <p class="raleway-reg-p15">Surclassement de chambre* </p>
          <p class="raleway-reg-p15">Check-in avancé</p>
          <p class="raleway-reg-p15">Check-out tardif</p>
          <p class="raleway-reg-p15">Petit déjeuner offert </p>
          <p class="raleway-reg-p15">Accès gratuit au spa </p>
          <p class="raleway-reg-p15">Accès gratuit à la thalasso</p>
          <p class="raleway-reg-p15">Un massage offert</p>
          <p class="raleway-reg-p15">Un dîner offert </p>
          <p class="raleway-reg-p15">Un dîner bistronomique offert</p>
          <p class="raleway-reg-p15">Un dîner gastronomique offert</p>
        </div>
        <div class="col-sm col-3">
          <p class="raleway-reg-p15">Remise sur la restauration</p>
          <p class="raleway-reg-p15">Remise sur la carte des boissons</p>
          <p class="raleway-reg-p15">Cadeau de bienvenue</p>
          <p class="raleway-reg-p15">Green fee offert</p>
          <p class="raleway-reg-p15">Dégustation de vins</p>
          <p class="raleway-reg-p15">1 nuit offerte si 7 nuits consécutives réservées</p>
          <p class="raleway-reg-p15">1 nuit offerte si 14 nuits consécutives réservées</p>
          <p></p>
          <p></p>
          <br/>
          <p style="color: #A0A0A0" class="ml-5"><i>Cette liste est non exhaustive*</i></p>
        </div>
        <div class="col-2 col-img-bg">
          <img src="../images/badge-guest@2x.png" class="guest-bg" v-set-alt>
        </div>
      </div>
    </div>

    <div class="support">
      <div class="row row-support ml-2">
        <div class="col-lg-7 col-md-10 offset-1">
          <div class="head-support">
            <h3 class="raleway-reg-p24 mb-4">Les offres exclusives</h3>
          </div>
          <div class="content-support">
            <p class="raleway-reg-p15 mb-4">
              Devenez un membre du programme de fidélité Hortensia et bénéficiez de tarifs confidentiels, d’attentions particulières et d’avantages négociés. 
              <br></br>
              Vos séjours auront une saveur particulière grâce à des avantages exceptionnels qu’ils soient sur place à l’hôtel ou après votre séjour. 
              Profitez de cadeaux uniques tout en vivant des expériences de voyage mémorables avec Hortense. 
              <br></br>
              Hortense vous offre des cadeaux uniques s’inspirant de vos envies et de vos passions et ayant vocation à récompenser votre passion du voyage éco-responsable.
            <p>
            <a style="width:330px" class="hortense-btn-primary raleway-bold-p mt-5" href="/offres-exclusives">DÉCOUVREZ NOS OFFRES EXCLUSIVES</a>
          </div>
        </div>
    </div>
    <div class="support">
      <div class="row row-support">
        <div class="col-lg-7 col-md-10 offset-1">
          <div class="head-support">
            <h3 class="raleway-reg-p24 mb-4">Soutenir le développement du tourisme durable grâce aux Programmes Hortensia et Evergreen</h3>
            <p class="raleway-reg-p15 mb-4">Hortense soutient financièrement le développement de projets du tourisme durable en reversant une partie de sa commissions aux porteurs de projets sélectionnés.</p>
            <a :href="'/programme-evergreen-demarche-environnementale/'" class="link-hortense">En savoir + sur le programme Evergreen</a>
          </div>
          <div class="content-support">
            <h3 class="raleway-reg-p24">Vous souhaitez agir activement pour le tourisme durable ?</h3>
            <p class="raleway-reg-p15">Hortense vous propose de contrebalancer symboliquement l’émission de CO² de votre voyage en participant au financement des projets éco-responsables portés par nos hébergements partenaires. <br><br>En versant la somme compensatoire de votre choix, gagnez des points de fidélité, des cadeaux et des avantages² sur votre séjour. <br><br>10 € reversés = 50 points de fidélité <br>100 € reversés = 500 points de fidélité = 1 nuit offerte³ <br>même si le statut Green Trotter n’est pas encore atteint.</p>
            <button v-if="this.privilegeRegistration" @click="createPrivilege" class="hortense-btn-primary btn-fid mb-4">{{ $t("Fid.viewbtn") }}</button>
            <p v-else-if="this.privilegeRegistration === false" class="raleway-reg-p15 mb-4">Vous êtes inscrit</p>
            <button v-else @click='openModal'class="hortense-btn-primary btn-fid mb-4">{{ $t("Fid.viewbtn") }}</button>
            <a href="/pages/cgu" class="link-hortense">Conditions d’utilisation du programme de fidélité Hortensia</a>
          </div>
          <div class="content-support mt-4">
            <h3 class="raleway-reg-p24">Le parrainage Hortense </h3>
            <p class="raleway-reg-p15">Le parrainage Hortense vous rapporte des points de fidélité ! Invitez vos proches à rejoindre <br> <br>Hortense et gagnez 10 points de fidélité lors de leur première réservation sur la plateforme. </p>
            <p class="raleway-reg-p15">En plus, nous offrons à vos filleuls 10 points de fidélité de bienvenue, lors de leur inscription.</p>
            <p class="raleway-reg-p15">Vous retrouverez votre liste de filleuls et vos avantages dans votre compte personnel.</p>
            <p class="raleway-reg-p15">Profitez du parrainage Hortense pour faire grandir la communauté de voyageurs engagés.</p>
            <button class="hortense-btn-primary btn-fid mb-4">Je parraine mes proches</button>
          </div>
          <div class="nb-support">
            <p class="raleway-reg-p13">¹ Sous réserve de souscription au programme de fidélité. <br>² Négociés avec les établissements partenaires d’Hortense. <br>³ Bon de 100 euros à utiliser dans les 12 mois dans l’un des hébergements du programme Evergreen. En cas de non <br>utilisation, le solde de la cagnotte sera réduit de 500 points, c’est-à-dire de la valeur du bon. <br>⁴ Dans la limite de 2 nuits par séjour.</p>
          </div>
        </div>
        <div class="col-lg-4 col-xs-3 img-bg">
          <img src="../images/badge-trotter@2x.png" class="trotter-bg" v-set-alt>
        </div>
      </div>
    </div>
    <Flash :text='this.text' :status='this.status' @destroy='toggleStatus(status)'></Flash>
  </div>
</template>


<script>

  import Flash from 'alert/Flash.vue';
  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'hortensia',
    props: [ 'user' ],
    components : { Flash },
    data() {
      return {
        status: null,
        text: null,
        privilegeRegistration: null,
      }
    },
    methods: {
      openModal() {
        this.$root.$emit('openModal')
      },
      async createPrivilege(){
        try {
          const response = await fetcher.post('/privileges');
          this.text = "Inscription au prpgramme de fidélité";
          this.toggleStatus('success');
          location.reload();
        }catch(e){
          this.toggleStatus('alert');
          this.text = 'Veuillez réessayer';
        }
      },
      toggleStatus(value="success") {
        this.status = this.status === value ? null : value
      },
      privilegeCondition(){
        return this.privilegeRegistration = this.user && this.user.privilege_id === null
      },
    },
    mounted() {
      this.privilegeCondition();
    },
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/hortensia'>

</style>
