<template>
  <div id="banner">
    <video
      autoplay
      loop
      muted
      poster="../../images/hortense-green.jpg"
      class="home_video"
      @canplay="handleVideoPlay"
    >
      <source src="https://hortense-vue.s3.eu-west-3.amazonaws.com/videos/Hortense-banner-video.mp4">
    </video>
    <div class="banner-container">
      <div class="banner-text">
        <h1 class="banner-text--title"> {{ $t("Banner.header") }} </h1>
        <p class="banner-text--para">{{ $t("Banner.subtitle") }}</p>
      </div>
      <div class="banner-search">
        <MainSearch></MainSearch>
      </div>
    </div>
    <div class="logo-see-more" @mouseover="animLogo" @click="scrollDown">
      <img src="../../images/fleche blanc.svg" v-set-alt>
    </div>
  </div>
</template>


<script>
import MainSearch from "../btns/MainSearch.vue";

export default {
  name: "banner",
  components: {
    MainSearch,
  },
  data() {
    return {
      search: "",
    };
  },
  methods: {
    handleVideoPlay() {
      const video = this.$el.querySelector(".home_video");
      video.classList.add("fade-in-video"); // Add fade-in class when video starts
    },
    animLogo(e) {
      e.currentTarget.animate(
        [
          { transform: "translateY(0px)" },
          { transform: "translateY(-10px)" },
          { transform: "translateY(0px)" },
        ],
        {
          duration: 800,
          easing: "ease-in-out",
          iterations: 2,
        }
      );
    },
    scrollDown() {
      this.$el.nextElementSibling.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>


<style lang="scss" scope='app/assets/stylesheets/components/banner'>
.home_video {
  position: absolute;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  height: auto;
  width: auto;
  background-size: cover;
  object-fit: cover;
  opacity: 0; // Initially hidden
  transition: opacity 1s ease-in-out; // Smooth fade-in transition
}

.home_video.fade-in-video {
  opacity: 1; // Video becomes visible when ready
}

.banner-container {
  position: relative;
}

.banner-text {
  text-align: center;

  &--title {
    font-size: 40px;
    color: white;
  }

  &--para {
    font-size: 15px;
    color: white;
    text-transform: uppercase;
  }
}

.logo-see-more {
  position: absolute;
  bottom: 20px;
  cursor: pointer;
}

</style>
