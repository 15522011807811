<template>
  <div id="card-add-comments">
    <div class="card-comment">
      <div class="photo-lodging">
        <img :src="formatSource()" :alt="this.resa.lodging.name">
      </div>
      <div class="resa-info">
        <div class="resa-content">
          <h1 class="raleway-reg-h1">{{ this.resa.lodging.name }}</h1>
          <p class="raleway-reg-p13 mb-0">Du {{ this.formatDate(this.resa.checkin) }} au {{ this.formatDate(this.resa.check_out) }}</p>
          <p class="raleway-reg-p13 mb-0">{{ this.resa.duration }} nuits</p>
          <p class="raleway-reg-p13 mb-0">Dîner et massage offerts</p>
          <p class="raleway-reg-p13 mb-0">Chambre Hyatt double</p>
        </div>
        <!--<div class="resa-rating">
          <p class="raleway-reg-p13 mb-0">Note globale</p>
          <div class="rate">
            <p class="raleway-reg-h1">-</p><p class="raleway-reg-p13 mb-0">/10</p>
          </div>
        </div>-->
      </div>
    </div>
    <button class="hortense-btn-primary raleway-bold-p" @click="showRate = !showRate">Noter cet hébergement</button>
    <Rategrid v-if="showRate" :user="user" :resa="resa" :reservation="this.resa.id" @close="setComment"></Rategrid>
     <!-- <div class="pagination-hub d-flex mt-3" @click="showRate = !showRate">
        <a v-if="showRate" class="link-hortense"> Voir moins <img src="../../images/Fleche haut.svg" v-set-alt></a>
        <a v-else class="link-hortense"> voir plus <img src="../../images/flechebas-simple.svg" v-set-alt></a>
    </div> -->
    <Flash :text='this.text' :status='this.status' @destroy='toggleStatus(status)'></Flash>
  </div>
</template>

<script>
  import Rategrid from 'components/dashboard/Rategrid.vue';
  import Flash from 'alert/Flash.vue';

  export default{
    name: 'card-add-comments',
    props: [ 'resa', 'user' ],
    components: { Rategrid, Flash },
    data() {
      return {
        showRate: false,
        status: null,
        text: null,
      }
    },
    methods: {
      formatSource() {
        if (this.resa.lodging.photo_banner) {
          return this.resa.lodging.photo_banner
        } else {
          return 'https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0=';
        }
      },
      setComment(){
        window.location.reload();
        this.toggleStatus('success');
        this.text = "hébergement à été noté";
      },
      formatDate(date){
        const set = new Date(date)
        const options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
       return set.toLocaleString('default', options)
      },
      toggleStatus(value="success") {
        this.status = this.status === value ? null : value
      },
    },
  }
</script>
