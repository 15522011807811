<template>
  <div id="exclusives">
    <Searchbtn
      @show="showBanner = !showBanner"
      @top="redirectToTop"
    ></Searchbtn>
    <Searchbanner v-if="showBanner" @close="showBanner = false"></Searchbanner>
    <div class="container-index">
      <div class="index-text">
        <h1 class="merriweather-reg-40 mt-5">Nos Offres Exclusives</h1>
        <h2 class="merriweather-italic24 mb-5">Voyagez comme un invité privilégié</h2>
        <p class="raleway-reg-p15 text-center">
          Pour vivre une expérience hors du commun, toutes les possibilités s’offrent à vous grâce à nos offres exclusives. 
          Hortense vous permet de bénéficier d’avantages uniques au cours de votre séjour. 
          À votre retour, Hortense vous propose une attention particulière en vous offrant un cadeau exceptionnel s’inspirant de vos envies. 
          Pour récompenser votre envie d’adopter un mode de voyage éco-responsable, Hortense vous couvre de cadeaux. 
          Les cadeaux offerts dépendent du nombre de nuits réservées. 
          Pour bénéficier de nos offres spéciales et recevoir votre cadeau, inscrivez-vous à notre programme de fidélité avant de réserver sur notre site. 
          Envie de voyager comme un invité privilégié et découvrir en avant première nos offres exclusives ? Rejoignez le Club Hortensia !
        </p>
        <a class="hortense-btn-primary raleway-bold-p mt-5" style="width:408px" @click="setBoolMember">JE M'INSCRIS</a>
        <Modalsignup v-if="showModal" :showModalLogin="showModalLogin" @close="closeModal"></Modalsignup>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <div class="row">
        <div class="col-lg-6 col-xs-10 cardColl" :class="{ 'col-weekend': !isPhone }">
            <img src="../../images/exclusive-offer/offre-exclusive-1.jpg" style="height:600px;width:568px" v-set-alt>
            <div class="card-content">
                <h2 class="mt-4 mb-4">OFFRES EXCLUSIVES POUR VOYAGER DIFFÉREMMENT</h2>
                <p class="mb-2">Envie de voyager différemment ? Voyagez avec Hortense. 
                  Hortense vous propose des offres exceptionnelles destinées à faire la promotion du tourisme durable et vous permettre de voyager durablement. 
                </p>
                <br/>
                <p class="mb-2">
                Voyager durablement est à la portée de tous grâce à nos offres exclusives.
                </p>
                <a href="/reservation-hotel" class="mb-3">Voyagez durablement</a>
            </div>
        </div>
        <div class="col-lg-6 col-xs-10 cardColl pt-5" :class="{ 'col-weekend': !isPhone }">
            <img src="../../images/exclusive-offer/offre-exclusive-2.jpg" style="height:600px;width:568px" v-set-alt>
            <div class="card-content">
                <h2 class="mt-4 mb-4">OFFRES EXCLUSIVES RÉSERVÉES AUX MEMBRES</h2>
                <p class="mb-2">Rejoignez le programme de fidélité Hortensia et profitez d’avantages exclusifs tout au long de l’année. 
                  En tant que membre du programme de fidélité Hortensia, vous bénéficierez d'un accès privilégié à nos offres exceptionnelles et nos tarifs exclusifs. 
                </p>
                <br/>
                <p class="mb-2">
                  Plus qu’un programme de fidélité, Hortensia est une communauté de voyageurs engagés, devenez membre et profitez d’avantages uniques.
                </p>
                <a href="/programme-fidélité-hortensia" class="mb-3">Découvrir Hortensia</a>
            </div>
        </div>
      </div>
        
       
      <div v-if="!isPhone" class="row row-weekend" v-for="(exclusive, index) in this.exclusivesMounted" :key="index">
          <div v-if="index%2 === 0" class="col-lg-6 col-xs-10 col-weekend cardColl">
            <CardLeftColl :exclusive="exclusive" data-aos="fade-up" data-aos-duration="900"></CardLeftColl>
          </div>
          <div v-if="index%2 === 0" class="col-lg-6 col-xs-10 col-weekend cardColl">
            <CardRightColl :exclusive="exclusive" data-aos="fade-up" data-aos-duration="900"></CardRightColl>
          </div>
          
          <div v-if="index%2 === 1" class="col-lg-6 col-xs-10 col-weekend cardColl">
            <CardRightColl :exclusive="exclusive" data-aos="fade-up" data-aos-duration="900"></CardRightColl>
          </div>
          <div v-if="index%2 === 1" class="col-lg-6 col-xs-10 col-weekend cardColl">
            <CardLeftColl :exclusive="exclusive" data-aos="fade-up" data-aos-duration="900"></CardLeftColl>
          </div>
          <br/>
          <br/>
      </div>
      <div v-else class="card-content index-text" data-aos="fade-up" data-aos-duration="900">
        <h1 class="mt-4 mb-4">{{ exclusive.title }}</h1>
        <h2 class="mb-2">{{ exclusive.subtitle_exclusive }}</h2>
        <img v-if="exclusive.photo_key" :src="exclusive.photo_key" style="height:200px;width:568px" :alt="exclusive.title">
        <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" style="height:200px;width:568px" :alt="exclusive.title">
        <div id="description" data-aos="fade-down" data-aos-duration="1000">
            <h5>Découvrez notre offre exclusive</h5>
            <ul>
                <li v-if="exclusive.exclusive_offer_1">{{ exclusive.exclusive_offer_1 }}</li>
                <li v-if="exclusive.exclusive_offer_2">{{ exclusive.exclusive_offer_2 }}</li>
                <li v-if="exclusive.exclusive_offer_3">{{ exclusive.exclusive_offer_3 }}</li>
                <li v-if="exclusive.exclusive_offer_4">{{ exclusive.exclusive_offer_4 }}</li>
                <li v-if="exclusive.exclusive_offer_5">{{ exclusive.exclusive_offer_5 }}</li>
            </ul>
            <a :href="'/offres-exclusives/' + exclusive.slug" class="hortense-btn-primary btn-fid mb-4" @click="scrollToElement('form-user-info')">RÉSERVEZ</a>
        </div>
        <p class="mb-2">{{ exclusive.description_exclusive_page }}</p>
        <hr/>
        <h3>Comment ça marche ?</h3>
        <p id="p-hiw" class="p-reg-p13 mb-2">{{ exclusive.how_it_works }}</p>
      </div> 
      
      <!-- FOOTER -->
      <!--<div :class="{ 'exclusive-footer-phone': isPhone, 'exclusive-footer': !isPhone }">-->
      <div v-if="!isPhone" class="exclusive-footer">
          <div class="row">
              <div class="col-lg-6 col-xs-10 col-weekend col-4">
                  <h1 class="merriweather-reg-40 mb-5 mr-1">
                    Des voyages plus riches et des récompenses exceptionnelles pour des voyageurs engagés.
                  </h1>
                  <a class="hortense-btn-primary mb-2 ml-5" @click="setBoolMember">DEVENIR MEMBRE</a>
                  <a class="link-hortense mb-5" @click="setBoolLogin">Vos avez déja un compte ? S'identifier.</a>
              </div>
              <div class="col-lg-6 col-xs-10 col-weekend col-4">
                  <img src="../../images/exclusive-offer/offre-exclusive-3.jpeg" v-set-alt>
              </div>
          </div>
      </div>
      <div v-else class="exclusive-footer">
          <img src="../../images/exclusive-offer/offre-exclusive-3.jpeg" v-set-alt>
          <h1 class="merriweather-reg-40 mb-5 mr-1">
             Des voyages plus riches et des récompenses exceptionnelles pour des voyageurs engagés.
          </h1>
          <a class="hortense-btn-primary mb-2">DEVENIR MEMBRE</a>
          <a class="link-hortense mb-5" @click="showModal = true">Vos avez déja un compte ? S'identifier.</a>
      </div>
      <ScrollUp></ScrollUp>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import ScrollUp from "components/btns/ScrollUp.vue";
import CardLeftColl from "pages/exclusives/CardLeftColl.vue";
import CardRightColl from "pages/exclusives/CardRightColl.vue";
import fetcher from "packs/shared/api.js";
import Searchbtn from "components/btns/Searchbtn.vue";
import Searchbanner from "components/btns/Searchbanner.vue";
import Modalsignup from 'components/modals/Modalsignup.vue';

export default {
  name: "exclusives",
  props: ["exclusives"],
  components: { ScrollUp, CardLeftColl, CardRightColl, Searchbtn, Searchbanner, Modalsignup },
  data() {
    return {
      showBanner: false,
      exclusivesMounted: null,
      showModal: false,
      showModalLogin: true,
    };
  },
  computed: {
     isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    }
  },
  methods: {
    closeModal(){
      this.showModal = false;
    },
    setBoolMember(){
      this.showModal = true;
      this.showModalLogin = false;
    },
    setBoolLogin(){
      this.showModal = true;
      this.showModalLogin = true;
    },
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    async fetchAllExclusives() {
      try {
        const response = await fetcher.get("/offres-exclusives.json");
        this.exclusivesMounted = response.data;
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    AOS.init();
    this.fetchAllExclusives();
  },
};
</script>

<style
  lang="scss"
  scope="app/assets/stylesheets/components/exclusive-gallery"
></style>

<style lang="scss" scoped>
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
     #description{
        display: block;
        position: inherit;
        width: 90%;
        margin-top: -15%;
        //margin-left: 9%;
        padding-top: 7%;
        padding: 5%;
        background-color: #fff;
          .hortense-btn-primary {
            width: 100%;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        ul{
          list-style: none;
          list-style-image: url('../../images/Icotype_puce.svg');
          font-family: $Raleway;
          font-weight: 400;
          font-size: 13px;
          }
        h5{
            font-family: $Merriweather;
            font-weight: 400;
            font-style: italic;
            font-size: 20px;
        }
      }
      .index-text{
        h1{
          text-align: center;
        }
        h2{
          text-align: center;
        }
        p{
          text-align: center;
        }
      }
    }
</style>