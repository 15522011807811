<template>
  <div id="evergreenHome">
    <div class="card-container">
      <div class="row row-hubs" data-aos="fade-up" data-aos-duration="1000">
        <div class="col-6 evergreen-col">
          <div class="evergreen-header">
            <h3 class="merriweather-reg-26"> {{ $t("Evergreen.program") }} </h3>
            <p class="raleway-reg-p16">{{ $t("Evergreen.content") }}</p>
          </div>
            <a class="hortense-btn-primary btn-evergreen raleway-bold-p mt-5" :href="'/programme-evergreen-demarche-environnementale/'">{{ $t("Evergreen.viewbtn") }}</a>
        </div>
        <div class="col-4 offset-6 planete-col">
          <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/planete2.png" width="800px" height="800px" v-set-alt>
        </div>
        <div class="cards">
          <slither-slider class="slither-slider" :options="{ numberOfSlides: 3,
                                                            autoplay: true,
                                                            controls: true,
                                                            extras: 3,
                                                            loop: true,
                                                            endless: true,
                                                            touch:true,
                                                            secondsOnSlide: 2}">
            <template v-for="project in this.projectsMounted">
              <div id="cardEvergreen">
                <div class="card-ever">
                <img :src="project.photo_key" v-set-alt>
                  <div class="sticker-program d-flex mt-4 pl-2">
                    <div class="sticker-program-info d-flex">
                      <p class="raleway-bold-h3 mb-0 pl-1 pr-2">{{ project.title }}</p>
                      <p class="raleway-reg-p13 mb-0 pl-1 pr-2">{{ project.goal }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template slot="previous">
              <div class="previous">
                <img src="../images/fleche gauche.svg" v-set-alt>
                <p class="raleway-reg-p13 m-0">PLUS</p>
              </div>
            </template>
            <template slot="next">
              <img src="../images/fleche droite.svg" v-set-alt>
            </template>
          </slither-slider>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

  import CardEvergreen from 'components/cards/CardEvergreen.vue';
  import SliderEvergreen from 'components/SliderEvergreen.vue';
  import AOS from 'aos';

  export default {
    name: 'evergreenHome',
    components:{ CardEvergreen, SliderEvergreen },
    props: {
      evergreens: Array
    },
    data() {
      return {
        projectsMounted: this.evergreens,
      }
    },
    mounted() {
      AOS.init();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/evergreen-home'>

</style>
