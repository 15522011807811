<template>
  <div id="card-comments">
    <div class="card-comment">
      <div class="photo-lodging">
        <img :src="formatSource()" :alt="this.resa.lodging.name">
      </div>
      <div class="resa-info">
        <div class="resa-content">
          <h1 class="raleway-reg-h1">{{ this.resa.lodging.name }}</h1>
          <p class="raleway-reg-p13 mb-0">Du {{ this.formatDate(this.resa.checkin) }} au {{ this.formatDate(this.resa.check_out) }}</p>
          <p class="raleway-reg-p13 mb-0">{{ this.resa.duration }} nuits</p>
          <p class="raleway-reg-p13 mb-0">Dîner et massage offerts</p>
          <p class="raleway-reg-p13 mb-0">Chambre Hyatt double</p>
        </div>
        <div class="resa-rating">
          <p class="raleway-reg-p13 mb-0">Note globale</p>
          <div class="rate">
            <p class="raleway-reg-h1">{{ rateAverage() }}</p><p class="raleway-reg-p13 mb-0">/10</p>
          </div>
        </div>
      </div>
    </div>
    <Rategrid v-if="showRate" :user="user" :resa="resa" :reservation="this.resa.id"></Rategrid>
     <div class="pagination-hub d-flex mt-3" @click="showRate = !showRate">
        <a v-if="showRate" class="link-hortense"> Fermer <img src="../../images/Fleche haut.svg" v-set-alt></a>
        <a v-else class="link-hortense"> voir le détail <img src="../../images/flechebas-simple.svg" v-set-alt></a>
      </div>
  </div>
</template>

<script>
  import Rategrid from 'components/dashboard/Rategrid.vue';

  export default{
    name: 'card-comments',
    props: [ 'resa', 'user' ],
    components: { Rategrid },
    data() {
      return {
        showRate: false,
        ratingAverage: null,
      }
    },
    methods: {
      formatSource() {
        if (this.resa.lodging.photo_banner) {
          return this.resa.lodging.photo_banner;
        } else {
          return 'https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0=';
        }
      },
      formatDate(date){
        const set = new Date(date)
        const options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
        return set.toLocaleString('default', options)
      },
      rateAverage(){
        const list = [this.resa.ratings[0].accueil, this.resa.ratings[0].comfort, this.resa.ratings[0].hygiene, this.resa.ratings[0].meal, this.resa.ratings[0].location, this.resa.ratings[0].price_quality_ratio, this.resa.ratings[0].environmental_commitment]
        const result = list.reduce((a, b) => a + b, 0) / list.length;
        return result.toFixed(1)
      }
    },
  }
</script>